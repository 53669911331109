import { HeimdallAuth } from "interfaces/generic";
import axiosRoot, { apiConfig } from "./rootConfig";
import axios from "axios";
/*----------------------------------------------------------
 * Authentication
 * ------------------------------------------------------------- */
export async function signIn(username: string, password: string) {
  const requestData: any = {
    email: username,
    password,
  };
  return axiosRoot.post<HeimdallAuth>("/api/login", requestData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "App-Url": localStorage.getItem("mobileBaseUrl"),
    },
  });
}

export async function logout() {
  return await axiosRoot
    .get("/api/logout")
    .then((response) => response.data)
    .catch(function (error) {
      console.error(error);
    });
}

export async function confirmEmail(userId: number) {
  return await axios.get(apiConfig.baseUrl + "/api/user_confirm_email/" + userId);
}

export async function confirmUpdateEmail(data: {
  userId: number;
  oldEmail: string;
  newEmail: string;
  token: string;
}) {
  return await axios
    .put(apiConfig.baseUrl + "/api/user_company_change_external_email/" + data.userId, {
      old_email: data.oldEmail,
      new_email: data.newEmail,
      token_email: data.token,
    })
    .then((res) => res.data);
}

export async function domainFromCompanyName(companyName: string) {
  const heimdallMonitorApiUrl = "https://monitoramento.heimdallcloud.com.br";
  const requestUrl = heimdallMonitorApiUrl + "/api/instance_by_name";
  return await axios
    .get<{ data: { domain: string } }>(requestUrl, {
      headers: {
        Name: companyName,
        Token: "base64:XJC0EU/KVCPAyIB0Dm/kYNQ9I3e+vTnbDjUO9/u94zU=",
      },
    })
    .then((res) => res.data);
}
