import { OccurrenceMark } from "./occurrences";

import { RegionDescription } from "./analytics";
import { AnalyticType } from "./analytics";

export type LogHandlingStatus = "Blocked" | "Done" | "Released";

export interface MonitoringMessage {
  id: number;
  name?: string;
  occurrence: AnalyticType;
  license_plate?: string;
  velocity?: string;
  car_color?: string[];
  car_color_confs?: number[];
  car_confidence?: number;
  car_coords?: [number, number, number, number];
  plate_coords?: [number, number, number, number];
  plate_digits_confs?: number[];
  plate_digits_coords?: [number, number, number, number][];
  plate_value?: string;
  plate_value_debug?: string;
  status: LogHandlingStatus;
  // checking_user: null | string;
  region_dic: RegionDescription;
  bbox_lst: number[][];
  simi_lst: number[][];
  class_lst: null;
  recipient_users: { id: number; name: string }[];
  url: string;
  url_video: string;
  check_status: null | OccurrenceMark;
  check_user_id: null | string;
  check_user: null | string;
  check_description: null | string;
  check_timestamp: null | string;
  created_at: string;
  video: string;
  analytic_id: number;
  camera_id: number;
  camera: {
    id: number;
    camera_name: string;
    ip: string;
    company_id: number;
    rtsp: string;
    remarks: string;
    camera_address: {
      id: number;
      cep: null | string;
      address: null | string;
      district: null | string;
      city: null | string;
      state: null | string;
      description: null | string;
      coordinate: null | string;
    };
  };
}

export interface LogHandlingMessage {
  id: number;
  occurrence: AnalyticType;
  username: string;
  current_value: LogHandlingStatus;
  recipient_users: {
    id: number;
    name: string;
  }[];
}
export interface LogHandlingMessageResponse {
  data: {
    current_value: LogHandlingStatus;
    recipient_users: {
      id: number;
      name: string;
    }[];
    channel: string[];
    message: LogHandlingMessage;
  };
}

export function isLogHandlingMessage(obj: any): obj is LogHandlingMessage {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.id === "number" &&
    typeof obj.occurrence === "string" &&
    (typeof obj.username === "string" || obj.username === null) &&
    isLogHandlingStatus(obj.current_value) &&
    isAnalyticType(obj.occurrence) &&
    Array.isArray(obj.recipient_users) &&
    obj.recipient_users.every(
      (user: any) =>
        typeof user === "object" &&
        user !== null &&
        typeof user.id === "number" &&
        typeof user.name === "string"
    )
  );
}

function isLogHandlingStatus(value: any): value is LogHandlingStatus {
  return (
    typeof value === "string" &&
    (value === "Blocked" || value === "Released" || value === "Done")
  );
}

function isAnalyticType(value: any): value is AnalyticType {
  return (
    typeof value === "string" &&
    (value === "Intrusão" ||
      value === "Aglomeração" ||
      value === "Evasão" ||
      value === "Inatividade" ||
      value === "Radar" ||
      value === "Detecção de Animal" ||
      value === "Detecção de Veículo" ||
      value === "Ausência de Veículo" ||
      value === "Congestionamento" ||
      value === "Pânico" ||
      value === "Lpr")
  );
}
