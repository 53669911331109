import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, TextField } from "@mui/material";
import {
  GridColDef,
  GridPaginationModel,
  GridToolbar,
  GridValidRowModel,
} from "@mui/x-data-grid";
import GlobalContext from "context/GlobalContext";
import { useNavigate } from "react-router-dom";
import MainContainer from "components/MainContainer/MainContainer";
import { getDispatcherColumns } from "lib/getDispatcherColumns";
import { deleteDispatcher, getDispatchers } from "api/alertSystem";
import { DispatcherListData } from "interfaces/alertSystem";
import { darken, lighten, styled } from "@mui/material/styles";
import DeleteDialog from "components/Modals/DeleteDialog";
import BorderedDataGrid from "components/BorderedDataGrid/BorderedDataGrid";
import DispatcherInUseDialog from "./DispatcherInUseDialog";
import { Add, FilterList, Search } from "@mui/icons-material";
import { BackendResponsePagination } from "interfaces/generic";
import ExpandableContainer from "components/ExpandableContainer";
import DataGridContainer, {
  defaultGridContainerMaxHeight,
} from "components/DataGridContainer/DataGridContainer";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(BorderedDataGrid)(({ theme }) => ({
  "& .theme--default": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

export default function Dispatchers() {
  const { toast } = useToast();
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  // Data grid state
  const [dispatchers, setDispatchers] = useState<DispatcherListData[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // Modal state
  const [selectedDispatcher, setSelectedDispatcher] =
    useState<DispatcherListData>();
  const [open, setOpen] = useState(false);
  // Filter state
  const [name, setName] = useState("");
  const [activeFilters, setActiveFilters] = useState(0);
  // Linked analytics Dialog state
  const [dispatcherInUse, setDispatcherInUse] = useState(false);
  const [analyticsUsingDispatcher, setAnalyticsUsingDispatcher] = useState<
    { cameraName: string; analyticType: string }[]
  >([]);

  // Fetching initial page data
  useEffect(() => {
    async function fetchData() {
      let newDispatchers: void | BackendResponsePagination<DispatcherListData> | null =
        null;
      const prevModel = sessionStorage.getItem("dispatchersPagination");
      const prevFilters = sessionStorage.getItem("dispatchersFilters");

      if (prevModel) {
        const prevModelParsed = JSON.parse(prevModel);
        newDispatchers = await getDispatchers({
          page: prevModelParsed.page + 1,
          pageSize: prevModelParsed.pageSize,
          includeDefault: false,
          name: prevFilters ? prevFilters : "",
        });
        if (prevFilters) {
          setName(prevFilters);
          setActiveFilters(1);
        }
      } else if (!prevModel && prevFilters) {
        newDispatchers = await getDispatchers({
          includeDefault: false,
          name: prevFilters,
        });
        setName(prevFilters);
        setActiveFilters(1);
      } else {
        newDispatchers = await getDispatchers({
          includeDefault: false,
        });
      }

      if (newDispatchers && newDispatchers.data) {
        setDispatchers(newDispatchers.data);
        setTotalPages(newDispatchers.meta.total);
        if (prevModel) setPaginationModel(JSON.parse(prevModel));
      }

      setIsLoading(false);
    }

    fetchData();
  }, []);

  async function handleDelete(id: number) {
    const dispatcherUsedBy = dispatchers.find(
      (value) => value.alert_system_id === id
    )?.link_analytic;
    console.log("Dispatcher used by:", dispatcherUsedBy);
    if (dispatcherUsedBy && dispatcherUsedBy.length > 0) {
      setOpen(false);
      const newAnalyticsUsingDispatchers: {
        cameraName: string;
        analyticType: string;
      }[] = [];
      dispatcherUsedBy.forEach((link) => {
        newAnalyticsUsingDispatchers.push({
          cameraName: link.analytic.camera.camera_name,
          analyticType: link.analytic.analytic_type,
        });
      });

      setAnalyticsUsingDispatcher(newAnalyticsUsingDispatchers);
      setDispatcherInUse(true);
      return;
    }
    setIsLoading(true);

    await deleteDispatcher(Number(id))
      .then(async () => {
        toast({
          description: "Despachador removido com sucesso.",
        });
        const futureDispatchers = await getDispatchers({
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
          includeDefault: false,
        });

        if (futureDispatchers && futureDispatchers.data)
          setDispatchers(futureDispatchers.data);

        setOpen(false);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Erro ao remover despachador",
          description: getErrorMessage(error),
        });
      });

    setIsLoading(false);
  }

  async function filter() {
    setIsLoading(true);

    const newDispatchers = await getDispatchers({
      page: 0,
      pageSize: paginationModel.pageSize,
      includeDefault: false,
      name,
    });

    if (newDispatchers) {
      setDispatchers(newDispatchers.data);
      setTotalPages(newDispatchers.meta.total);
      sessionStorage.setItem("dispatchersFilters", name);
      sessionStorage.setItem(
        "dispatchersPagination",
        JSON.stringify({ page: 0, pageSize: paginationModel.pageSize })
      );
      if (name) setActiveFilters(1);
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }

    setIsLoading(false);
  }

  async function clearFilters() {
    setIsLoading(true);

    const newDispatchers = await getDispatchers({
      page: 0,
      pageSize: paginationModel.pageSize,
      includeDefault: false,
    });

    if (newDispatchers) {
      setName("");
      setActiveFilters(0);
      sessionStorage.removeItem("dispatchersFilters");
      setDispatchers(newDispatchers.data);
      setTotalPages(newDispatchers.meta.total);
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }

    setIsLoading(false);
  }

  function handleEdit(id: number) {
    navigate("/dispatchers/create-dispatcher/" + id);
  }

  async function onPaginationModelChange(model: GridPaginationModel) {
    sessionStorage.setItem("dispatchersPagination", JSON.stringify(model));
    setIsLoading(true);

    if (user) {
      const newDispatchers = await getDispatchers({
        page: model.page + 1,
        pageSize: model.pageSize,
        includeDefault: false,
      });

      if (newDispatchers) {
        setDispatchers(newDispatchers.data);
        setTotalPages(newDispatchers.meta.total);
      }
      setPaginationModel({
        page: model.page,
        pageSize: model.pageSize,
      });
    }

    setIsLoading(false);
  }

  return (
    <MainContainer
      title="Sistema de Alerta"
      responsiveActions={[
        {
          title: "Novo sistema de alerta",
          icon: <Add />,
          action: () => navigate("create-dispatcher"),
        },
      ]}
    >
      <ExpandableContainer
        title="Filtros avançados"
        icon={
          <>
            {activeFilters > 0 ? (
              <Badge
                color="secondary"
                badgeContent={activeFilters}
                style={{ position: "absolute", top: "0px", left: "20px" }}
              />
            ) : null}
            <FilterList color="primary" />
          </>
        }
      >
        <TextField
          label="Nome"
          margin="dense"
          size="small"
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <Button
            color="error"
            onClick={clearFilters}
            style={{ marginRight: "10px" }}
          >
            Limpar filtros
          </Button>
          <Button variant="outlined" startIcon={<Search />} onClick={filter}>
            Buscar
          </Button>
        </div>
      </ExpandableContainer>
      <DataGridContainer>
        <StyledDataGrid
          sx={{ maxHeight: `${defaultGridContainerMaxHeight - 40}px` }}
          rows={dispatchers}
          columns={
            getDispatcherColumns({
              refreshList: () => onPaginationModelChange(paginationModel),
              handleDelete: (dispatcher) => {
                setSelectedDispatcher({ ...dispatcher });
                setOpen(true);
              },
              handleEdit,
            }) as GridColDef<GridValidRowModel>[]
          }
          loading={isLoading}
          getRowId={(row) => row.alert_system_id}
          rowCount={totalPages}
          paginationMode="server"
          slots={{ toolbar: GridToolbar }}
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => onPaginationModelChange(model)}
          pageSizeOptions={[10, 15, 25, 50, 100]}
          disableColumnFilter
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            `theme--${params.row.name.includes("default") ? "default" : ""}`
          }
          // SEARCH FIELD
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </DataGridContainer>
      <DispatcherInUseDialog
        open={dispatcherInUse}
        onClose={() => setDispatcherInUse(false)}
        title={`O despachador ${selectedDispatcher?.name} está em uso`}
        analytics={analyticsUsingDispatcher}
      />
      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => handleDelete(selectedDispatcher!.alert_system_id)}
        title={`Deseja mesmo remover o despachador ${selectedDispatcher?.name}?`}
        message="Essa ação não poderá ser revertida"
        isLoading={isLoading}
      />
    </MainContainer>
  );
}
