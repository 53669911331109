import React from "react";
import { Android, Language } from "@mui/icons-material";
import { SimpleDeviceInfo } from "lib/deviceInfo";
import {
  Chrome,
  Edge,
  Firefox,
  Opera,
  Safari,
} from "components/icons/thirdPartyIcons";

export function PlatformIcon({
  devicePlatform,
  deviceOs,
}: {
  devicePlatform?: SimpleDeviceInfo["browser"];
  deviceOs: SimpleDeviceInfo["operatingSystem"];
}) {
  if (devicePlatform) {
    switch (devicePlatform) {
      case "Chrome":
        return <Chrome className="size-9" />;
      case "Edge":
        return <Edge className="size-9" />;
      case "Firefox":
        return <Firefox className="size-9" />;
      case "Opera":
        return <Opera className="size-9" />;
      case "Safari":
        return <Safari className="size-9" />;
      default:
        return <Language className="text-3xl sm:text-4xl" fontSize="inherit" />;
    }
  }

  switch (deviceOs) {
    case "android":
    case "Android":
      return (
        <Android
          className="text-3xl sm:text-4xl"
          color="success"
          fontSize="inherit"
        />
      );
    case "ios":
    case "iOS":
      return (
        <svg width="100" height="50" xmlns="http://www.w3.org/2000/svg">
          <text
            x="0"
            y="40"
            font-family="Arial, sans-serif"
            font-size="40"
            fill="black"
          >
            iOS
          </text>
        </svg>
      );
    default:
      return <Language className="text-3xl sm:text-4xl" fontSize="inherit" />;
  }
}
