import { Device } from "@capacitor/device";

export async function getMobileDeviceInfo() {
  const info = await Device.getInfo();
  return info;
}

export async function getMobileDeviceId() {
  const id = await Device.getId();
  return id;
}

async function getBatteryInfo() {
  const info = await Device.getBatteryInfo();
  return info;
}
