import { v4 as uuidv4 } from "uuid";

import { isPlatform } from "@ionic/react";
import { getMobileDeviceId, getMobileDeviceInfo } from "./mobile/device_info";
import { DeviceInfo, OperatingSystem } from "@capacitor/device";

export type SimpleDeviceInfo = Omit<
  DeviceInfo,
  | "realDiskFree"
  | "realDiskTotal"
  | "isVirtual"
  | "manufacturer"
  | "osVersion"
  | "androidSDKVersion"
  | "webViewVersion"
  | "operatingSystem"
  | "platform"
  | "model"
> & {
  operatingSystem: OperatingSystem | ReturnType<typeof getOS>;
  model?: string;
  browser?: ReturnType<typeof getBrowser>;
};

async function getDeviceInfo() {
  if (isPlatform("mobile") && !isPlatform("mobileweb")) {
    const deviceInfo = await getMobileDeviceInfo();
    const deviceId = await getMobileDeviceId();
    const simpleDeviceInfo: SimpleDeviceInfo = {
      operatingSystem: deviceInfo.operatingSystem,
      model: deviceInfo.model,
      name: deviceInfo.name,
    };

    return {
      deviceInfo: simpleDeviceInfo,
      deviceId: deviceId.identifier,
    };
  }

  const userAgent = navigator.userAgent;
  const os = getOS(userAgent);
  const browser = getBrowser(userAgent);
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) deviceId = uuidv4();
  localStorage.setItem("deviceId", deviceId);

  const simpleDeviceInfo: SimpleDeviceInfo = {
    operatingSystem: os,
    browser,
  };

  return {
    deviceInfo: simpleDeviceInfo,
    deviceId,
  };
}

const getOS = (userAgent: string) => {
  const ua = userAgent.toLowerCase();
  if (ua.includes("windows nt 10.0")) return "Windows 10/11";
  if (ua.includes("windows nt 6.3")) return "Windows 8.1";
  if (ua.includes("windows nt 6.2")) return "Windows 8";
  if (ua.includes("windows nt 6.1")) return "Windows 7";
  if (ua.includes("windows nt 6.0")) return "Windows Vista";
  if (ua.includes("windows nt 5.1")) return "Windows XP";
  if (ua.includes("windows nt 5.0")) return "Windows 2000";
  if (ua.includes("macintosh") || ua.includes("mac os x")) return "MacOS";
  if (ua.includes("linux")) return "Linux";
  if (ua.includes("x11")) return "UNIX";
  if (ua.includes("android")) return "Android";
  if (ua.includes("iphone") || ua.includes("ipad")) return "iOS";
  return "Unknown";
};

const getBrowser = (userAgent: string) => {
  if (userAgent.includes("Chrome")) return "Chrome";
  if (userAgent.includes("Firefox")) return "Firefox";
  if (userAgent.includes("Safari")) return "Safari";
  if (userAgent.includes("Edge")) return "Edge";
  if (userAgent.includes("Opera") || userAgent.includes("OPR")) return "Opera";
  return "Unknown";
};

export default getDeviceInfo;
