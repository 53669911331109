import React, { useContext, useState } from "react";
import GlobalContext from "context/GlobalContext";
import { createCamera } from "api/cameras";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { getErrorMessage } from "lib/apiErrorHandler";
import { useToast } from "components/ui/use-toast";

interface CameraFormProps {
  onCreateCamera: (id: number) => Promise<void>;
}

export default function CameraForm({ onCreateCamera }: CameraFormProps) {
  const { toast } = useToast();
  const { user } = useContext(GlobalContext);
  const [cameraForm, setCameraForm] = useState({
    name: "",
    ip: "",
    username: "",
    password: "",
    port: "",
    channel: "",
    substream: false,
    latitude: "",
    longitude: "",
    address: "",
    place: "",
    groups: [],
  });
  const [nameError, setNameError] = useState(false);
  const [ddnsError, setDdnsError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [portError, setPortError] = useState(false);
  const [channelError, setChannelError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const handleForm = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isSubstream?: boolean
  ) => {
    isSubstream
      ? setCameraForm((prevState) => ({
          ...prevState,
          //@ts-ignore
          [ev.target.name]: ev.target.checked,
        }))
      : setCameraForm((prevState) => ({
          ...prevState,
          [ev.target.name]: ev.target.value,
        }));
  };

  function handleChangeName(ev: React.ChangeEvent<HTMLInputElement>) {
    if (ev.target.value.length < 30) {
      setNameError(false);
      setCameraForm((prevState) => ({
        ...prevState,
        [ev.target.name]: ev.target.value,
      }));
      return;
    }
    return setNameError(true);
  }

  const handleCreateCamera = async () => {
    if (!user) return;
    setBackdrop(true);
    const substreamNumber = cameraForm.substream ? 1 : 0;

    try {
      const parsedLatitude = parseFloat(cameraForm.latitude);
      const parsedLongitude = parseFloat(cameraForm.longitude);
      if (
        (cameraForm.latitude !== "" && isNaN(parsedLatitude)) ||
        (cameraForm.longitude !== "" && isNaN(parsedLongitude))
      ) {
        throw new Error("Campo latitude e/ou longitude inválido");
      }
      const form = new FormData();
      form.append("name", cameraForm.name);
      form.append("ip", cameraForm.ip);
      form.append("user", cameraForm.username);
      form.append("address", cameraForm.address);
      form.append("password", cameraForm.password);
      form.append("rtsp_channel", cameraForm.channel);
      form.append("rtsp_substream", substreamNumber.toString());
      form.append("rtsp_port", cameraForm.port);
      form.append("manufacturer", "hikvision");
      form.append("remarks", "Câmera para segurança");
      form.append(
        "coordinate",
        JSON.stringify({
          latitude: cameraForm.latitude,
          longitude: cameraForm.longitude,
        })
      );

      await createCamera(form)
        .then(async (res) => {
          await onCreateCamera(res.data.camera_id);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao criar câmera",
            description: getErrorMessage(err),
          });
        });

      setBackdrop(false);
    } catch (error) {
      setBackdrop(false);
      toast({
        variant: "destructive",
        description: (error as Error).message,
      });
    }
  };

  function canCreateCamera() {
    if (cameraForm.name === "" || cameraForm.name.length > 30) {
      setNameError(true);
    }
    if (cameraForm.ip === "") {
      setDdnsError(true);
    }
    if (cameraForm.username === "") {
      setUsernameError(true);
    }
    if (cameraForm.password === "") {
      setPasswordError(true);
    }
    if (cameraForm.port === "") {
      setPortError(true);
    }
    if (cameraForm.channel === "") {
      setChannelError(true);
    }
    if (cameraForm.address === "") {
      setLocationError(true);
    }

    if (
      !cameraForm.name ||
      cameraForm.name.length > 30 ||
      !cameraForm.ip ||
      !cameraForm.username ||
      !cameraForm.password ||
      !cameraForm.port ||
      !cameraForm.channel ||
      !cameraForm.address
    )
      return false;

    return true;
  }

  return (
    <>
      <form
        noValidate
        className="grid grid-cols-1 gap-3 sm:grid-cols-2"
        onSubmit={(ev) => {
          ev.preventDefault();
          if (canCreateCamera()) handleCreateCamera();
        }}
      >
        <TextField
          required
          fullWidth
          name="name"
          size="small"
          label="Nome"
          value={cameraForm.name}
          error={nameError}
          helperText={
            nameError ? "Precisa fornecer um nome até 30 caracteres" : ""
          }
          onChange={handleChangeName}
        />
        <TextField
          required
          fullWidth
          name="ip"
          size="small"
          value={cameraForm.ip}
          label="DDNS ou IP fixo"
          error={ddnsError}
          helperText={ddnsError ? "Precisa fornecer um IP ou DDNS" : ""}
          onChange={(event) => {
            handleForm(event);
            setDdnsError(false);
          }}
        />
        <TextField
          required
          size="small"
          name="username"
          label="Usuário"
          value={cameraForm.username}
          error={usernameError}
          helperText={
            usernameError ? "Precisa fornecer um nome de usuário" : ""
          }
          style={{ display: "flex", flex: "1" }}
          onChange={(event) => {
            handleForm(event);
            setUsernameError(false);
          }}
        />
        <PasswordInput
          required
          size="small"
          label="Senha"
          name="password"
          value={cameraForm.password}
          error={passwordError}
          helperText={passwordError ? "Precisa fornecer uma senha" : ""}
          style={{ display: "flex", flex: "1" }}
          onChange={(event) => {
            handleForm(event);
            setPasswordError(false);
          }}
        />
        <TextField
          required
          fullWidth
          name="port"
          size="small"
          value={cameraForm.port}
          label="Porta"
          error={portError}
          helperText={portError ? "Precisa fornecer uma porta" : ""}
          onChange={(event) => {
            handleForm(event);
            setPortError(false);
          }}
        />
        <TextField
          required
          fullWidth
          size="small"
          name="channel"
          label="Canal"
          value={cameraForm.channel}
          error={channelError}
          helperText={channelError ? "Você precisa especificar um canal" : ""}
          onChange={(event) => {
            handleForm(event);
            setChannelError(false);
          }}
        />
        <TextField
          required
          fullWidth
          size="small"
          label="Endereço"
          name="address"
          value={cameraForm.address}
          error={locationError}
          helperText={
            locationError
              ? "Você deve especificar um endereço para a câmera"
              : ""
          }
          onChange={(event) => {
            handleForm(event);
            setLocationError(false);
          }}
        />
        <div>
          <FormControlLabel
            name="substream"
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              marginRight: "5px",
              marginLeft: "0px",
            }}
            labelPlacement="start"
            label="Substream"
            control={<Switch onChange={(ev) => handleForm(ev, true)} />}
          />
        </div>
        <TextField
          fullWidth
          size="small"
          name="latitude"
          value={cameraForm.latitude}
          label="Latitude"
          onChange={(event) => handleForm(event)}
        />
        <TextField
          fullWidth
          size="small"
          name="longitude"
          value={cameraForm.longitude}
          label="Longitude"
          onChange={(event) => handleForm(event)}
        />
        <Button
          className="sm:col-span-2"
          variant="contained"
          onSubmit={(ev) => {
            ev.preventDefault();
            if (canCreateCamera()) handleCreateCamera();
          }}
          type="submit"
        >
          Cadastrar Câmera
        </Button>
      </form>
      <Backdrop
        sx={{
          color: "var(--root-color-primary)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
