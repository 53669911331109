import React, { useState, useEffect, useContext } from "react";
import { RadarLog } from "interfaces/analytics";
import { getPDF } from "api/flaskApiMethods";
import { GridToolbar, GridRowModel, useGridApiRef } from "@mui/x-data-grid";
import { getRadarColumns } from "lib/logs";
import { FilterFormData } from "interfaces/occurrences";
import MainContainer from "components/MainContainer/MainContainer";
import LogsMultiViewer from "components/LogsMultiViewer/LogsMultiViewer";
import { getRadarLogs } from "api/logs";
import LogsFilter from "components/logs-filter/LogsFilter";
import ExpandImageModal from "components/Modals/ExpandImageModal";
import { useToast } from "components/ui/use-toast";
import EditLogModal from "components/Modals/EditLogModal";
import { BackendResponsePagination } from "interfaces/generic";
import { HeimdallCameraGroup } from "interfaces/heimdallCamera";
import { useNavigate } from "react-router-dom";
import GlobalContext from "context/GlobalContext";

export default function LogRadar() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const gridApiRef = useGridApiRef();
  const { user } = useContext(GlobalContext);
  // Modal state
  const [openEdit, setOpenEdit] = useState(false);
  const [photoModal, setModalPhoto] = useState(false);
  const [selectedOccurrence, setSelectedOccurrence] = useState<RadarLog>();
  // Grid state
  const [isLoading, setIsLoading] = useState(true);
  const [logData, setLogData] = useState<BackendResponsePagination<RadarLog>>();
  const [totalPages, setTotalPages] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(totalPages || 0);
  // Filter state
  const [filterData, setFilterData] = useState<FilterFormData>({
    cameras: null,
    status: "",
    dateAfter: null,
    dateBefore: null,
    licensePlate: "",
    speed: "",
    order: "desc",
  });
  // Props passed to the filter
  const filterProps = {
    filterFormData: filterData,
    onFormDataChange: (newData: FilterFormData) => {
      setFilterData(newData);
    },
    filter,
    clearFilters,
  };

  // Avoiding grid data error when getting new data
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalPages !== undefined ? totalPages : prevRowCountState
    );
  }, [totalPages, setRowCountState]);

  // Getting grid data
  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const data = await getRadarLogs({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      });

      if (data) {
        setLogData(data);
        setTotalPages(data.meta.total);
      }

      // console.log("Dados iniciais obtidos:", data);
      setIsLoading(false);
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getData() {
    setIsLoading(true);

    const data = await getRadarLogs({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      camerasIds: (filterData.cameras as HeimdallCameraGroup)?.cameras_in_group
        ? (filterData.cameras as HeimdallCameraGroup).cameras_in_group
            .map((camera) => camera.id)
            .join(",")
        : filterData.cameras?.id.toString(),
      status: filterData.status,
      timestampAfter: filterData.dateAfter?.format("YYYY-MM-DD%20HH:mm"),
      timestampBefore: filterData.dateBefore?.format("YYYY-MM-DD%20HH:mm"),
      licensePlate: filterData.licensePlate,
      speed: filterData.speed,
    });

    if (data) {
      setTotalPages(data.meta.total);
      setLogData(data);
    }

    setIsLoading(false);
  }

  async function filter() {
    setIsLoading(true);
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });

    const data = await getRadarLogs({
      page: 1,
      pageSize: paginationModel.pageSize,
      camerasIds: (filterData.cameras as HeimdallCameraGroup)?.cameras_in_group
        ? (filterData.cameras as HeimdallCameraGroup).cameras_in_group
            .map((camera) => camera.id)
            .join(",")
        : filterData.cameras?.id.toString(),
      status: filterData.status,
      timestampAfter: filterData.dateAfter?.format("YYYY-MM-DD%20HH:mm"),
      timestampBefore: filterData.dateBefore?.format("YYYY-MM-DD%20HH:mm"),
      licensePlate: filterData.licensePlate,
      speed: filterData.speed,
    });

    if (data) {
      setLogData(data);
      setTotalPages(data.meta.total);
    }

    setIsLoading(false);
  }

  async function clearFilters() {
    setIsLoading(true);
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });

    const data = await getRadarLogs({
      page: 1,
      pageSize: paginationModel.pageSize,
    });

    if (data) {
      setLogData(data);
      setTotalPages(data.meta.total);
    }
    setIsLoading(false);
  }

  async function onPaginationModelChange(model: GridRowModel) {
    setIsLoading(true);
    // console.log(model, "Mudei o paginationModel");

    const newData = await getRadarLogs({
      page: model.page + 1,
      pageSize: model.pageSize,
      camerasIds: (filterData.cameras as HeimdallCameraGroup)?.cameras_in_group
        ? (filterData.cameras as HeimdallCameraGroup)?.cameras_in_group
            .map((camera) => camera.id)
            .join(",")
        : filterData.cameras?.id.toString(),
      status: filterData.status,
      timestampAfter: filterData.dateAfter?.format("YYYY-MM-DD%20HH:mm"),
      timestampBefore: filterData.dateBefore?.format("YYYY-MM-DD%20HH:mm"),
      licensePlate: filterData.licensePlate,
      speed: filterData.speed,
    });

    if (newData) {
      setLogData(newData);
      setTotalPages(newData.meta.total);
    }

    setPaginationModel({
      page: model.page,
      pageSize: model.pageSize,
    });

    setIsLoading(false);
  }

  async function handlePDF(row: RadarLog) {
    await getPDF(row)
      .then((blob) => {
        download(
          blob,
          `relatorio_${row.license_plate.toLowerCase()}_${row.created_at}`
        );
        toast({
          description: "PDF gerado com sucesso",
        });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          description: "Erro ao gerar PDF",
        });
      });
  }

  function handleOnConfigureAnalytics(cameraId: number) {
    navigate(`/cameras/${cameraId}/configure-analytics`);
  }

  function download(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  return (
    <MainContainer title="Log radar">
      <LogsFilter {...filterProps} isRadar />
      <LogsMultiViewer
        data={logData}
        withContainer
        apiRef={gridApiRef}
        rows={logData?.data || []}
        setSelectedOccurrence={setSelectedOccurrence}
        setOpenExpanded={setModalPhoto}
        columns={getRadarColumns(
          setSelectedOccurrence,
          setModalPhoto,
          setOpenEdit,
          handlePDF,
          handleOnConfigureAnalytics,
          user?.company.id
        )}
        loading={isLoading}
        paginationMode="server"
        rowCount={rowCountState}
        disableRowSelectionOnClick
        pageSizeOptions={[15, 25, 50, 100]}
        slots={{ toolbar: GridToolbar }}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
      {selectedOccurrence && (
        <>
          <ExpandImageModal
            open={photoModal}
            setOpen={setModalPhoto}
            log={selectedOccurrence}
            location={`Local: ${selectedOccurrence?.camera.camera_address.address}`}
            title={`${selectedOccurrence?.camera.camera_name}, às ${selectedOccurrence?.created_at}`}
            radarInfo={`Placa: ${selectedOccurrence?.license_plate}. Velocidade ${selectedOccurrence?.velocity}km/h`}
          />
          <EditLogModal
            onSubmit={async () => getData()}
            open={openEdit}
            log={selectedOccurrence}
            onClose={() => setOpenEdit(false)}
            location={`Local: ${selectedOccurrence?.camera.camera_address.address}`}
            title={`${selectedOccurrence?.camera.camera_name}, às ${selectedOccurrence?.created_at}`}
          />
        </>
      )}
    </MainContainer>
  );
}
