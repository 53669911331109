import React from "react";
import { GridLayout } from "pages/cameras-mosaic/CamerasMosaic";

export default function DynamicGrid({
  gridLayout,
  children,
}: {
  gridLayout: GridLayout;
  children: React.ReactNode;
}) {
  switch (JSON.stringify(gridLayout)) {
    case JSON.stringify({ rows: 1, columns: 1 }):
      return <div className="grid grid-cols-1">{children}</div>;
    case JSON.stringify({ rows: 2, columns: 2 }):
      return <div className="grid grid-cols-2">{children}</div>;
    case JSON.stringify({ rows: 2, columns: 3 }):
      return <div className="grid grid-cols-3">{children}</div>;
    case JSON.stringify({ rows: 3, columns: 2 }):
      return <div className="grid grid-cols-2">{children}</div>;
    case JSON.stringify({ rows: 3, columns: 3 }):
      return <div className="grid grid-cols-3">{children}</div>;
    case JSON.stringify({ rows: 3, columns: 4 }):
      return <div className="grid grid-cols-4">{children}</div>;
    case JSON.stringify({ rows: 4, columns: 3 }):
      return <div className="grid grid-cols-3">{children}</div>;
    case JSON.stringify({ rows: 4, columns: 4 }):
      return <div className="grid grid-cols-4">{children}</div>;
    case JSON.stringify({ rows: 4, columns: 5 }):
      return <div className="grid grid-cols-5">{children}</div>;
    default:
      return <div className="grid grid-cols-2">{children}</div>;
  }
}
