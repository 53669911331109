import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { argbFromHex, Scheme } from "@material/material-color-utilities";
import { createHeimdallMuiTheme } from "styles/muiTheme";
import { defaultHeimdallMuiDarkPalette } from "lib/themeDefaults";
import { defaultHeimdallMuiLightPalette } from "lib/themeDefaults";
import { lighten, ThemeProvider } from "@mui/material/styles";
import { fromRgbaToString } from "styles/colors";
import {
  HeimdallTheme as HeimdallThemeBackend,
  ThemeContext,
} from "interfaces/heimdallTheme";
import {
  applyFavicon,
  argbSchemeToRgbaScheme,
  correctLogoUrlFromUrl,
  themeFromSourceColors,
} from "lib/themeUtils";
import { ThemeState } from "interfaces/heimdallTheme";
import { AuxScheme } from "styles/md3AuxScheme";
import { getDefaultHeimdallThemeState } from "lib/themeDefaults";
import { muiPaletteFromHeimdallScheme } from "lib/themeUtils";
import { applyMd3Theme } from "lib/themeUtils";
import { StyledEngineProvider } from "@mui/material/styles";
import { Preferences } from "@capacitor/preferences";

const HeimdallThemeProvider = ({ children }: React.PropsWithChildren) => {
  const rootContainer = useMemo(
    () => document.getElementById("root") as HTMLDivElement,
    []
  );
  const [themeState, setThemeState] = useState<ThemeState>(
    defaultHeimdallThemeState
  );
  const [images, setImages] = useState(defaultHeimdallThemeContext.images);

  const loadHeimdallBackendTheme = useCallback(
    (theme: ThemeState, newImages: ThemeContext["images"]) => {
      // Transforming the JSON into a Scheme that can be handled by md3 library
      theme.md3Theme.schemes = {
        // @ts-expect-error
        light: new AuxScheme(theme.md3Theme.schemes.light) as Scheme,
        // @ts-expect-error
        dark: new AuxScheme(theme.md3Theme.schemes.dark) as Scheme,
      };
      // Self explanatory stuff
      setImages({
        logoDark: newImages.logoDark,
        logoLight: newImages.logoLight,
        favicon: newImages.favicon,
      });
      setThemeState({
        ...theme,
        muiTheme: createHeimdallMuiTheme(theme.muiTheme.palette, rootContainer),
      });
      applyFavicon(newImages.favicon!);
      applyMd3Theme(theme.md3Theme, rootContainer);
    },
    [rootContainer]
  );

  const setHeimdallTheme = useCallback(
    (theme: ThemeState, newImages?: Partial<ThemeContext["images"]>) => {
      if (!!newImages) {
        const noLogoDark = !newImages.logoDark || newImages.logoDark === "";
        const noLogoLight = !newImages.logoLight || newImages.logoLight === "";
        const noFavicon = !newImages.favicon || newImages.favicon === "";
        setImages((prevImages) => ({
          logoDark: noLogoDark ? prevImages.logoDark : newImages.logoDark!,
          logoLight: noLogoLight ? prevImages.logoLight : newImages.logoLight!,
          favicon: noFavicon ? prevImages.favicon : newImages.favicon!,
        }));
        if (!noFavicon) applyFavicon(newImages.favicon!);
        setThemeState({
          ...theme,
          muiTheme: createHeimdallMuiTheme(
            theme.muiTheme.palette,
            rootContainer
          ),
        });
      } else {
        setThemeState({
          ...theme,
          muiTheme: createHeimdallMuiTheme(
            muiPaletteFromHeimdallScheme(
              theme.md3Schemes.light,
              rootContainer,
              theme.isDark
            ).palette,
            rootContainer
          ),
        });
      }
      applyMd3Theme(theme.md3Theme, rootContainer);
    },
    [rootContainer]
  );

  // Theme initialization (usually run after page refresh or on new tab opened)
  useEffect(() => {
    const initializeTheme = async () => {
      console.log("initializing theme");
      const defaultTheme = await Preferences.get({ key: "defaultTheme" });
      if (defaultTheme.value) {
        const parsedTheme = JSON.parse(
          defaultTheme.value
        ) as HeimdallThemeBackend;
        loadHeimdallBackendTheme(parsedTheme.state, {
          logoDark: correctLogoUrlFromUrl(parsedTheme.logo_dark),
          logoLight: correctLogoUrlFromUrl(parsedTheme.logo_light),
          favicon: correctLogoUrlFromUrl(parsedTheme.favicon),
        });
      } else applyMd3Theme(defaultHeimdallThemeState.md3Theme, rootContainer);
    };

    initializeTheme();
  }, [loadHeimdallBackendTheme, rootContainer]);

  const setHeaderLogoMode = (mode: "light" | "dark") => {
    setThemeState((prevThemeState) => ({
      ...prevThemeState,
      headerLogoMode: mode,
    }));
  };

  const toggleDarkMode = () => {
    changeMuiPalette(themeState.isDark ? false : true);
    setThemeState((prevThemeState) => {
      const isDark = !prevThemeState.isDark;
      return { ...prevThemeState, isDark };
    });
  };

  const toggleClassicMode = () => {
    setThemeState((prevThemeState) => {
      const isClassic = !prevThemeState.isClassic;
      return { ...prevThemeState, isClassic };
    });
  };

  /**
   * Generates a mui palette based on the current theme state's primary and secondary colors and then sets it
   * @param isDarkPalette indicates if the palette you want to generate should be dark or light
   */
  const changeMuiPalette = (isDarkPalette?: boolean) => {
    const primary = themeState.md3Schemes.light.primary;
    const secondary = themeState.md3Schemes.light.secondary;

    const newMuiTheme = isDarkPalette
      ? createHeimdallMuiTheme(
          {
            ...defaultHeimdallMuiDarkPalette,
            primary: { main: lighten(fromRgbaToString(primary), 0.3) },
            secondary: { main: fromRgbaToString(secondary) },
          },
          rootContainer
        )
      : createHeimdallMuiTheme(
          {
            ...defaultHeimdallMuiLightPalette,
            primary: { main: fromRgbaToString(primary) },
            secondary: { main: fromRgbaToString(secondary) },
          },
          rootContainer
        );

    setThemeState((prevThemeState) => ({
      ...prevThemeState,
      muiTheme: newMuiTheme,
    }));
  };

  /**
   * Generates a complete Heimdall light theme state containing a material design 3 theme,
   * a mui theme and the material design 3 scheme util
   * @param baseColor the primary color of your application
   * @param secondaryColor the secondary color of your application
   * @param tertiaryColor the tertiary and less important color of your application
   */
  const setThemeFromSourceColors = (
    baseColor: string,
    secondaryColor: string | null = null,
    tertiaryColor: string | null = null
  ) => {
    const theme = themeFromSourceColors(
      argbFromHex(baseColor),
      secondaryColor ? argbFromHex(secondaryColor) : null,
      tertiaryColor ? argbFromHex(tertiaryColor) : null
    );

    const lightScheme = argbSchemeToRgbaScheme(theme.schemes.light);
    const darkScheme = argbSchemeToRgbaScheme(theme.schemes.dark);
    const newMd3Schemes = { light: lightScheme, dark: darkScheme };

    setThemeState((prevThemeState) => ({
      ...prevThemeState,
      isCustom: false,
      md3Theme: theme,
      md3Schemes: newMd3Schemes,
      muiTheme: muiPaletteFromHeimdallScheme(
        newMd3Schemes.light,
        rootContainer
      ),
    }));
    applyMd3Theme(theme, rootContainer);
  };

  return (
    <StyledEngineProvider injectFirst>
      <HeimdallThemeContext.Provider
        value={{
          ...themeState,
          images,
          toggleDarkMode,
          setHeimdallTheme,
          toggleClassicMode,
          setHeaderLogoMode,
          loadHeimdallBackendTheme,
          setThemeFromSourceColors,
        }}
      >
        <ThemeProvider theme={themeState.muiTheme}>{children}</ThemeProvider>
      </HeimdallThemeContext.Provider>
    </StyledEngineProvider>
  );
};

const useHeimdallTheme = () => {
  return useContext(HeimdallThemeContext);
};

const defaultHeimdallThemeState: ThemeState = getDefaultHeimdallThemeState();

const defaultHeimdallThemeContext: ThemeContext = {
  ...defaultHeimdallThemeState,
  images: { logoLight: "", logoDark: "", favicon: "" },
  setHeaderLogoMode(mode) {},
  setHeimdallTheme(theme, backendInfo) {},
  toggleClassicMode() {},
  toggleDarkMode() {},
  loadHeimdallBackendTheme(theme, images) {},
  setThemeFromSourceColors(
    sourceColor,
    secondaryColor,
    tertiaryColor,
    customColors
  ) {},
};

const HeimdallThemeContext = createContext<ThemeContext>(
  defaultHeimdallThemeContext
);

export { HeimdallThemeProvider, useHeimdallTheme };
