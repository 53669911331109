import React, { useEffect, useState } from "react";
import bg from "assets/icons/forenseMap.svg";
import { useRef } from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import cam from "assets/icons/camMap.svg";
import styles from "./mapCheckpoint.module.css";
import { apiConfig } from "api/rootConfig";

export default function MapCheckpoint() {
  const canvas = useRef();
  const [camerasInfo, setCameraInfo] = useState([]);
  let ctx = null;
  const { points } = useContext(GlobalContext);
  const navigate = useNavigate();

  const getCAM = async () => {
    const response = await fetch(
      apiConfig.baseUrlNoPort + ":5600/api/forensic/camera",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 404) {
      alert("erro na API");
    } else {
      const data = await response.json();
      const transformedData = data.map((camera) => {
        return {
          name: camera.description,
          x: camera.x * 800,
          y: camera.y * 600,
        };
      });
      setCameraInfo(transformedData);
    }
  };

  useEffect(() => {
    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;
    getCAM();
    ctx = canvasEle.getContext("2d");
    var img = document.getElementById("cam");
    //Imagem nao renderiza

    for (var i = 0; i < points.length; i++) {
      const widthPoint = points[i].selected ? 5 : 3;
      const heightPoint = points[i].selected ? 5 : 3;
      const lineWid = points[i].selected ? 5 : 3;
      ctx.beginPath();
      ctx.strokeStyle = points[i].selected ? "red" : "blue";
      ctx.lineWidth = lineWid;
      ctx.rect(points[i].x, points[i].y, widthPoint, heightPoint);
      ctx.stroke();
    }

    // for (var i = 0; i < camerasInfo.length; i++) {
    //   ctx.beginPath();
    //   ctx.drawImage(img, camerasInfo[i].x, camerasInfo[i].y, 30, 30);
    //   ctx.stroke()
    // }
  }, []);

  return (
    <div className={styles.cardsContainer}>
      <img id="cam" width="0" height="0" src={cam} alt="CAM"></img>
      <div>
        <div>
          <h2>Visualização</h2>
          <p>
            Veja com mais detalhes por onde a face capturada passou pelo mapa
          </p>
        </div>
        <button
          className={styles.btnSend}
          onClick={() => navigate("/checkpoint")}
        >
          RETORNAR
        </button>
      </div>
      <div className={styles.canvasStyle}>
        <canvas
          ref={canvas}
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></canvas>
      </div>
    </div>
  );
}
