import { SpecificLogUrlPath } from "interfaces/occurrences";

export const API_ROOT = "/api"; // Optional if your API endpoints have a common base URL
// Analytics
export const AVAILABLE_ANALYTICS_QUERY_KEY = `${API_ROOT}/alert_system/analytic_types`;
// Camera groups
export const CAMERA_GROUPS_QUERY_KEY = `${API_ROOT}/camera-groups`;
// Cameras
export const CAMERAS_QUERY_KEY = `${API_ROOT}/cameras`;
export const CAMERA_BY_ID_QUERY_KEY = (cameraId: string | number) =>
  `${API_ROOT}/camera/${cameraId}`;
export const CAMERAS_SIMPLIFIED_QUERY_KEY = `${API_ROOT}/dashboard_cameras`;
export const CAMERAS_MAP_QUERY_KEY = `${API_ROOT}/camera_map`;
export const SHARED_CAMERAS_RELATIONS_QUERY_KEY = `${API_ROOT}/camera_share`;
export const CAMERAS_SHARED_WITH_ME_QUERY_KEY = `${API_ROOT}/camera_share_others`;
// Logs
export const ALL_LOGS_QUERY_KEY = `${API_ROOT}/service_log`;
export const LOGS_BY_ANALYTIC_QUERY_KEY = (logType: SpecificLogUrlPath) =>
  `${API_ROOT}/${logType}`;
// Company
export const COMPANIES_QUERY_KEY = `${API_ROOT}/company`;
export const COMPANY_BY_ID_QUERY_KEY = (companyId: string) =>
  `${API_ROOT}/companies/${companyId}`;
// Plans
export const PLANS_QUERY_KEY = `${API_ROOT}/plan`;
// Operators
export const OPERATORS_QUERY_KEY = `${API_ROOT}/user_company`;
export const OPERATOR_BY_ID_QUERY_KEY = (operatorId: string) =>
  `${API_ROOT}/user_company/${operatorId}`;
// Users
export const USERS_FROM_INSTANCE_QUERY_KEY = `${API_ROOT}/camera_share_users`;
export const USERS_FROM_COMPANY_AND_SHARED_CAMERAS = `${API_ROOT}/alert_system_users`;
export const USER_PROFILE_QUERY_KEY = `${API_ROOT}/user_profile`;
// Alert system
export const DISPATCHERS_QUERY_KEY = `${API_ROOT}/alert_system`;
// Machines
export const MACHINES_QUERY_KEY = `${API_ROOT}/machines`;
