import React, { useContext, useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar";
import GlobalContext from "context/GlobalContext";
import Header from "components/Header/Header";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
import { StatusBar } from "@capacitor/status-bar";
import { argbFromRgba, hexFromArgb } from "@material/material-color-utilities";
import { isPlatform } from "@ionic/react";
import { HeimdallUser } from "interfaces/generic";
import BottomNavigation from "../BottomNavigation/BottomNavigation";
import getDeviceInfo from "lib/deviceInfo";
import { registerDevice } from "api/user";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import { PushNotifications } from "@capacitor/push-notifications";
import { registerNotifications } from "lib/pushNotifications";
import { Preferences } from "@capacitor/preferences";

export default function MainLayout() {
  const { md3Schemes } = useHeimdallTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { user, setUser } = useContext(GlobalContext);

  const storedUser = useLoaderData() as HeimdallUser | null;

  // Mobile status bar color the same as header color
  useEffect(() => {
    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      StatusBar.setBackgroundColor({
        color: hexFromArgb(argbFromRgba(md3Schemes.light.primaryContainer)),
      });
    }
  }, [md3Schemes]);

  // Handles firebase push notifications on mobile devices
  useEffect(() => {
    async function handleFirebasePushNotifications() {
      await PushNotifications.removeAllListeners();
      let _user = user;
      if (!_user) {
        _user = storedUser;
      }
      if (!_user) return;
      const deviceInfo = await getDeviceInfo();

      await PushNotifications.addListener("registration", async (token) => {
        console.info("Registration token: ", token.value);
        const hasToken = _user?.messaging_tokens.find(
          (value) => value.device_token === token.value
        );
        if (!!_user && !hasToken) {
          await registerDevice(_user.id, {
            device_id: deviceInfo.deviceId,
            device_info: deviceInfo.deviceInfo,
            device_token: token.value,
          }).catch((err) => {
            toast({
              variant: "destructive",
              title: "Erro ao registrar dispositivo",
              description: getErrorMessage(err),
            });
          });
        }
      });

      await PushNotifications.addListener("registrationError", (err) => {
        console.error("Registration error: ", err.error);
      });
      // Tries to register notifications for the user as soon as he logs in or refreshes the app
      registerNotifications().catch((err) => {
        console.log(err);
      });
    }

    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      handleFirebasePushNotifications();
    }
  }, [toast, storedUser, user]);

  // Handles device access to the app/website
  useEffect(() => {
    async function handleDeviceAccess() {
      const deviceInfo = await getDeviceInfo();
      let _user = user;
      if (!_user) {
        _user = storedUser;
      }
      if (!_user) return;

      if (
        !_user.messaging_tokens.find(
          (value) => value.device_id === deviceInfo.deviceId
        )
      ) {
        await registerDevice(_user.id, {
          device_id: deviceInfo.deviceId,
          device_info: deviceInfo.deviceInfo,
        }).catch((err) => {
          toast({
            variant: "destructive",
            description: getErrorMessage(err),
          });
          if (err.response && err.response.status === 412) {
            navigate("/auth/remove-devices", { replace: true });
          }
        });
      }
    }
    handleDeviceAccess();
  }, [navigate, user, storedUser, toast]);

  // Handles user info and authentication
  useEffect(() => {
    async function handleUserAndToken() {
      const token = await Preferences.get({ key: "token" });

      if (!user && storedUser && token.value) {
        setUser(storedUser);
      } else if (!user && storedUser && !token.value) {
        navigate("/auth", { replace: true });
      }
    }
    handleUserAndToken();
  }, [user, setUser, storedUser, navigate, toast]);

  if (!user && !storedUser) return <Navigate to="/auth" replace />;

  return (
    <>
      <Header />
      <main
        className={
          location.pathname.match(/monitor(\/[w-]*)?/)
            ? "appContainerDASH"
            : "appContainer"
        }
      >
        <Sidebar />
        <Outlet />
      </main>
      <BottomNavigation />
    </>
  );
}
