import React, { useEffect, useMemo } from "react";
import { getCamerasForMap } from "api/cameras";
import MainContainer from "components/MainContainer/MainContainer";
import {
  APIProvider,
  Map,
  useMapsLibrary,
  useMap,
} from "@vis.gl/react-google-maps";
import { useQuery } from "@tanstack/react-query";
import { CAMERAS_MAP_QUERY_KEY } from "constants/apiQueryKeys";
import AdvancedMarkerWithInfoWindow from "./AdvancedMarkerWithInfoWindow";

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

// TODO: testar o mapa quando não há câmeras, ou quando não há câmeras com coordenadas

export default function CamerasMap() {
  const { data: cameras } = useQuery({
    queryKey: [CAMERAS_MAP_QUERY_KEY],
    queryFn: () => getCamerasForMap(),
  });
  const camerasWithCoordinates = useMemo(() => {
    if (!cameras) return [];
    return cameras.data.filter(
      (value) =>
        !!value.camera_address.coordinate &&
        value.camera_address.coordinate.latitude !== ""
    );
  }, [cameras]);

  const ComponentToControlMapBecauseLibraryIsNotSoGood = () => {
    // useMapsLibrary loads the core library, it might initially return `null`
    // if the library hasn't been loaded. Once loaded, it will return the library
    // object as it would be returned by `await google.maps.importLibrary()`
    const coreLib = useMapsLibrary("core");
    const map = useMap();
    const latLngBounds = useMemo(
      () => coreLib && new coreLib.LatLngBounds(),
      [coreLib]
    );

    useEffect(() => {
      if (!map || !latLngBounds || camerasWithCoordinates.length === 0) return;

      camerasWithCoordinates.forEach((camera) => {
        latLngBounds.extend({
          lat: parseFloat(camera.camera_address.coordinate!.latitude),
          lng: parseFloat(camera.camera_address.coordinate!.longitude),
        });
      });
      map.fitBounds(latLngBounds);
    }, [latLngBounds, map]);

    return <></>;
  };

  if (!mapsApiKey) {
    return (
      <MainContainer title="Mapa de câmeras" returnAction>
        <p>Chave de acesso ao mapa não disponível</p>
      </MainContainer>
    );
  }

  return (
    <APIProvider apiKey={mapsApiKey}>
      <MainContainer title="Mapa de câmeras" returnAction>
        <div className="w-full h-[calc(100dvh-var(--header-height)-120px)]">
          <Map
            defaultCenter={{ lat: Campinas.latitude, lng: Campinas.longitude }}
            defaultZoom={10}
            mapId="DEMO_MAP_ID"
          >
            {camerasWithCoordinates.map((camera) => (
              <AdvancedMarkerWithInfoWindow key={camera.id} camera={camera} />
            ))}
          </Map>
        </div>
        <ComponentToControlMapBecauseLibraryIsNotSoGood />
      </MainContainer>
    </APIProvider>
  );
}

const Campinas = {
  latitude: -22.907104,
  longitude: -47.06324,
};
