import { WhatsApp, Telegram, Person, AddCircle } from "@mui/icons-material";
import {
  TextField,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Autocomplete,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getUsersFromCompanyAndSharedCameras } from "api/user";
import { USERS_FROM_COMPANY_AND_SHARED_CAMERAS } from "constants/apiQueryKeys";
import { cn } from "lib/classNames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReceiverType } from "./ConfigureAnalytic";
import { ReceiverIcon } from "./ReceiverIcon";
import { HeimdallUser } from "interfaces/generic";
import { BackendResponsePagination } from "interfaces/generic";

interface CustomUser
  extends Pick<
    HeimdallUser,
    | "id"
    | "name"
    | "role"
    | "status"
    | "username"
    | "company"
    | "created_at"
    | "email"
  > {
  group: "" | "Usuários de compartilhamento";
}

async function fetchAndTransformUsersFromCompanyAndSharedCameras() {
  const usersFromCompanyAndSharedCameras =
    await getUsersFromCompanyAndSharedCameras();
  const transformedResponse = {
    data: usersFromCompanyAndSharedCameras.data.map((value) => ({
      ...value,
      group: "",
    })),
    meta: usersFromCompanyAndSharedCameras.meta,
    links: usersFromCompanyAndSharedCameras.links,
  };

  usersFromCompanyAndSharedCameras.data_shared.forEach((user) =>
    transformedResponse.data.push({
      ...user,
      group: "Usuários de compartilhamento",
    })
  );

  transformedResponse.data.unshift({
    id: -1,
    // @ts-expect-error
    company: {
      id: -1,
    },
    name: "Todos",
    created_at: "",
    email: "",
    group: "",
    role: "collaborator",
    status: "activated",
    status_shared: false,
    username: "Todos",
  });

  return transformedResponse as BackendResponsePagination<CustomUser>;
}

export function SimpleReceiverField({
  onAddReceiver,
}: {
  onAddReceiver: (type: ReceiverType, receiverInfo: string) => void;
}) {
  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: [USERS_FROM_COMPANY_AND_SHARED_CAMERAS],
    queryFn: () => fetchAndTransformUsersFromCompanyAndSharedCameras(),
  });
  const [selectedReceiverType, setSelectedReceiverType] =
    useState<ReceiverType | null>(null);
  const [hideFirstClickContainer, setHideFirstClickContainer] =
    useState(!!selectedReceiverType);
  const [receiverInfo, setReceiverInfo] = useState("");
  const [autocompleteReceiverInfo, setAutocompleteReceiverInfo] =
    useState<CustomUser | null>(null);
  const [receiverError, setReceiverError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Menu state
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const sortedUsers = useMemo(() => {
    return (
      users?.data.sort((a, b) => {
        if (a.group === "") return -1;
        return 1;
      }) || []
    );
  }, [users]);

  useEffect(() => {
    setHideFirstClickContainer(!!selectedReceiverType);
  }, [selectedReceiverType]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(buttonRef.current);
  };

  function handleReceiverType(type: ReceiverType) {
    setSelectedReceiverType(type);
    setAnchorEl(null);
  }

  function handleAddReceiver() {
    if (!selectedReceiverType) {
      setReceiverError(true);
      setErrorMessage("Você deve selecionar o tipo de remetente");
      return;
    }
    if (!receiverInfo || receiverInfo === "") {
      setReceiverError(true);
      setErrorMessage("Você deve preencher o campo");
      return;
    }

    onAddReceiver(selectedReceiverType, receiverInfo);
    setReceiverInfo("");
    setSelectedReceiverType(null);
  }

  if (selectedReceiverType === ReceiverType.user) {
    return (
      <>
        <Autocomplete
          fullWidth
          id="users-from-company-and-shared-cameras-autocomplete"
          options={sortedUsers}
          size="small"
          loading={isLoadingUsers}
          value={autocompleteReceiverInfo}
          onChange={(_, _value) => {
            if (!!_value) {
              setReceiverInfo(`${_value.id}/${_value.name}`);
            }
            setAutocompleteReceiverInfo(_value);
          }}
          isOptionEqualToValue={(option, _value) => option.id === _value.id}
          groupBy={(option) => option.group}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              name="id"
              label="Usuário"
              InputProps={{
                ...params.InputProps,
                sx: { borderRadius: "20px" },
                startAdornment: (
                  <>
                    <div
                      className={cn(
                        "absolute top-0 left-0 w-full h-10 rounded-2xl",
                        hideFirstClickContainer ? "hidden" : ""
                      )}
                      onClick={handleClick}
                    ></div>
                    <IconButton
                      ref={buttonRef}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <ReceiverIcon type={selectedReceiverType} />
                    </IconButton>
                    <Menu
                      open={open}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          handleReceiverType(ReceiverType.whatsapp)
                        }
                      >
                        <ListItemIcon>
                          <WhatsApp color="success" />
                        </ListItemIcon>
                        <ListItemText>WhatsApp</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleReceiverType(ReceiverType.telegram)
                        }
                      >
                        <ListItemIcon>
                          <Telegram color="info" />
                        </ListItemIcon>
                        <ListItemText>Telegram</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleReceiverType(ReceiverType.user)}
                      >
                        <ListItemIcon>
                          <Person />
                        </ListItemIcon>
                        <ListItemText>Usuário</ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                ),
              }}
            />
          )}
        />
        <IconButton color="primary" onClick={handleAddReceiver}>
          <AddCircle fontSize="large" />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <TextField
        fullWidth
        size="small"
        className="relative"
        value={receiverInfo}
        error={receiverError}
        helperText={receiverError ? errorMessage : ""}
        onChange={(ev) => setReceiverInfo(ev.target.value)}
        placeholder={
          hideFirstClickContainer ? "ID" : "Selecione o tipo de destinatário"
        }
        InputProps={{
          style: { borderRadius: "20px" },
          startAdornment: (
            <>
              <div
                className={cn(
                  "absolute top-0 left-0 w-full h-10 rounded-2xl",
                  hideFirstClickContainer ? "hidden" : ""
                )}
                onClick={handleClick}
              ></div>
              <IconButton
                ref={buttonRef}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <ReceiverIcon type={selectedReceiverType} />
              </IconButton>
              <Menu
                open={open}
                id="basic-menu"
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => handleReceiverType(ReceiverType.whatsapp)}
                >
                  <ListItemIcon>
                    <WhatsApp color="success" />
                  </ListItemIcon>
                  <ListItemText>WhatsApp</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleReceiverType(ReceiverType.telegram)}
                >
                  <ListItemIcon>
                    <Telegram color="info" />
                  </ListItemIcon>
                  <ListItemText>Telegram</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleReceiverType(ReceiverType.user)}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText>Usuário</ListItemText>
                </MenuItem>
              </Menu>
            </>
          ),
        }}
      />

      <IconButton color="primary" onClick={handleAddReceiver}>
        <AddCircle fontSize="large" />
      </IconButton>
    </>
  );
}
