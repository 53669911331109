import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForward, DeleteOutline, Domain } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { apiConfig } from "api/rootConfig";
import { domainFromCompanyName } from "api/auth";
import { useToast } from "components/ui/use-toast";
import { LoadingButton } from "@mui/lab";
import { Preferences } from "@capacitor/preferences";

const formSchema = z.object({
  companyName: z.string().min(1, "Campo obrigatório"),
});

type CompanyAuthSchema = z.infer<typeof formSchema>;
type HeimdallDomain = { domain: string; companyName: string };

export default function MobileCompanyForm({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyAuthSchema>({
    defaultValues: {
      companyName: "",
    },
    resolver: zodResolver(formSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [prevDomains, setPrevDomains] = useState<HeimdallDomain[]>([]);

  useEffect(() => {
    async function handlePrevDomains() {
      const rawPrevDomains = await Preferences.get({ key: "prevDomains" });
      if (rawPrevDomains.value) {
        setPrevDomains(JSON.parse(rawPrevDomains.value));
      }
    }
    handlePrevDomains();
  }, []);

  async function onSubmit(data: CompanyAuthSchema) {
    setIsLoading(true);
    await domainFromCompanyName(data.companyName)
      .then(async (res) => {
        apiConfig.updateBaseUrl(`https://${res.data.domain}`);
        const lowerCompanyName = data.companyName.toLowerCase();
        if (prevDomains.length !== 0) {
          const newPrevDomains = [...prevDomains];
          let hasDomain = false;
          newPrevDomains.forEach((value) => {
            if (value.companyName.toLowerCase() === lowerCompanyName) {
              hasDomain = true;
            }
          });
          if (!hasDomain) {
            newPrevDomains.unshift({
              companyName: lowerCompanyName,
              domain: res.data.domain,
            });
            await Preferences.set({
              key: "prevDomains",
              value: JSON.stringify(newPrevDomains),
            });
          }
        } else {
          const newDomains = JSON.stringify([
            { companyName: lowerCompanyName, domain: res.data.domain },
          ]);
          await Preferences.set({
            key: "prevDomains",
            value: newDomains,
          });
        }
        onSuccess();
      })
      .catch(() => {
        toast({
          variant: "destructive",
          description: "Um erro inesperado ocorreu",
        });
      });
    setIsLoading(false);
  }

  async function handleDeletePrevDomain(
    ev: React.MouseEvent,
    prevDomain: HeimdallDomain
  ) {
    ev.stopPropagation();
    const newPrevDomains = [...prevDomains];
    const removeIndex = newPrevDomains.findIndex(
      (value) =>
        value.companyName.toLowerCase() === prevDomain.companyName.toLowerCase()
    );
    if (removeIndex !== -1) {
      newPrevDomains.splice(removeIndex, 1);
    }
    setPrevDomains(newPrevDomains);
    await Preferences.set({
      key: "prevDomains",
      value: JSON.stringify(newPrevDomains),
    });
  }

  async function handlePrevDomain(value: {
    companyName: string;
    domain: string;
  }) {
    // Order the prev domains list based on the most recent domain accessed
    const newPrevDomains = [...prevDomains];
    const idx = newPrevDomains.findIndex(
      (_value) =>
        _value.companyName.toLowerCase() === value.companyName.toLowerCase()
    );
    if (idx !== 0) {
      const removedDomain = newPrevDomains.splice(idx, 1);
      newPrevDomains.unshift(removedDomain[0]);
      await Preferences.set({
        key: "prevDomains",
        value: JSON.stringify(newPrevDomains),
      });
      // no need to update the prevDomains state, since we're leaving the screen
    }
    // Updates the base url and proceeds
    apiConfig.updateBaseUrl(`https://${value.domain}`);
    onSuccess();
  }

  return (
    <form className="flex flex-col space-y-3" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Nome da organização"
        error={!!errors.companyName}
        helperText={errors.companyName?.message}
        inputProps={{
          ...register("companyName"),
        }}
      />
      <LoadingButton
        type="submit"
        variant="contained"
        loading={isLoading}
        startIcon={<ArrowForward />}
      >
        Continuar
      </LoadingButton>
      {prevDomains.length > 0 && <p>Organizações anteriores</p>}
      {prevDomains.map((prevDomain, idx) => (
        <div
          key={idx}
          className="flex w-full border rounded-md justify-between items-center px-2 py-1"
          onClick={() => handlePrevDomain(prevDomain)}
        >
          <div className="flex items-center space-x-2">
            <div>
              <Domain />
            </div>
            <div className="mt-1">{prevDomain.companyName.toUpperCase()}</div>
          </div>
          <IconButton
            size="small"
            color="error"
            onClick={(ev) => handleDeletePrevDomain(ev, prevDomain)}
          >
            <DeleteOutline />
          </IconButton>
        </div>
      ))}
    </form>
  );
}
