import React, { useContext, useMemo, useState } from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { Analytic } from "interfaces/analytics";
import {
  getAnalyticCardDescription,
  getAnalyticCardImage,
  getAnalyticCardTitle,
} from "lib/analytics";
import {
  GppBad,
  GppGood,
  GppMaybe,
  Info,
  InfoOutlined,
} from "@mui/icons-material";
import {
  canUpdateAnalytic,
  updateAnalyticMiddleware,
} from "lib/configureAnalytic";
import { convertAnalyticToAnalyticForm } from "lib/addCamera";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import { animated, useSpring, useSpringRef } from "@react-spring/web";
import GlobalContext from "context/GlobalContext";
import {
  muteAnalyticPushNotifications,
  unmuteAnalyticPushNotifications,
  updateAnalytic,
} from "api/analytics";
import { isUserMobileUser } from "lib/isUserMobileUser";

interface AnalyticCardProps {
  analytic: Analytic;
  onSelectAnalytic: () => void;
  onActivateAnalytic: () => Promise<void>;
}

export default function AnalyticCard({
  analytic,
  onSelectAnalytic,
  onActivateAnalytic,
}: AnalyticCardProps) {
  const { toast } = useToast();
  const { user } = useContext(GlobalContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingMute, setIsUpdatingMute] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const springApi = useSpringRef();
  const [rotateSprings, rotate] = useSpring(() => ({
    ref: springApi,
    from: { rotate: 0 },
  }));
  const [scaleSprings, scale] = useSpring(() => ({
    ref: springApi,
    from: { scale: 1 },
  }));

  const mutedNotificationsObject = useMemo(
    () =>
      analytic.userMutedAnalytic.find((value) => value.user_id === user?.id),
    [analytic, user]
  );

  const analyticForm = useMemo(
    () => convertAnalyticToAnalyticForm(analytic),
    [analytic]
  );
  const canActivateAnalytic = useMemo(
    () =>
      canUpdateAnalytic(analyticForm, {
        checked: [true, true, true, true, true, true, true],
      }),
    [analyticForm]
  );

  function playRotationAnimation() {
    rotate.start({
      from: {
        rotate: 0,
      },
      to: {
        rotate: 360,
      },
    });
  }

  function playScaleAnimation(reverse?: boolean) {
    scale.start({
      from: {
        scale: 1,
      },
      to: {
        scale: 0,
      },
      reverse,
    });
  }

  async function handleActivateAnalytic() {
    setIsUpdating(true);
    playRotationAnimation();
    if (analytic.analytic_type === "Radar") {
      await updateAnalytic(
        { activated: !analytic.activated },
        "Radar",
        analytic.id
      )
        .then(async () => {
          toast({
            description: analytic.activated
              ? "Analítico desativado com sucesso"
              : "Analítico ativado com sucesso",
          });
          playScaleAnimation();
          await onActivateAnalytic();
          playScaleAnimation(true);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: `Falha ao ${analytic.activated ? "desativar" : "ativar"} analítico`,
            description: getErrorMessage(err),
          });
          playScaleAnimation();
          setTimeout(() => {
            playScaleAnimation(true);
          }, 1000);
        });
      return setIsUpdating(false);
    }

    await updateAnalyticMiddleware({
      analytic: analyticForm,
      checkedDaysOfWeek: [true, true, true, true, true, true, true],
    })
      .then(async () => {
        toast({
          description: analytic.activated
            ? "Analítico desativado com sucesso"
            : "Analítico ativado com sucesso",
        });
        playScaleAnimation();
        await onActivateAnalytic();
        playScaleAnimation(true);
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: `Falha ao ${analytic.activated ? "desativar" : "ativar"} analítico`,
          description: getErrorMessage(err),
        });
        playScaleAnimation();
        setTimeout(() => {
          playScaleAnimation(true);
        }, 1000);
      });
    setIsUpdating(false);
  }

  async function handleMuteAnalyticPushNotifications(shouldMute: boolean) {
    setIsUpdatingMute(true);
    if (shouldMute) {
      await muteAnalyticPushNotifications({
        analyticId: analytic.id,
      })
        .then(async () => {
          toast({
            description: "Notificações de celular silenciadas com sucesso",
          });
          await onActivateAnalytic();
          setIsUpdatingMute(false);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao silenciar notificações de celular",
            description: getErrorMessage(err),
          });
          setIsUpdatingMute(false);
        });
    } else {
      await unmuteAnalyticPushNotifications(mutedNotificationsObject!.id)
        .then(async () => {
          toast({
            description: "Notificações de celular habilitadas com sucesso",
          });
          await onActivateAnalytic();
          setIsUpdatingMute(false);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao habilitar notificações de celular",
            description: getErrorMessage(err),
          });
          setIsUpdatingMute(false);
        });
    }
  }

  return (
    <Card className="shadow-md w-full rounded-xl">
      <CardActionArea className="rounded-none" onClick={onSelectAnalytic}>
        <div className="relative">
          <CardMedia
            sx={{ height: 150 }}
            image={getAnalyticCardImage(analytic.analytic_type)}
            title={getAnalyticCardTitle(analytic.analytic_type)}
          />
          <animated.div
            className="absolute top-0 right-2"
            style={{ ...rotateSprings, ...scaleSprings }}
          >
            {analytic.activated ? (
              <GppGood className="text-5xl" color="success" />
            ) : analytic.pause ? (
              <GppMaybe className="text-5xl" color="warning" />
            ) : (
              <GppBad className="text-5xl" color="error" />
            )}
          </animated.div>
        </div>
      </CardActionArea>
      <Divider />
      <CardContent className="py-1">
        <Typography
          className="text-xl my-2"
          gutterBottom
          variant="h5"
          component="div"
        >
          {getAnalyticCardTitle(analytic.analytic_type)}
        </Typography>
        <div className="flex items-center justify-between">
          <p className="text-gray-500">Ativado</p>
          <Switch
            checked={analytic.activated}
            disabled={isUpdating || !canActivateAnalytic}
            onChange={() => handleActivateAnalytic()}
          />
        </div>
        {!!user && isUserMobileUser(user) && (
          <div className="mt-1 flex items-center justify-between">
            <p className="text-gray-500">Notificações celular</p>
            <Switch
              checked={!mutedNotificationsObject}
              disabled={isUpdatingMute}
              onChange={(ev, checked) =>
                handleMuteAnalyticPushNotifications(!checked)
              }
            />
          </div>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <Info color="secondary" />
          ) : (
            <InfoOutlined color="secondary" />
          )}
        </IconButton>
      </CardActions>
      <Collapse in={expanded}>
        <CardContent className="pt-0 min-h-28">
          <Typography variant="body2" color="text.secondary">
            {getAnalyticCardDescription(analytic.analytic_type)}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
