import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import { getUser, removeConnectedDevice } from "api/user";
import MainContainer from "components/MainContainer/MainContainer";
import { useToast } from "components/ui/use-toast";
import GlobalContext from "context/GlobalContext";
import { ConnectedDevice } from "interfaces/generic";
import { getErrorMessage } from "lib/apiErrorHandler";
import { ConnectedDevicesList } from "pages/profile/ConnectedDevicesList";
import SecurityUpdateWarningIcon from "@mui/icons-material/SecurityUpdateWarning";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useBlocker, useNavigate } from "react-router-dom";
import { isPlatform } from "@ionic/react";
import getDeviceInfo from "lib/deviceInfo";
import { ArrowBack } from "@mui/icons-material";
import { cleanUp } from "lib/logoutUtils";

export default function RemoveDevices() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user, setUser, logout } = useContext(GlobalContext);
  const [deviceId, setDeviceId] = useState<string>();
  const [isRemoving, setIsRemoving] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<ConnectedDevice | null>(
    null
  );
  const canNavigateAway = useRef(false);

  // Block navigating elsewhere when data has been entered into the input
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      !canNavigateAway.current &&
      currentLocation.pathname !== nextLocation.pathname
    ) {
      cleanUp();
      setUser(null);
      return false;
    }
    return false;
  });

  useEffect(() => {
    function handleBeforeUnload() {
      if (!canNavigateAway.current) {
        // calling logout here does not work, so I just clean up
        cleanUp();
        setUser(null);
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [setUser]);

  useEffect(() => {
    async function setCurrentDeviceIdAndInfo() {
      const currentDeviceInfo = await getDeviceInfo();
      setDeviceId(currentDeviceInfo.deviceId);
    }
    setCurrentDeviceIdAndInfo();
  }, []);

  async function handleRemoveDevice() {
    if (!user || !selectedDevice) {
      return toast({
        variant: "destructive",
        title: "Erro ao remover dispositivo",
        description: "Identificador de usuário ou dispositivo não definidos",
      });
    }

    setIsRemoving(true);

    await removeConnectedDevice(user.id, selectedDevice?.device_id)
      .then(async () => {
        toast({
          description: "Dispositivo removido com sucesso",
        });
        canNavigateAway.current = true;
        setIsRemoving(false);
        setSelectedDevice(null);
        await getUser()
          .then(async (updatedUser) => {
            setUser(updatedUser);

            if (isPlatform("mobile")) {
              navigate("/cameras", { replace: true });
            } else {
              navigate("/", { replace: true });
            }
          })
          .catch((err) => {
            toast({
              variant: "destructive",
              title: "Erro obter dados do usuário",
              description: getErrorMessage(err),
            });
          });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao remover dispositivo",
          description: getErrorMessage(err),
        });
        setIsRemoving(false);
        setSelectedDevice(null);
      });
  }

  return (
    <MainContainer
      title={
        <div className="flex items-center space-x-2 mb-1">
          <h2 className="text-lg">Remova dispositivos</h2>
          <SecurityUpdateWarningIcon fontSize="large" />
        </div>
      }
      returnAction={
        <IconButton
          onClick={() => {
            canNavigateAway.current = true;
            logout();
            navigate("/auth");
          }}
        >
          <ArrowBack />
        </IconButton>
      }
    >
      <p className="text-lg">
        Você ultrapassou o limite de dispositivos distintos registrados.
      </p>
      <p className="text-gray-500 mb-3">
        Para prosseguir, remova pelo menos um dos dispositivos da lista abaixo
      </p>
      <ConnectedDevicesList
        currentDeviceId={deviceId}
        connectedDevices={user?.messaging_tokens || []}
        onRemoveDevice={(device) => setSelectedDevice(device)}
      />
      <Dialog open={!!selectedDevice} onClose={() => setSelectedDevice(null)}>
        <DialogTitle>
          Tem certeza que deseja remover o dispositivo{" "}
          {selectedDevice?.device_info.name ||
            selectedDevice?.device_info.model}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            O dispositivo a ser removido não receberá mais notificações
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedDevice(null)}>Cancelar</Button>
          <LoadingButton
            color="error"
            loading={isRemoving}
            onClick={handleRemoveDevice}
          >
            Sim
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
}
