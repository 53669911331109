import React, { useContext, useEffect } from "react";
import GlobalContext from "context/GlobalContext";
import Header from "components/Header/Header";
import { Navigate, Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { StatusBar } from "@capacitor/status-bar";
import { isPlatform } from "@ionic/react";
import { hexFromArgb, argbFromRgba } from "@material/material-color-utilities";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
import { HeimdallUser } from "interfaces/generic";
import { Preferences } from "@capacitor/preferences";

export function MosaicLayout() {
  const { md3Schemes } = useHeimdallTheme();
  const { user, setUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const storedUser = useLoaderData() as HeimdallUser | null;

  // Mobile status bar color the same as header color
  useEffect(() => {
    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      StatusBar.setBackgroundColor({
        color: hexFromArgb(argbFromRgba(md3Schemes.light.primaryContainer)),
      });
    }
  }, [md3Schemes]);

  useEffect(() => {
    async function handleUserAndToken() {
      const token = await Preferences.get({ key: "token" });

      if (!user && storedUser && token.value) {
        setUser(storedUser);
      } else if (!user && storedUser && !token.value) {
        navigate("/auth", { replace: true });
      }
    }
    handleUserAndToken();
  }, [user, storedUser, setUser, navigate]);

  if (!user && !storedUser) return <Navigate to="/auth" replace />;

  return (
    <>
      <Header />
      <main className="appContainerDASH">
        <Outlet />
      </main>
    </>
  );
}
