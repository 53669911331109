import React, { useState } from "react";
import {
  AutocompleteGroupedOption,
  IconButton,
  TextField,
  useAutocomplete,
} from "@mui/material";
import { DispatcherListData } from "interfaces/alertSystem";
import { getDispatchers } from "api/alertSystem";
import styles from "./AnalyticControls.module.css";
import { AnalyticType } from "interfaces/analytics";
import { useQuery } from "@tanstack/react-query";
import { deleteDispatcher } from "api/alertSystem";
import { DISPATCHERS_QUERY_KEY } from "constants/apiQueryKeys";
import { ArrowDropDown, DeleteOutline } from "@mui/icons-material";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import DeleteDialog from "components/Modals/DeleteDialog";
import DispatcherInUseDialog from "pages/alert-system/DispatcherInUseDialog";

async function fetchDispatchersAndFilter(analyticType: AnalyticType) {
  return await getDispatchers({
    page: 1,
    pageSize: 10000,
  }).then((res) => {
    if (res) {
      return res.data.filter(
        (_dispatcher) =>
          !_dispatcher.analytic_type || // <- For compatibility when there was no relation between dispatcher and analytic types
          _dispatcher.analytic_type === analyticType
      );
    }
    throw new Error("Error fetching dispatchers");
  });
}

export default function DispatcherSelector({
  initialValue,
  analyticType,
  onSelectDispatcher,
}: {
  initialValue?: DispatcherListData;
  analyticType: AnalyticType;
  onSelectDispatcher: (value: number) => void;
}) {
  const { toast } = useToast();
  const [selectedDispatcher, setSelectedDispatcher] =
    useState<DispatcherListData | null>(null);
  const [isRemovingDispatcher, setIsRemovingDispatcher] = useState(false);
  const [dispatcherInUse, setDispatcherInUse] = useState(false);
  const [analyticsUsingDispatcher, setAnalyticsUsingDispatcher] = useState<
    { cameraName: string; analyticType: string }[]
  >([]);
  const [_dispatcher, setDispatcher] = useState<DispatcherListData | null>(
    initialValue ?? null
  );
  const {
    data: dispatchers,
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: [DISPATCHERS_QUERY_KEY],
    queryFn: () => fetchDispatchersAndFilter(analyticType),
  });
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getPopupIndicatorProps,
    groupedOptions,
  } = useAutocomplete({
    value: _dispatcher,
    id: "alert-system-autocomplete",
    options: dispatchers
      ? dispatchers.sort((a, b) => {
          if (a.name.toLocaleLowerCase().includes("padrão")) return 1;
          else return -1;
        })
      : [],
    onChange: (_, newValue) => {
      if (newValue) {
        setDispatcher(newValue);
        onSelectDispatcher(newValue?.alert_system_id);
      }
    },
    isOptionEqualToValue: (option, value) =>
      isLoading || option.alert_system_id === value.alert_system_id,
    groupBy: (option) => {
      if (option.name.toLocaleLowerCase().includes("padrão")) {
        return "Padrões";
      } else {
        return "Personalizados";
      }
    },
    getOptionLabel: (option) =>
      `${option.name} - Fluxo ${option.max_cycle_duration / 60} minutos`,
  });

  async function handleDeleteDispatcher(dispatcher: DispatcherListData) {
    const dispatcherUsedBy = dispatcher.link_analytic;
    console.log("Dispatcher used by:", dispatcherUsedBy);
    if (dispatcherUsedBy && dispatcherUsedBy.length > 0) {
      setIsRemovingDispatcher(false);
      const newAnalyticsUsingDispatchers: {
        cameraName: string;
        analyticType: string;
      }[] = [];
      dispatcherUsedBy.forEach((link) => {
        newAnalyticsUsingDispatchers.push({
          cameraName: link.analytic.camera.camera_name,
          analyticType: link.analytic.analytic_type,
        });
      });

      setAnalyticsUsingDispatcher(newAnalyticsUsingDispatchers);
      setDispatcherInUse(true);
      return;
    }
    setIsRemovingDispatcher(true);

    await deleteDispatcher(dispatcher.alert_system_id)
      .then(() => {
        toast({
          description: "Sistema de alerta removido com sucesso",
        });
        refetch();
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Erro ao remover sistema de alerta",
          description: getErrorMessage(error),
        });
      });

    setIsRemovingDispatcher(false);
    setSelectedDispatcher(null);
  }

  function UseAutocompleteComponent() {
    return (
      <div className="relative">
        <div {...getRootProps()}>
          <TextField
            fullWidth
            size="small"
            inputProps={{
              ...getInputProps(),
            }}
            label="Sistema de alerta"
            InputLabelProps={{ ...getInputLabelProps() }}
            InputProps={{
              endAdornment: (
                // @ts-expect-error
                <IconButton {...getPopupIndicatorProps()}>
                  <ArrowDropDown />
                </IconButton>
              ),
            }}
          />
        </div>
        {groupedOptions.length > 0 ? (
          <ul
            className="w-full m-0 p-0 z-10 absolute list-none bg-white dark:bg-black overflow-auto max-h-64 border border-b-gray-300"
            {...getListboxProps()}
          >
            {(
              groupedOptions as AutocompleteGroupedOption<DispatcherListData>[]
            ).map((groupedOption) => {
              return (
                <React.Fragment key={groupedOption.key}>
                  <li className="text-sm p-3 font-medium text-gray-700">
                    {groupedOption.group}
                  </li>
                  <ul className="w-full m-0 p-0">
                    {groupedOption.options.map((option, index) => {
                      const { ...optionProps } = getOptionProps({
                        option,
                        index: groupedOption.index + index,
                      });

                      return (
                        <li
                          {...optionProps}
                          className="flex"
                          key={option.alert_system_id}
                        >
                          <span className="flex-1 px-4 py-2 w-full hover:bg-gray-200 cursor-pointer">
                            {option.name}
                          </span>
                          {!option.name
                            .toLocaleLowerCase()
                            .includes("padrão") && (
                            <IconButton
                              color="error"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                setSelectedDispatcher(option);
                              }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </React.Fragment>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }

  return (
    <>
      <section className={styles.configSection}>
        <UseAutocompleteComponent />
        <DeleteDialog
          open={!!selectedDispatcher && !dispatcherInUse}
          isLoading={isRemovingDispatcher}
          onClose={() => setSelectedDispatcher(null)}
          onSubmit={() => handleDeleteDispatcher(selectedDispatcher!)}
          title={`Tem certeza que deseja remover ${selectedDispatcher?.name}?`}
        />
        <DispatcherInUseDialog
          open={dispatcherInUse}
          onClose={() => {
            setDispatcherInUse(false);
            setSelectedDispatcher(null);
          }}
          title={`O despachador ${selectedDispatcher?.name} está em uso`}
          analytics={analyticsUsingDispatcher}
        />
        {/* <Autocomplete
          size="small"
          disablePortal
          loading={isLoading}
          defaultValue={initialValue}
          id="alert-system-autocomplete"
          groupBy={(option) => {
            if (option.name.toLocaleLowerCase().includes("padrão")) {
              return "Padrões";
            } else {
              return "Personalizados";
            }
          }}
          options={
            dispatchers
              ? dispatchers.sort((a, b) => {
                  if (a.name.toLocaleLowerCase().includes("padrão")) return 1;
                  else return -1;
                })
              : []
          }
          isOptionEqualToValue={(option, value) =>
            isLoading || option.alert_system_id === value.alert_system_id
          }
          onChange={(ev, newValue) => {
            if (newValue) onSelectDispatcher(newValue?.alert_system_id);
          }}
          getOptionLabel={(option) =>
            `${option.name} - Fluxo ${option.max_cycle_duration / 60} minutos`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name="alert-system"
              label="Sistema de alerta"
            />
          )}
        /> */}
      </section>
    </>
  );
}
