import React, { useContext, useMemo } from "react";
import { apiConfig } from "api/rootConfig";
import { CombinedLog, LprLog, RadarLog } from "interfaces/analytics";
import { getResizedImagePath } from "lib/resizedImage";
import { LogIcon } from "lib/LogIcon";
import { Video } from "lucide-react";
import { Share, Today } from "@mui/icons-material";
import { cn } from "lib/classNames";
import GlobalContext from "context/GlobalContext";

export function LogCard({
  row,
  breakpoints,
  setOpenExpanded,
  setSelectedOccurrence,
}: LogCardProps) {
  const { user } = useContext(GlobalContext);
  const resizedImagePath = useMemo(() => {
    if (breakpoints?.isSmallest) {
      return apiConfig.baseUrl + getResizedImagePath(row.url, 350);
    } else if (breakpoints?.isXxs) {
      return apiConfig.baseUrl + getResizedImagePath(row.url, 425);
    }
    return apiConfig.baseUrl + getResizedImagePath(row.url, 600);
  }, [row.url, breakpoints]);

  function handleExpandImage() {
    // @ts-expect-error
    setSelectedOccurrence(row);
    setOpenExpanded(true);
  }

  return (
    <div className="flex space-x-2 border-b">
      <div className="relative">
        {row.camera.company_id !== user?.company.id && (
          <div className="absolute left-0 top-0 flex items-center justify-center rounded-tl-lg rounded-br-lg size-6 bg-black opacity-75">
            <Share className="fill-white text-sm" />
          </div>
        )}
        <img
          className={cn(
            "mb-2 rounded-lg object-cover hover:cursor-pointer",
            breakpoints?.isSmallest
              ? "h-16 min-w-28 w-28"
              : "h-20 min-w-36 w-36"
          )}
          alt={row.occurrence}
          src={resizedImagePath}
          onClick={handleExpandImage}
        />
      </div>
      <div
        className={cn(
          "flex flex-col w-full",
          breakpoints?.isSmallest ? "space-y-1" : "space-y-2"
        )}
      >
        <div className="flex items-center space-x-2">
          <LogIcon logType={row.occurrence} fontSize="small" color="inherit" />
          <p className="text-sm font-bold opacity-90">{row.occurrence}</p>
        </div>
        <div className="flex items-center space-x-2">
          <span>
            <Video size={20} />
          </span>
          <p className="text-xs opacity-80">{row.camera.camera_name}</p>
        </div>
        <div className="flex items-center space-x-2">
          <span>
            <Today fontSize="small" />
          </span>
          <p className="text-xs opacity-80">{row.created_at}</p>
        </div>
      </div>
    </div>
  );
}

interface LogCardProps {
  row: CombinedLog;
  breakpoints?: { isXxs?: boolean; isSmallest?: boolean };
  setSelectedOccurrence: ((log: RadarLog) => void) | ((log: LprLog) => void);
  setOpenExpanded: (value: boolean) => void;
}
