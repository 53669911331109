import React, { useMemo } from "react";
import { CombinedLog, LprLog, RadarLog } from "interfaces/analytics";
import { LogCard } from "./LogCard";
import { Skeleton, useMediaQuery } from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

interface LogsCardViewerProps {
  rows: CombinedLog[];
  isLoading?: boolean;
  breakpoints?: {
    isSmallest?: boolean;
    isXxs?: boolean;
  };
  setSelectedOccurrence: ((log: RadarLog) => void) | ((log: LprLog) => void);
  setOpenExpanded: (value: boolean) => void;
}

const mapArray = [1, 2, 3, 4, 5, 6];

function Row({
  data,
  index,
  style,
}: ListChildComponentProps<
  Omit<LogsCardViewerProps, "isLoading" | "viewType">
>) {
  const currentRow = data.rows[index];

  return (
    <div style={{ ...style, marginBottom: "12px" }}>
      <LogCard
        breakpoints={data.breakpoints}
        row={currentRow}
        key={currentRow.id + currentRow.occurrence}
        setOpenExpanded={data.setOpenExpanded}
        setSelectedOccurrence={data.setSelectedOccurrence}
      />
    </div>
  );
}

export function LogsCardViewer({
  rows,
  isLoading,
  setOpenExpanded,
  setSelectedOccurrence,
}: LogsCardViewerProps) {
  const isXxs = useMediaQuery("(max-width: 450px)");
  const isSmallest = useMediaQuery("(max-width: 350px)");
  const listItemContext = useMemo(() => {
    return {
      rows,
      breakpoints: { isXxs, isSmallest },
      setOpenExpanded,
      setSelectedOccurrence,
    };
  }, [rows, isXxs, isSmallest, setOpenExpanded, setSelectedOccurrence]);

  return (
    <div className="flex flex-col space-y-3 h-full">
      {isLoading ? (
        mapArray.map((value) => (
          <Skeleton
            className="min-h-24"
            key={value}
            variant="rounded"
            width="100%"
            height={96}
          />
        ))
      ) : (
        <AutoSizer>
          {({ width, height }: { width: number; height: number }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={100}
              itemData={listItemContext}
              itemCount={rows.length}
              overscanCount={5}
            >
              {(props) => <Row {...props} />}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </div>
  );
}
