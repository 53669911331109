import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  registerDevice,
  changeEmail,
  changePassword,
  getUser,
  removeConnectedDevice,
} from "api/user";
import MainContainer from "components/MainContainer/MainContainer";
import React, { useEffect, useState } from "react";
import { translateRole } from "lib/operators";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import { EditOutlined, Lock, Notifications } from "@mui/icons-material";
import ChangePasswordDialog, {
  NewPassword,
} from "components/Modals/ChangePasswordDialog";
import ChangeEmailDialog, {
  NewEmail,
} from "components/Modals/ChangeEmailDialog";
import { isPlatform } from "@ionic/react";
import { registerNotifications } from "lib/pushNotifications";
import { PushNotifications } from "@capacitor/push-notifications";
import { getMobileDeviceId, getMobileDeviceInfo } from "lib/mobile/device_info";
import { USER_PROFILE_QUERY_KEY } from "constants/apiQueryKeys";
import { ConnectedDevice } from "interfaces/generic";
import { ConnectedDevicesList } from "./ConnectedDevicesList";
import { InfoTile } from "./InfoTile";
import getDeviceInfo, { SimpleDeviceInfo } from "lib/deviceInfo";

export default function Profile() {
  const { toast } = useToast();
  const {
    data: profile,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: [USER_PROFILE_QUERY_KEY],
    queryFn: () => getUser(),
  });
  const [isNotificationPermissionGranted, setIsNotificationPermissionGranted] =
    useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openChangeEmail, setOpenChangeEmail] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState<SimpleDeviceInfo>();
  const [deviceId, setDeviceId] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<ConnectedDevice | null>(
    null
  );

  useEffect(() => {
    async function checkPermissions() {
      const currentDeviceInfo = await getDeviceInfo();
      setDeviceInfo(currentDeviceInfo.deviceInfo);
      setDeviceId(currentDeviceInfo.deviceId);
      if (isPlatform("mobile") && !isPlatform("mobileweb")) {
        setIsNotificationPermissionGranted(
          (await PushNotifications.checkPermissions()).receive === "granted"
        );
      }
    }

    checkPermissions();
  }, []);

  async function handleRemoveDevice() {
    if (!profile || !selectedDevice) {
      return toast({
        variant: "destructive",
        title: "Erro ao remover dispositivo",
        description: "Identificador de usuário ou dispositivo não definidos",
      });
    }

    await removeConnectedDevice(profile.id, selectedDevice?.device_id)
      .then(() => {
        refetch();
        toast({
          description: "Dispositivo removido com sucesso",
        });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao remover dispositivo",
          description: getErrorMessage(err),
        });
      });
    setSelectedDevice(null);
  }

  async function onChangePassword(data: NewPassword) {
    setIsChangingPassword(true);
    await changePassword(profile!.id, data)
      .then(() => {
        toast({
          description: "Senha alterada com sucesso",
        });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao alterar senha",
          description: getErrorMessage(err),
        });
      });
    setIsChangingPassword(false);
    setOpenChangePassword(false);
  }

  async function onChangeEmail(data: NewEmail) {
    setIsChangingEmail(true);
    await changeEmail(profile!.id, data)
      .then(() => {
        toast({
          title: "Solicitação enviada com sucesso",
          description:
            "Uma solicitação de alteração de email foi enviada para o endereço de email fornecido",
        });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao alterar email",
          description: getErrorMessage(err),
        });
      });
    setIsChangingEmail(false);
    setOpenChangeEmail(false);
  }

  async function handleFirebasePushNotifications() {
    await PushNotifications.removeAllListeners();
    const deviceId = await getMobileDeviceId();
    const deviceInfo = await getMobileDeviceInfo();

    await PushNotifications.addListener("registration", async (token) => {
      console.info("Registration token: ", token.value);
      if (
        !!profile &&
        profile.messaging_tokens.findIndex(
          (value) => value.device_token === token.value
        ) === -1
      ) {
        await registerDevice(profile.id, {
          device_id: deviceId.identifier,
          device_info: deviceInfo,
          device_token: token.value,
        }).catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao habilitar notificações",
            description: getErrorMessage(err),
          });
        });
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });
    registerNotifications(true).catch((err) => {
      console.log(err);
    });
  }

  if (error) {
    return (
      <MainContainer className="[&_h2]:text-xl" title="Meu perfil">
        Erro a obter dados
      </MainContainer>
    );
  }

  return (
    <MainContainer className="[&_h2]:text-lg" title="Meu perfil">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
        {isLoading ? (
          [1, 2, 3, 4, 5].map((value) => (
            <Skeleton key={value} width="100%" height={80} variant="rounded" />
          ))
        ) : (
          <>
            <InfoTile label="Nome" value={profile?.name} />
            {profile?.username && (
              <InfoTile label="Nome de usuário" value={profile?.username} />
            )}
            <div className="border rounded-lg p-2">
              <div className="flex flex-wrap items-center justify-between">
                <h2>Email</h2>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<EditOutlined />}
                  onClick={() => setOpenChangeEmail(true)}
                >
                  Alterar
                </Button>
              </div>
              <p className="mr-2">{profile?.email}</p>
            </div>
            <InfoTile label="Cargo" value={translateRole(profile!.role)} />
            <div className="flex items-center justify-between border rounded-lg p-2">
              <h2>Senha</h2>
              <Button
                size="small"
                color="secondary"
                startIcon={<Lock />}
                onClick={() => setOpenChangePassword(true)}
              >
                Alterar
              </Button>
            </div>
          </>
        )}
      </div>
      <Divider className="my-3" />
      <h2 className="mb-3">Dispositivos conectados</h2>
      {isLoading ? (
        <div className="space-y-3">
          <Skeleton width={448} height={56} variant="rounded">
            <div className="w-full max-w-md h-14"></div>
          </Skeleton>
          <Skeleton width={448} height={56} variant="rounded">
            <div className="w-full max-w-md h-14"></div>
          </Skeleton>
        </div>
      ) : (
        <ConnectedDevicesList
          currentDeviceId={deviceId}
          connectedDevices={profile!.messaging_tokens}
          onRemoveDevice={(device) => setSelectedDevice(device)}
        />
      )}
      {isPlatform("mobile") && !isPlatform("mobileweb") && (
        <>
          <Divider className="my-3" />
          <h2 className="mb-3">Notificações</h2>
          <Button
            className="max-w-md"
            variant="outlined"
            onClick={handleFirebasePushNotifications}
            startIcon={<Notifications />}
          >
            Habilitar notificações
          </Button>
          <Alert className="max-w-md mt-3" severity="info">
            Para <strong>desabilitar</strong> as notificações, você deve acessar
            as configurações de notificações de seu dispositivo.
          </Alert>
        </>
      )}
      <ChangeEmailDialog
        open={openChangeEmail}
        isSubmitting={isChangingEmail}
        onClose={() => setOpenChangeEmail(false)}
        onSubmit={onChangeEmail}
      />
      <ChangePasswordDialog
        open={openChangePassword}
        isSubmitting={isChangingPassword}
        onClose={() => setOpenChangePassword(false)}
        onSubmit={onChangePassword}
      />
      <Dialog open={!!selectedDevice} onClose={() => setSelectedDevice(null)}>
        <DialogTitle>
          Tem certeza que deseja remover o dispositivo{" "}
          {selectedDevice?.device_info.name ||
            selectedDevice?.device_info.model}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            O dispositivo a ser removido não receberá mais notificações
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedDevice(null)}>Cancelar</Button>
          <Button color="error" onClick={handleRemoveDevice}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
}
