import { AnalyticType } from "interfaces/analytics";
import axiosRoot from "./rootConfig";

/*----------------------------------------------------------
 * Analytics
 * ------------------------------------------------------------- */
export async function updateAnalytic(
  data: any,
  type: AnalyticType,
  id: string | number
) {
  let requestURL = "";

  if (type === "Aglomeração") requestURL += "/api/agglomeration/";
  else if (type === "Detecção de Animal")
    requestURL += "/api/animal_intrusion/";
  else if (type === "Detecção de Veículo")
    requestURL += "/api/vehicle_intrusion/";
  else if (type === "Evasão") requestURL += "/api/evasion/";
  else if (type === "Intrusão") requestURL += "/api/intrusion/";
  else if (type === "Radar") requestURL += "/api/radar/";
  else if (type === "Congestionamento")
    requestURL += "/api/vehicle_agglomeration/";
  else if (type === "Ausência de Veículo")
    requestURL += "/api/vehicle_evasion/";
  else if (type === "Inatividade") requestURL += "/api/inactivity/";
  else if (type === "Lpr") requestURL += "/api/lpr/";
  else if (type === "Permanência") requestURL += "/api/permanence/";
  else throw new Error("Tipo de analitico nao suportado");

  requestURL += id;

  return await axiosRoot
    .put(requestURL, data, { timeout: 20000 })
    .then((response) => response.data);
}

export async function getAnalytic(id: number | string) {
  return await axiosRoot
    .get("/api/analytic/" + id)
    .then((response) => response.data)
    .catch(function (error) {
      console.error(error);
    });
}

interface PauseAnalyticParams {
  camera_id: number;
  analytic_id: number;
  description: string;
  user_id: number;
  duration_time: number;
}

export async function pauseAnalytic({ ...data }: PauseAnalyticParams) {
  return await axiosRoot.post("/api/aliveness_analytic_log", data);
}

export async function getAvailableAnalytics() {
  return await axiosRoot
    .get<AnalyticType[]>("/api/alert_system/analytic_types")
    .then((res) => res.data);
}

export async function muteAnalyticPushNotifications(data: {
  analyticId: number;
}) {
  return await axiosRoot
    .post("/api/user_muted_analytic", { analytic_id: data.analyticId })
    .then((res) => res.data);
}

export async function unmuteAnalyticPushNotifications(
  mutedAnalyticObjectId: number
) {
  return await axiosRoot
    .delete("/api/user_muted_analytic/" + mutedAnalyticObjectId)
    .then((res) => res.data);
}
