import React, { useEffect, useMemo, useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import { Autocomplete, IconButton, MenuItem, TextField } from "@mui/material";
import {
  DispatcherReceiverInfo,
  DispatcherReceiverTypes,
  NewDispatcherReceiver,
} from "interfaces/alertSystem";
import { PhoneInput } from "components/PhoneInput/PhoneInput";
import { useQuery } from "@tanstack/react-query";
import { getMoniIntegrations } from "api/moni";
import { USERS_FROM_COMPANY_AND_SHARED_CAMERAS } from "constants/apiQueryKeys";
import { useToast } from "components/ui/use-toast";
import { isValidHttpHeaderKey } from "lib/headerValidator";
import { getUsersFromCompanyAndSharedCameras } from "api/user";

const initialInfo: DispatcherReceiverInfo = {
  email: "",
  id: "",
  isVideo: false,
  name: "",
  number: "",
  emailName: "",
  // moni
  // @ts-expect-error
  moniIntegrationId: "",
  clientId: "",
  partitionId: "",
  sectorId: "",
  companyId: "",
};

async function fetchAndTransformUsersFromCompanyAndSharedCameras() {
  const usersFromCompanyAndSharedCameras =
    await getUsersFromCompanyAndSharedCameras();
  const transformedResponse = {
    data: usersFromCompanyAndSharedCameras.data.map((value) => ({
      ...value,
      group: "",
    })),
    meta: usersFromCompanyAndSharedCameras.meta,
    links: usersFromCompanyAndSharedCameras.links,
  };

  usersFromCompanyAndSharedCameras.data_shared.forEach((user) =>
    transformedResponse.data.push({
      ...user,
      group: "Usuários de compartilhamento",
    })
  );

  transformedResponse.data.unshift({
    id: -1,
    // @ts-expect-error
    company: {
      id: -1,
    },
    name: "Todos",
    created_at: "",
    email: "",
    group: "",
    role: "collaborator",
    status: "activated",
    status_shared: false,
    username: "Todos",
  });

  return transformedResponse;
}

export default function DispatcherReceiver({
  onAdd,
  value,
  onChange,
  onRemove,
}: {
  value: NewDispatcherReceiver;
  onAdd?: () => void;
  onRemove?: () => void;
  onChange?: (
    type: DispatcherReceiverTypes,
    info?: DispatcherReceiverInfo
  ) => void;
}) {
  const { toast } = useToast();
  const { data: moniIntegrations } = useQuery({
    queryKey: ["moni-integrations"],
    queryFn: () => getMoniIntegrations(),
  });
  const [info, setInfo] = useState<DispatcherReceiverInfo>(initialInfo);
  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: [USERS_FROM_COMPANY_AND_SHARED_CAMERAS],
    queryFn: () => fetchAndTransformUsersFromCompanyAndSharedCameras(),
  });
  const [headersForm, setHeadersForm] = useState<
    { key: string; value: string }[]
  >([]);
  const [bodyForm, setBodyForm] = useState<{ key: string; value: string }[]>(
    []
  );
  const [newHeaderInfo, setNewHeaderInfo] = useState({
    key: "",
    value: "",
  });
  const [newBodyInfo, setNewBodyInfo] = useState({
    key: "",
    value: "",
  });
  const sortedUsers = useMemo(() => {
    return (
      users?.data.sort((a, b) => {
        if (a.group === "") return -1;
        return 1;
      }) || []
    );
  }, [users]);

  // Update fields after calling onChange
  useEffect(() => {
    if (value) {
      const newInfo = { ...initialInfo };
      if (value.type === "telegram") {
        newInfo.id = value.info.id;
        newInfo.isVideo = value.info.isVideo;
      } else if (value.type === "whatsapp") {
        newInfo.isVideo = value.info.isVideo;
        newInfo.number = value.info.number;
      } else if (value.type === "email") {
        newInfo.email = value.info.address;
        newInfo.emailName = value.info.name;
      } else if (value.type === "user") {
        newInfo.name = value.info.name;
        newInfo.id = value.info.id;
      } else if (value.type === "whatsappGroup") {
        newInfo.groupId = value.info.groupId;
        newInfo.isVideo = value.info.isVideo;
      } else if (value.type === "moni") {
        newInfo.clientId = value.info.clientId;
        newInfo.companyId = value.info.companyId;
        newInfo.partitionId = value.info.partitionId;
      } else if (value.type === "webhook") {
        newInfo.ip = value.info.ip;
        newInfo.port = value.info.port;
        newInfo.protocol = value.info.protocol;
        if (!!value.info.headers) {
          newInfo.headers = value.info.headers;
          const newHeadersForm: { key: string; value: string }[] = [];
          Object.keys(value.info.headers).forEach((key) => {
            newHeadersForm.push({ key, value: value.info.headers![key] });
          });
          setHeadersForm(newHeadersForm);
        }
        if (!!value.info.body) {
          newInfo.body = value.info.body;
          const newBodyForm: { key: string; value: string }[] = [];
          Object.keys(value.info.body).forEach((key) => {
            newBodyForm.push({ key, value: value.info.body![key] });
          });
          setBodyForm(newBodyForm);
        }
        if (!!value.info.endpoint) {
          newInfo.endpoint = value.info.endpoint;
        }
      }
      setInfo(newInfo);
    }
  }, [value]);

  function handleType(
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (onChange) onChange(ev.target.value as DispatcherReceiverTypes);
  }

  function handleInfo(
    infoValue: string,
    infoType:
      | "id"
      | "number"
      | "groupId"
      | "companyId"
      | "clientId"
      | "partitionId"
      | "moniIntegrationId"
  ) {
    const newInfo: DispatcherReceiverInfo = {
      ...info,
    };
    //@ts-ignore
    newInfo[infoType] = infoValue;
    if (onChange) onChange(value!.type as DispatcherReceiverTypes, newInfo);
  }

  function handleUser(newId: number, newName: string) {
    const newInfo: DispatcherReceiverInfo = {
      ...info,
      id: newId,
      name: newName,
    };
    onChange?.(value.type, newInfo);
  }

  function handleEmail(newEmail: string) {
    const newInfo: DispatcherReceiverInfo = {
      ...info,
      email: newEmail,
    };
    onChange?.(value.type, newInfo);
  }

  function handleEmailName(recipientName: string) {
    const newInfo: DispatcherReceiverInfo = {
      ...info,
      emailName: recipientName,
    };
    onChange?.(value.type, newInfo);
  }

  function handleWebhook({
    ip,
    port,
    protocol,
    endpoint,
  }: {
    ip?: string;
    port?: string | number;
    protocol?: "http" | "https";
    endpoint?: string;
  }) {
    const newInfo: DispatcherReceiverInfo = {
      ...info,
      ip: ip !== undefined ? ip : info.ip,
      port: port !== undefined ? port : info.port,
      endpoint: endpoint !== undefined ? endpoint : info.endpoint,
    };
    if (protocol) {
      newInfo.protocol = protocol;
    }

    onChange?.(value.type, newInfo);
  }

  function addWebhookHeaderEntry({
    key,
    _value,
  }: {
    key: string;
    _value: string;
  }) {
    setNewHeaderInfo({ key: "", value: "" });

    const newHeaders: Record<string, string> = { ...info.headers };

    if (!isValidHttpHeaderKey(key)) {
      return toast({
        variant: "destructive",
        description: "Formato de chave inválida",
      });
    }

    if (!newHeaders[key]) newHeaders[key] = _value;
    else
      toast({
        variant: "destructive",
        description: "Não é possível adicionar chaves duplicadas",
      });

    const newInfo: DispatcherReceiverInfo = {
      ...info,
      headers: newHeaders,
    };

    onChange?.(value.type, newInfo);
  }

  function addWebhookBodyEntry({
    key,
    _value,
  }: {
    key: string;
    _value: string;
  }) {
    setNewBodyInfo({ key: "", value: "" });

    const newBody: Record<string, string> = { ...info.body };

    if (!isValidHttpHeaderKey(key)) {
      return toast({
        variant: "destructive",
        description: "Formato de chave inválida",
      });
    }

    if (!newBody[key]) newBody[key] = _value;
    else
      toast({
        variant: "destructive",
        description: "Não é possível adicionar chaves duplicadas",
      });

    const newInfo: DispatcherReceiverInfo = {
      ...info,
      body: newBody,
    };

    onChange?.(value.type, newInfo);
  }

  function removeWebhookHeaderEntry(key: string) {
    const newHeaders: Record<string, string> = { ...info.headers };
    delete newHeaders[key];

    const newInfo: DispatcherReceiverInfo = {
      ...info,
      headers: newHeaders,
    };

    onChange?.(value.type, newInfo);
  }

  function removeWebhookBodyEntry(key: string) {
    const newBody: Record<string, string> = { ...info.body };
    delete newBody[key];

    const newInfo: DispatcherReceiverInfo = {
      ...info,
      body: newBody,
    };

    onChange?.(value.type, newInfo);
  }

  // function handleIsVideo(newValue: boolean) {
  //   const newInfo: DispatcherReceiverInfo = {
  //     ...info,
  //     isVideo: newValue,
  //   };
  //   onChange?.(value.type, newInfo);
  // }

  if (!value) {
    return (
      <div style={{ display: "flex" }}>
        <TextField
          select
          fullWidth
          name="type"
          size="small"
          onChange={(ev) => handleType(ev)}
          style={{
            marginBottom: "10px",
            flex: 1,
          }}
          label="Tipo de desnatário"
        >
          <MenuItem value="telegram">Telegram</MenuItem>
          <MenuItem value="whatsapp">WhatsApp</MenuItem>
          <MenuItem value="whatsappGroup">WhatsApp (Grupo)</MenuItem>
          <MenuItem value="gear">Gear</MenuItem>
          <MenuItem value="moni">MONI</MenuItem>
          <MenuItem value="user">Usuário</MenuItem>
          <MenuItem value="webhook">Servidor de eventos</MenuItem>
          {/* <MenuItem value="email">Email</MenuItem> */}
        </TextField>
        <div
          style={{
            display: "flex",
            marginLeft: "10px",
            minWidth: "90px",
            marginBottom: "10px",
          }}
        >
          {onRemove ? (
            <IconButton onClick={onRemove}>
              <Remove color="error" />
            </IconButton>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", marginBottom: "15px" }}>
      <TextField
        select
        name="type"
        size="small"
        value={value.type}
        onChange={(ev) => handleType(ev)}
        sx={{ marginRight: "10px", minWidth: "200px" }}
        label="Tipo de destinatário"
      >
        <MenuItem value="telegram">Telegram</MenuItem>
        <MenuItem value="whatsapp">WhatsApp</MenuItem>
        <MenuItem value="whatsappGroup">WhatsApp (Grupo)</MenuItem>
        {/* <MenuItem value="email">Email</MenuItem> */}
        <MenuItem value="gear">Gear</MenuItem>
        <MenuItem value="moni">MONI</MenuItem>
        <MenuItem value="user">Usuário</MenuItem>
        <MenuItem value="webhook">Servidor de eventos</MenuItem>
      </TextField>
      {value.type === "telegram" ? (
        <>
          <TextField
            fullWidth
            size="small"
            label="ID do usuário ou grupo"
            value={value.info ? value.info.id : ""}
            onChange={(ev) => handleInfo(ev.target.value, "id")}
          />
          {/* <FormControlLabel
            style={{ marginLeft: "10px" }}
            control={
              <Switch
                name="isVideo"
                checked={value.info.isVideo}
                onChange={(ev) => handleIsVideo(ev.target.checked)}
              />
            }
            label="Video"
          /> */}
        </>
      ) : value.type === "whatsapp" ? (
        <>
          <PhoneInput
            fullWidth
            // @ts-expect-error
            size="small"
            label="Número"
            mask="+55 (99) 99999-9999"
            value={value.info ? value.info.number : ""}
            onChange={(ev) => handleInfo(ev.target.value, "number")}
          />
          {/* <FormControlLabel
            style={{ marginLeft: "10px" }}
            control={
              <Switch
                name="isVideo"
                checked={value.info.isVideo}
                onChange={(ev) => handleIsVideo(ev.target.checked)}
              />
            }
            label="Video"
          /> */}
        </>
      ) : value.type === "whatsappGroup" ? (
        <>
          <TextField
            fullWidth
            size="small"
            label="Id do grupo"
            value={value.info ? value.info.groupId : ""}
            onChange={(ev) => handleInfo(ev.target.value, "groupId")}
          />
          {/* <FormControlLabel
            style={{ marginLeft: "10px" }}
            control={
              <Switch
                name="isVideo"
                checked={value.info.isVideo}
                onChange={(ev) => handleIsVideo(ev.target.checked)}
              />
            }
            label="Video"
          /> */}
        </>
      ) : value.type === "email" ? (
        <>
          <TextField
            fullWidth
            name="email"
            size="small"
            label="Email"
            onChange={(ev) => handleEmail(ev.target.value)}
            style={{ marginRight: "10px" }}
            value={value.info.address}
          />
          <TextField
            fullWidth
            size="small"
            name="emailName"
            label="Nome do destinatário"
            value={value.info.name}
            onChange={(ev) => handleEmailName(ev.target.value)}
          />
        </>
      ) : value.type === "user" ? (
        <Autocomplete
          fullWidth
          id="users-from-company-and-shared-cameras-autocomplete"
          options={sortedUsers}
          size="small"
          loading={isLoadingUsers}
          value={
            value.info
              ? ({ id: value.info.id, name: value.info.name } as any)
              : ({ id: -1, name: "Todos" } as any)
          }
          onChange={(_, _value) => {
            if (!!_value) {
              handleUser(_value.id, _value.name);
            }
          }}
          isOptionEqualToValue={(option, _value) => option.id === _value.id}
          groupBy={(option) => option.group}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} name="id" label="Usuário" />
          )}
        />
      ) : value.type === "webhook" ? (
        <div className="w-full grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          <TextField
            select
            fullWidth
            size="small"
            label="Protocolo"
            value={value.info.protocol}
            onChange={(ev) =>
              handleWebhook({ protocol: ev.target.value as "http" | "https" })
            }
          >
            <MenuItem value="http">HTTP</MenuItem>
            <MenuItem value="https">HTTPS</MenuItem>
          </TextField>
          <TextField
            fullWidth
            size="small"
            label="IP / DNS"
            value={value.info.ip}
            onChange={(ev) => handleWebhook({ ip: ev.target.value })}
          />
          <TextField
            fullWidth
            size="small"
            label="Porta"
            value={value.info.port}
            onChange={(ev) => handleWebhook({ port: ev.target.value })}
          />
          <TextField
            fullWidth
            size="small"
            className="col-span-2"
            label="Endpoint (opcional)"
            value={value.info.endpoint}
            placeholder="/api/heimdall/webservice/analitico"
            onChange={(ev) => handleWebhook({ endpoint: ev.target.value })}
          />
          <div className="grid gap-3 md:grid-cols-3 md:col-span-3 sm:grid-cols-2 sm:col-span-2">
            <h3 className="sm:col-span-2 md:col-span-3 flex items-center">
              Headers (Opcional)
            </h3>
            <KeyValueComponent
              createdKeyValues={headersForm}
              newValueState={newHeaderInfo}
              onAddKeyValue={({ key, value }) =>
                addWebhookHeaderEntry({ key, _value: value })
              }
              onRemoveKeyValue={removeWebhookHeaderEntry}
              onChangeNewValueState={(newKeyValue) =>
                setNewHeaderInfo(newKeyValue)
              }
            />
          </div>
          <div className="grid gap-3 md:grid-cols-3 md:col-span-3 sm:grid-cols-2 sm:col-span-2">
            <h3 className="sm:col-span-2 md:col-span-3 flex items-center">
              Body (Opcional)
            </h3>
            <KeyValueComponent
              createdKeyValues={bodyForm}
              newValueState={newBodyInfo}
              onAddKeyValue={({ key, value }) =>
                addWebhookBodyEntry({ key, _value: value })
              }
              onRemoveKeyValue={removeWebhookBodyEntry}
              onChangeNewValueState={(newKeyValue) =>
                setNewBodyInfo(newKeyValue)
              }
            />
          </div>
        </div>
      ) : value.type === "moni" ? (
        <div className="flex flex-1 space-x-1 sm:space-x-2">
          <TextField
            select
            fullWidth
            size="small"
            label="Integração moni"
            name="moniIntegrationId"
            value={value.info ? value.info.moniIntegrationId : ""}
            onChange={(ev) => handleInfo(ev.target.value, "moniIntegrationId")}
          >
            {moniIntegrations?.data.map((_moniIntegration) => (
              <MenuItem key={_moniIntegration.id} value={_moniIntegration.id}>
                {_moniIntegration.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            size="small"
            label="ID da empresa"
            value={value.info ? value.info.companyId : ""}
            onChange={(ev) => handleInfo(ev.target.value, "companyId")}
          />
          <TextField
            fullWidth
            size="small"
            name="partitionId"
            label="ID da partição"
            value={value.info ? value.info.partitionId : ""}
            onChange={(ev) => handleInfo(ev.target.value, "partitionId")}
          />
          <TextField
            size="small"
            fullWidth
            name="clientId"
            label="ID do cliente"
            value={value.info ? value.info.clientId : ""}
            onChange={(ev) => handleInfo(ev.target.value, "clientId")}
          />
        </div>
      ) : null}
      <div
        style={{
          display: "inherit",
          marginLeft: "10px",
          marginBottom: value.type === "whatsapp" ? "5px" : "0px",
          minWidth: "90px",
        }}
      >
        {onAdd && (
          <IconButton className="size-10" onClick={onAdd}>
            <Add />
          </IconButton>
        )}
        {onRemove && (
          <IconButton className="size-10" onClick={onRemove}>
            <Remove color="error" />
          </IconButton>
        )}
      </div>
    </div>
  );
}

function KeyValueComponent({
  createdKeyValues,
  newValueState,
  onChangeNewValueState,
  onAddKeyValue,
  onRemoveKeyValue,
}: {
  createdKeyValues: { key: string; value: string }[];
  newValueState: { key: string; value: string };
  onChangeNewValueState: ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => void;
  onAddKeyValue: ({ key, value }: { key: string; value: string }) => void;
  onRemoveKeyValue: (key: string) => void;
}) {
  return (
    <>
      {createdKeyValues.map(({ key, value }) => (
        <>
          <TextField
            disabled
            fullWidth
            size="small"
            label="Chave"
            value={key}
          />
          <TextField
            disabled
            fullWidth
            size="small"
            label="Valor"
            value={value}
          />
          <IconButton
            className="size-10"
            color="error"
            onClick={() => onRemoveKeyValue(key)}
          >
            <Remove />
          </IconButton>
        </>
      ))}
      <TextField
        fullWidth
        size="small"
        label="Chave"
        value={newValueState.key}
        onChange={(ev) =>
          onChangeNewValueState({
            key: ev.target.value,
            value: newValueState.value,
          })
        }
      />
      <TextField
        fullWidth
        size="small"
        label="Valor"
        value={newValueState.value}
        onChange={(ev) =>
          onChangeNewValueState({
            key: newValueState.key,
            value: ev.target.value,
          })
        }
      />
      <IconButton
        className="size-10"
        onClick={() =>
          onAddKeyValue({ key: newValueState.key, value: newValueState.value })
        }
      >
        <Add />
      </IconButton>
    </>
  );
}
