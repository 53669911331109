export const HEIMDALL_ROUTE = {
  // Auth pages
  AUTH: "/auth",
  LOST_PASSWORD: "lost-password",
  RESET_PASSWORD: "reset",
  CONFIRM_EMAIL: "confirm-email",
  CONFIRM_UPDATE_EMAIL: "confirm-update-email",
  REMOVE_DEVICES: "remove-devices",
  // Main pages
  HOME: "/",
  MACHINES: "/machines",
  COMPANIES: "companies",
  CREATE_COMPANY: "create-company/:id?",
  COMPANY_PLANS: ":companyId/plans",
  COMPANY_PLAN: ":companyId/plans/:planId",
  CREATE_COMPANY_PLAN: ":companyId/plans/create-plan",
  OPERATORS: "operators",
  CREATE_OPERATOR: "create-operator",
  EDIT_OPERATOR: "edit-operator",
  CAMERAS: "cameras",
  CREATE_CAMERA: "add-camera",
  CAMERA_DETAILS: ":id",
  SHARED_CAMERA_DETAILS: "shared/:id",
  CONFIGURE_ANALYTICS: ":cameraId/configure-analytics",
  CONFIGURE_ANALYTIC: ":analyticType",
  CAMERAS_MAP: "map",
  CAMERA_GROUPS: "camera-groups",
  SPECIFIC_LOG: "log/:logType",
  RADAR_LOG: "log-radar",
  FORENSE: "forense",
  FORENSE_MAP: "map",
  LPR: "lpr",
  LPR_BLACKLIST: "blacklist",
  IO_DEVICES: "io-devices",
  CREATE_IO_DEVICE: "create-device",
  PANIC_DEVICES: "panic",
  CREATE_PANIC_DEVICE: "create-panic",
  PROFILE: "profile",
  HELP: "help",
  FEEDBACK: "feedback",
  DISPATCHERS: "dispatchers",
  CREATE_DISPATCHER: "create-dispatcher/:id?",
  VMS: "vms",
  CREATE_VMS: "create-vms/:id?",
  MONI: "moni",
  CREATE_MONI: "create-moni/:id?",
  THEMES: "themes",
  EDIT_THEME: "edit-theme/:id",
  // Monitoring pages
  MONITORING: "monitor",
  DEFAULT_MONITOR: "default/:playAudio?/:cameras?/:panicCameras?",
  EVENTS_MONITOR: "events/:playAudio?/:cameras?/:panicCameras?",
  // Cameras mosaic page
  MOSAIC: "/cameras-mosaic",
} as const;

export type HeimdallRoute = keyof typeof HEIMDALL_ROUTE;
