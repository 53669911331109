import {
  RegionDescription,
  RegionMonitor,
  isRegionDescription,
} from "interfaces/analytics";
import React from "react";

export type CanvasInfo = {
  ref: React.RefObject<HTMLCanvasElement>;
  context: CanvasRenderingContext2D | null;
  dimensions: { width: number; height: number };
  drawFlag: boolean;
  prevX: number;
  currX: number;
  prevY: number;
  currY: number;
  lineWidth: number;
  lineColor: string;
  dotColor: string;
  fillStyle: string;
};

export type Vector2d = { x: number; y: number };

function getFillColors() {
  return [
    "rgba(255, 0, 0, 0.25)", // (Red)
    "rgba(0, 255, 0, 0.25)", // (Green)
    "rgba(0, 0, 255, 0.25)", //(Blue)
    "rgba(255, 255, 0, 0.25)", // (Yellow)
    "rgba(0, 255, 255, 0.25)", //(Cyan)
    "rgba(255, 0, 255, 0.25)", //(Magenta)
    "rgba(128, 0, 128, 0.25)", //(Purple)
    "rgba(255, 165, 0, 0.25)", //(Orange)
  ];
}

export function getInitialWidth(innerWidth: number) {
  if (innerWidth < 601) {
    return innerWidth - 25;
  } else if (innerWidth < 1100) {
    return innerWidth - 125;
  } else if (innerWidth < 1475) {
    return 450;
  } else if (innerWidth < 1675) {
    return 600;
  } else {
    return 800;
  }
}

export function getInitialHeight(innerWidth: number) {
  if (innerWidth < 601) {
    return (innerWidth - 25) * 0.6;
  } else if (innerWidth < 1100) {
    return (innerWidth - 125) * 0.6;
  } else if (innerWidth < 1475) {
    return 280;
  } else if (innerWidth < 1675) {
    return 380;
  } else {
    return 420;
  }
}

export function isClose(pointA: [number, number], pointB: [number, number]) {
  const distance = Math.sqrt(
    Math.pow(pointA[0] - pointB[0], 2) + Math.pow(pointA[1] - pointB[1], 2)
  );
  if (distance < 10) {
    return true;
  }
  return false;
}

export function draw(canvas: CanvasInfo) {
  if (!canvas.context)
    throw new Error("The canvas context was not initialized");

  canvas.context.beginPath();
  canvas.context.moveTo(canvas.prevX, canvas.prevY);
  canvas.context.lineTo(canvas.currX, canvas.currY);
  canvas.context.stroke();
  canvas.context.closePath();
}

export function getEmptyCanvas(initialDimensions: {
  width: number;
  height: number;
}) {
  const newCanvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
  const newCanvasInfo: CanvasInfo = {
    context: null,
    drawFlag: false,
    ref: newCanvasRef,
    prevX: 0,
    currX: 0,
    prevY: 0,
    currY: 0,
    lineWidth: 2,
    dimensions: initialDimensions,
    lineColor: "rgba(0, 0, 0, 0.8)",
    dotColor: "rgba(255, 0, 0, 0.5)",
    fillStyle: "rgba(255, 0, 0, 0.25)",
  };
  return newCanvasInfo;
}

export function getEmptyCanvases(
  numberOfCanvases: number,
  initialDimensions: { width: number; height: number }
) {
  const emptyCanvases: CanvasInfo[] = [];
  for (let index = 0; index < numberOfCanvases; index++) {
    emptyCanvases.push(getEmptyCanvas(initialDimensions));
  }
  return emptyCanvases;
}

export function drawPolygon(polygon: number[][], canvas: CanvasInfo) {
  if (!canvas.context)
    throw new Error("The canvas context was not initialized");
  if (polygon.length === 0 || polygon[0].length === 0) return false;
  const ctx = canvas.context;
  const firstVertex = polygon[0];
  // Clearing previous polygons
  ctx.clearRect(0, 0, 1000, 1000);
  ctx.setLineDash([0]);
  ctx.fillStyle = canvas.fillStyle;
  // Drawing polygon
  ctx.beginPath();
  ctx.moveTo(firstVertex[0], firstVertex[1]);
  for (let index = 0; index < polygon.length; index++) {
    const vertex = polygon[index];
    ctx.lineTo(vertex[0], vertex[1]);
  }
  ctx.closePath();
  ctx.fill();
  return true;
}

export function drawAllRegions(
  regions: RegionDescription | RegionMonitor,
  ctx: CanvasRenderingContext2D,
  canvasDimensions: { width: number; height: number }
) {
  const fillStyles = getFillColors();
  ctx.clearRect(0, 0, 1000, 1000);
  ctx.setLineDash([0]);
  ctx.strokeStyle = "rgba(0,0,0,0)";

  if (isRegionDescription(regions)) {
    Object.keys(regions).forEach((regionKey, idx) => {
      const region = regions[regionKey];
      if (region.length === 0) return;
      const firstVertex = region[0];
      ctx.fillStyle = fillStyles[idx];
      // Drawing polygon
      ctx.beginPath();
      ctx.moveTo(
        firstVertex[0] * canvasDimensions.width,
        firstVertex[1] * canvasDimensions.height
      );
      for (let index = 0; index < region.length; index++) {
        const vertex = region[index];
        ctx.lineTo(
          vertex[0] * canvasDimensions.width,
          vertex[1] * canvasDimensions.height
        );
      }
      ctx.closePath();
      ctx.fill();
    });
  } else {
    regions.forEach((region, idx) => {
      if (region.length === 0) return;
      const firstVertex = region[0];
      ctx.fillStyle = fillStyles[idx];
      // Drawing polygon
      ctx.beginPath();
      ctx.moveTo(
        firstVertex[0] * canvasDimensions.width,
        firstVertex[1] * canvasDimensions.height
      );
      for (let index = 0; index < region.length; index++) {
        const vertex = region[index];
        ctx.lineTo(
          vertex[0] * canvasDimensions.width,
          vertex[1] * canvasDimensions.height
        );
      }
      ctx.closePath();
      ctx.fill();
    });
  }
}

interface DrawNormalizedPolygonParams {
  polygon: number[][];
  canvas: CanvasInfo;
  onFinishDraw?: () => void;
}
export function drawNormalizedPolygon({
  canvas,
  polygon,
  onFinishDraw,
}: DrawNormalizedPolygonParams) {
  if (!canvas.context)
    throw new Error("The canvas context was not initialized");
  if (!polygon || polygon.length === 0 || polygon[0].length === 0) return false;

  const ctx = canvas.context;
  const firstVertex = polygon[0];
  // Clearing previous polygons
  ctx.clearRect(0, 0, 1000, 1000);
  ctx.setLineDash([0]);
  ctx.fillStyle = canvas.fillStyle;
  // Drawing polygon
  ctx.beginPath();
  ctx.moveTo(
    firstVertex[0] * canvas.dimensions.width,
    firstVertex[1] * canvas.dimensions.height
  );
  for (let index = 0; index < polygon.length; index++) {
    const vertex = polygon[index];
    ctx.lineTo(
      vertex[0] * canvas.dimensions.width,
      vertex[1] * canvas.dimensions.height
    );
  }
  ctx.closePath();
  ctx.fill();
  if (onFinishDraw) onFinishDraw();
  return true;
}

export function closePolygonAndDraw(
  currentCanvas: CanvasInfo,
  polygon: [number, number][]
) {
  const prevX = currentCanvas.prevX;
  const prevY = currentCanvas.prevY;

  if (!currentCanvas.context) return;
  // Drawing last line
  const firstVertex = polygon[0];
  currentCanvas.context.beginPath();
  currentCanvas.context.moveTo(prevX, prevY);
  currentCanvas.context.lineTo(firstVertex[0], firstVertex[1]);
  currentCanvas.context.stroke();
  currentCanvas.context.closePath();
  // Drawing polygon
  drawPolygon(polygon, currentCanvas);
}

// Ray-Casting algorithm to check if the point is inside the polygon
export function isPointInPolygon(point: Vector2d, vertices: Vector2d[]) {
  let isInside = false;
  const { x, y } = point;

  // Iterate over edges of the polygon
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i].x,
      yi = vertices[i].y;
    const xj = vertices[j].x,
      yj = vertices[j].y;

    const intersect =
      // prettier-ignore
      (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) isInside = !isInside;
  }

  return isInside;
}

/**
 * Tells you if you can safely move the region within the canvas boundaries
 * @param points the polygon vertices
 * @param displacement the {x, y} amount you want to move the entire region
 * @param dimensions the current canvas dimensions
 * @returns true if you can move the region within the canvas boundaries, false otherwise
 */
export function canMoveRegion(
  points: Vector2d[],
  displacement: Vector2d,
  dimensions: { width: number; height: number }
) {
  return points.every((vertex) => {
    const newX = vertex.x + displacement.x;
    const newY = vertex.y + displacement.y;
    return (
      newX >= 0 &&
      newX <= dimensions.width &&
      newY >= 0 &&
      newY <= dimensions.height
    );
  });
}
