import React, { useEffect, useState } from "react";

import {
  getDevice,
  getLinkedDevicesByAnalytic,
  deleteLinkedDevice,
} from "api/ioDevices";
import { GridColDef } from "@mui/x-data-grid";
import { Button, IconButton } from "@mui/material";
// Icons
import AddIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  HeimdallDevice,
  HeimdallDeviceTypes,
  HeimdallDeviceTypesPretty,
} from "interfaces/devices";
import AssociateDeviceDialog from "./AssociateDeviceDialog";
import IoDevicesListDialog from "./IoDevicesListDialog";
import { PanicLinkedAnalytic } from "interfaces/panicDevice";
import BorderedDataGrid from "components/BorderedDataGrid/BorderedDataGrid";
import DeleteDialog from "components/Modals/DeleteDialog";
import { useToast } from "components/ui/use-toast";

interface AssociateDevicesProps {
  analyticID?: number | string;
  panicLinkedDevices?: any[];
}

export default function AssociateDevices({
  analyticID,
  panicLinkedDevices,
}: AssociateDevicesProps) {
  const { toast } = useToast();
  // Data grid state
  const [devices, setDevices] = useState<
    HeimdallDevice[] | PanicLinkedAnalytic[]
  >([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [isLoading, setIsLoading] = useState(true);
  // Modal state
  // const [success, setSuccess] = useState(false);
  // const [id, setID] = useState<string | number>();
  const [open, setOpen] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deviceID, setDeviceID] = useState<string | number>();
  const [device, setDevice] = useState<HeimdallDevice>();
  const [linkedDevice, setLinkedDevice] = useState<PanicLinkedAnalytic>();
  const [deviceType, setDeviceType] = useState<HeimdallDeviceTypes>();

  // Fetching initial page data
  useEffect(() => {
    async function getData() {
      if (analyticID) {
        const newDevices = await getLinkedDevicesByAnalytic(analyticID);

        if (newDevices) setDevices(newDevices);

        setIsLoading(false);
      }
    }

    getData();
  }, [analyticID]);

  function getDeviceRowID(row: any) {
    if (row.commbox_id) {
      return row.commbox_id + "," + row.device;
    } else {
      return row.horn_speaker_id + "," + row.device;
    }
  }

  async function handleAssociate(
    id: number | string,
    type: HeimdallDeviceTypesPretty
  ) {
    let device;
    if (type === "Controladora") {
      device = await getDevice("commbox", id);
      setDeviceType("commbox");
    } else if (type === "Alto Falante") {
      device = await getDevice("horn_speaker", id);
      setDeviceType("horn_speaker");
    }

    if (device) setDevice(device);
    setOpen(true);
  }

  async function handleSubmit() {
    setOpen(false);
    setOpenTable(false);
    setIsLoading(true);
    if (analyticID) {
      // Updating table
      const newDevices = await getLinkedDevicesByAnalytic(analyticID);
      if (newDevices && newDevices.length > 0) setDevices(newDevices);
    }

    setIsLoading(false);
  }

  async function handleDelete() {
    setIsLoading(true);

    if (analyticID && deviceType) {
      const response = await deleteLinkedDevice(Number(deviceID), deviceType);

      if (response) {
        const newDevices = await getLinkedDevicesByAnalytic(analyticID);
        if (newDevices) setDevices(newDevices);
        toast({
          description: "Dispositivo desassociado com sucesso",
        });
      }
    }

    setIsLoading(false);
    setOpenDelete(false);
  }

  const columns: GridColDef<PanicLinkedAnalytic>[] = [
    {
      field: "name",
      headerName: "Nome",
      align: "left",
      headerAlign: "left",
      flex: 0.5,
    },
    {
      field: "device",
      headerName: "Tipo",
      align: "left",
      headerAlign: "left",
      flex: 0.5,
      valueFormatter: (value) => {
        if (value === "horn_speaker") return "Corneta";
        else return "Commbox";
      },
    },
    {
      field: "ip",
      headerName: "IP",
      align: "left",
      headerAlign: "left",
      flex: 0.5,
    },
    {
      field: "config",
      headerName: "Configuração",
      align: "left",
      headerAlign: "left",
      flex: 1,
      filterable: false,
      sortable: false,
      valueGetter: (value, row) => {
        const config = row.config;
        if (!config) return "-";
        if (config.audio_id) {
          const value =
            "Áudio: " +
            config.audio_id +
            ". Número repeticoes: " +
            config.nbr_times;

          return value;
        } else {
          // @ts-expect-error
          const value = "Relé: " + config.address + ". Duração: " + config.time;
          return value;
        }
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      align: "center",
      headerAlign: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(ev) => {
              ev.stopPropagation();

              setLinkedDevice(params.row);

              if (params.row.device === "horn_speaker") {
                setDeviceID(params.row.horn_speaker_analytic_id);
                setDeviceType("horn_speaker");
              } else {
                setDeviceID(params.row.commbox_analytic_id);
                setDeviceType("commbox");
              }

              setOpenDelete(true);
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        );
      },
    },
  ];
  return (
    <>
      <div className="mt-4 shadow-lg p-5 rounded-lg h-[550px]">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h3>Dispositivos associados</h3>
          <Button
            variant="text"
            startIcon={<AddIcon color="primary" />}
            onClick={() => setOpenTable(true)}
          >
            Associar dispositivo
          </Button>
        </div>
        <div className="h-[450px]">
          <BorderedDataGrid
            rows={devices}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => getDeviceRowID(row)}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => {
              setIsLoading(true);
              setPaginationModel({
                page: model.page,
                pageSize: model.pageSize,
              });
              setIsLoading(false);
            }}
            pageSizeOptions={[5]}
          />
        </div>
      </div>
      <IoDevicesListDialog
        open={openTable}
        setOpen={setOpenTable}
        handleAssociate={handleAssociate}
      />
      <AssociateDeviceDialog
        device={device}
        open={open}
        setOpen={setOpen}
        deviceType={deviceType}
        analyticID={Number(analyticID)}
        handleSubmit={handleSubmit}
      />
      <DeleteDialog
        title={`Deseja mesmo desassociar o dispositivo ${linkedDevice?.name}?`}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onSubmit={handleDelete}
      />
    </>
  );
}
