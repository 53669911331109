import { redirect } from "react-router-dom";
import { getUser } from "api/user";
import { Preferences } from "@capacitor/preferences";
import axiosRoot from "api/rootConfig";

const protectedRouteLoader = async () => {
  const token = await Preferences.get({ key: "token" });

  console.log("Running protected route loader");
  if (token.value) {
    axiosRoot.defaults.headers.common.Authorization = `Bearer ${token.value}`;
    return await getUser()
      .then((user) => {
        return user;
      })
      .catch(() => {
        return redirect("/auth");
      });
  }

  return redirect("/auth");
};

export default protectedRouteLoader;
