import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { apiConfig } from "api/rootConfig";
import CustomHlsPlayer from "components/CustomHlsPlayer/CustomHlsPlayer";
import { GridLayout } from "pages/cameras-mosaic/CamerasMosaic";
import React, { useMemo } from "react";

export default function CamerasMosaicPaginatedView({
  data,
  page,
  size,
  gridLayout,
  onRemoveCameraFromView,
}: {
  page: number;
  gridLayout: GridLayout;
  data: { id: number; name: string }[];
  size: { width: number; height: number };
  onRemoveCameraFromView?: (value: { id: number; name: string }) => void;
}) {
  const viewItems = useMemo(() => {
    const viewItems = [];
    const firstIdx =
      gridLayout.rows * gridLayout.columns * page -
      gridLayout.rows * gridLayout.columns;

    for (
      let index = firstIdx;
      index < firstIdx + gridLayout.rows * gridLayout.columns &&
      index < data.length;
      index++
    ) {
      const element = data[index];
      viewItems.push(element);
    }
    return viewItems;
  }, [data, gridLayout, page]);
  const iframeSize = useMemo(() => {
    const width = size.width / gridLayout.columns;
    const height = size.height / gridLayout.rows;

    return { width, height };
  }, [size, gridLayout]);

  return (
    <>
      {viewItems.map((camera) => (
        <div
          key={camera.id}
          style={{ ...iframeSize }}
          className="border border-slate-800"
        >
          <div className="px-4 py-1 bg-black flex justify-between text-white">
            <span className="text-medium">{camera.name}</span>
            <IconButton
              size="small"
              color="inherit"
              onClick={() => onRemoveCameraFromView?.(camera)}
            >
              <Close fontSize="small" />
            </IconButton>
          </div>
          <div
            className="relative w-full overflow-hidden"
            style={{ height: iframeSize.height - 32 }}
          >
            <CustomHlsPlayer
              className="w-full bg-[#000000EE] text-white"
              containerProps={{ className: "text-white" }}
              style={{ height: iframeSize.height - 32 }}
              autoPlay
              src={apiConfig.baseUrlNoPort + `/media/${camera.id}/index.m3u8`}
            />
          </div>
        </div>
      ))}
    </>
  );
}
