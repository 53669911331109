import { useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import { notify, occurrenceNotificationMessage } from "lib/notification";
import { apiConfig } from "api/rootConfig";
import {
  LogHandlingMessage,
  MonitoringMessage,
  isLogHandlingMessage,
} from "interfaces/monitoring";
import GlobalContext from "context/GlobalContext";

interface PusherData {
  message: MonitoringMessage | LogHandlingMessage;
}

export default function usePusher(filter?: {
  cameras: number[] | undefined;
  panicCameras: number[] | undefined;
}) {
  const [occurrences, setOccurrences] = useState<MonitoringMessage[]>([]);
  const [logHandlingMessage, setLogHandlingMessage] =
    useState<LogHandlingMessage>();
  const { user } = useContext(GlobalContext);

  useEffect(() => {
    if (filter && !!user) {
      // console.log("Pusher instanciado");
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
        wsHost: new URL(apiConfig.baseUrl).host,
        wsPort: !!apiConfig.basePort ? parseInt(apiConfig.basePort) : 80,
        forceTLS: false,
        // disableStats: true,
        cluster: "sa1",
      });
      const channel1 = pusher.subscribe(`zions.${user.id}`);
      // You can bind more channels here like this
      // const channel2 = pusher.subscribe('channel_name2')
      channel1.bind("alert", function (data: PusherData) {
        const occurrenceMessage: MonitoringMessage | LogHandlingMessage =
          data.message;
        // console.log("New message:", data.message);
        const isHandlingMessage = isLogHandlingMessage(occurrenceMessage);
        if (!isHandlingMessage) {
          notify(occurrenceNotificationMessage(occurrenceMessage));
          if (occurrenceMessage && occurrenceMessage.occurrence !== "Pânico") {
            if (filter.cameras?.includes(occurrenceMessage.camera_id)) {
              setOccurrences((prevState) => {
                const newOccurrences = [...prevState];
                newOccurrences.unshift(occurrenceMessage);
                newOccurrences.length = Math.min(newOccurrences.length, 6);
                return newOccurrences;
              });
            }
          } else if (occurrenceMessage) {
            if (filter.panicCameras?.includes(occurrenceMessage.camera_id)) {
              setOccurrences((prevState) => {
                const newOccurrences = [...prevState];
                newOccurrences.unshift(occurrenceMessage);
                newOccurrences.length = Math.min(newOccurrences.length, 6);
                return newOccurrences;
              });
            }
          }
        } else {
          setLogHandlingMessage(occurrenceMessage);
        }
      });

      return () => {
        pusher.unsubscribe(`zions.${user.id}`);
      };
    }
  }, [user, filter]);

  return { occurrences, logHandlingMessage };
}
