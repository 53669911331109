import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getSimplifiedCameras } from "api/cameras";
import { getAllCamerasSharedWithMe } from "api/cameraSharing";
import CamerasMosaicPaginatedView from "components/CamerasMosaicPaginatedView/CamerasMosaicPaginatedView";
import CamerasMosaicSidebar from "components/CamerasMosaicSidebar/CamerasMosaicSidebar";
import DynamicGrid from "components/DynamicGrid/DynamicGrid";
import {
  CAMERAS_SHARED_WITH_ME_QUERY_KEY,
  CAMERAS_SIMPLIFIED_QUERY_KEY,
} from "constants/apiQueryKeys";
import GlobalContext from "context/GlobalContext";
import { HeimdallCameraGroup } from "interfaces/heimdallCamera";
import { CamerasAndGroups } from "interfaces/occurrences";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";

export type GridLayout = {
  rows: number;
  columns: number;
};

export const INITIAL_SLIDESHOW_INTERVAL = 60; // seconds

export default function CamerasMosaic() {
  const { user } = useContext(GlobalContext);
  const {
    data: cameras,
    isLoading,
    error,
  } = useQuery({
    queryKey: [CAMERAS_SIMPLIFIED_QUERY_KEY],
    queryFn: () => getSimplifiedCameras(),
  });
  const { data: camerasSharedWithMe, isLoading: isLoadingCamerasSharedWithMe } =
    useQuery({
      queryKey: [
        CAMERAS_SHARED_WITH_ME_QUERY_KEY,
        {
          filters: {
            receiverId: user?.id,
            status: "active",
          },
        },
      ],
      queryFn: () =>
        getAllCamerasSharedWithMe({
          page: 1,
          pageSize: 1000000,
          filters: {
            receiverId: user?.id,
            status: "active",
          },
        }),
    });

  const allCameras = useMemo(() => {
    if (cameras && camerasSharedWithMe) {
      const allCamerasTransform = {
        ...cameras,
        data: [
          ...cameras.data,
          ...camerasSharedWithMe.data.map((value) => ({
            id: value.camera_shared.camera_id,
            name: value.camera_shared.name,
          })),
        ],
      };

      return allCamerasTransform;
    }
    return null;
  }, [cameras, camerasSharedWithMe]);

  const [gridLayout, setGridLayout] = useState<GridLayout>({
    rows: 2,
    columns: 2,
  });
  const [page, setPage] = useState(1);
  const [slideshow, setSlideshow] = useState(true);
  const [slideshowInterval, setSlideshowInterval] = useState(
    INITIAL_SLIDESHOW_INTERVAL
  );
  const fullscreenRef = useRef<HTMLDivElement | null>(null);
  const [selectedCameras, setSelectedCameras] = useState<
    { id: number; name: string }[]
  >([]);
  const [internalSelectedCameras, setInternalSelectedCameras] = useState<
    CamerasAndGroups[]
  >([]);

  // Show all cameras as a default view
  useEffect(() => {
    if (!!allCameras) {
      setSelectedCameras(allCameras.data);
    }
  }, [allCameras]);

  // Handles the slideshow logic
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (slideshow) {
      intervalId = setInterval(() => {
        const hasNext =
          cameras &&
          gridLayout.rows * gridLayout.columns * page < selectedCameras.length;
        if (hasNext) setPage((prev) => prev + 1);
        else setPage(1);
      }, slideshowInterval * 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [
    page,
    cameras,
    slideshow,
    gridLayout,
    selectedCameras,
    slideshowInterval,
  ]);

  function goFullscreen() {
    fullscreenRef.current?.requestFullscreen();
  }

  function handleChangeGridLayout(value: GridLayout) {
    setPage(1);
    setGridLayout(value);
  }

  function handleChangeSelectedCameras(
    value: { id: number; name: string }[],
    internalValue: CamerasAndGroups[]
  ) {
    setPage(1);
    setSelectedCameras(value);
    setInternalSelectedCameras(internalValue);
  }

  function handleRemoveCameraFromView(value: { id: number; name: string }) {
    const newSelectedCameras = [...selectedCameras];
    newSelectedCameras.splice(
      newSelectedCameras.findIndex((_value) => _value.id === value.id),
      1
    );
    // Go to prev page if it is the last element of the page
    if (
      gridLayout.rows * gridLayout.columns * page -
        gridLayout.rows * gridLayout.columns ===
        selectedCameras.length - 1 &&
      page > 1
    ) {
      setPage((prev) => prev - 1);
    }
    /** Here the user is removing a camera from view while `All Cameras`
     * is selected. Then, here I change the internal value from `All Cameras`
     * to every camera except the one that was removed
     */
    const allCamerasGroup = internalSelectedCameras.find(
      (_value) => _value.id === -1
    );
    if (!!allCamerasGroup) {
      const newInternalSelectedCameras = (
        allCamerasGroup as HeimdallCameraGroup
      ).cameras_in_group.reduce<CamerasAndGroups[]>((newArr, _value) => {
        if (_value.id !== value.id) {
          newArr.push({
            id: _value.id,
            name: _value.name,
            category: "Câmeras",
          });
        }
        return newArr;
      }, []);
      setInternalSelectedCameras(newInternalSelectedCameras);
    }
    setSelectedCameras(newSelectedCameras);
  }

  if (isLoading || isLoadingCamerasSharedWithMe) {
    return (
      <div className="surface flex">
        <div className="w-64 p-3 primary-container on-primary-container-text">
          <h2 className="text-xl mb-3">Mosaico de câmeras</h2>
        </div>
        <div className="flex-1 h-[calc(100vh-60px)]">
          <AutoSizer>
            {(size: { width: number; height: number }) => (
              <div
                style={{ width: size.width, height: size.height }}
                className="flex-1 grid gap-x-2 grid-cols-1 sm:grid-cols-2"
              >
                {[1, 2, 3, 4].map((value) => (
                  <Skeleton
                    key={value}
                    variant="rounded"
                    className="w-full"
                    height={size.height / 2.04}
                  />
                ))}
              </div>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="surface">
        <h2 className="text-xl mb-3">Mosaico de câmeras</h2>
        {JSON.stringify(error, null, 2)}
      </div>
    );
  }

  return (
    <div className="surface flex h-[calc(100vh-60px)] overflow-hidden">
      <CamerasMosaicSidebar
        page={page}
        cameras={allCameras!}
        slideshow={slideshow}
        gridLayout={gridLayout}
        selectedCameras={selectedCameras}
        slideshowInterval={slideshowInterval}
        internalSelectedCameras={internalSelectedCameras}
        onChangeSlideshowInterval={(value) => setSlideshowInterval(value)}
        onToggleSlideshow={() => setSlideshow((prev) => !prev)}
        onChangeSelectedCameras={handleChangeSelectedCameras}
        onMoveToPrevPage={() => setPage((prev) => prev - 1)}
        onMoveToNextPage={() => setPage((prev) => prev + 1)}
        onChangeGridLayout={handleChangeGridLayout}
        onRequestFullscreen={goFullscreen}
      />
      <div className="flex-1">
        <div className="h-full" ref={fullscreenRef}>
          <AutoSizer>
            {(size: { width: number; height: number }) => (
              <div style={{ width: size.width, height: size.height }}>
                <DynamicGrid gridLayout={gridLayout}>
                  <CamerasMosaicPaginatedView
                    page={page}
                    size={size}
                    data={selectedCameras}
                    gridLayout={gridLayout}
                    onRemoveCameraFromView={handleRemoveCameraFromView}
                  />
                </DynamicGrid>
              </div>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
}
