import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { HeimdallCamera } from "interfaces/heimdallCamera";
import { Stepper, Step, StepLabel } from "@mui/material";
import MainContainer from "components/MainContainer/MainContainer";
import CameraForm from "components/AddCamera/CreateCameraForm/CameraForm";
import styles from "./AddCamera.module.css";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";

// Used by MoniSectorForm
export const CameraContext = createContext<HeimdallCamera | null>(null);
// Used in ConfiguerAnalyticsPage and here
export const addCameraSteps = ["Cadastrar câmera", "Configurar analíticos"];

export function AddCamera() {
  const navigate = useNavigate();
  const { isDark } = useHeimdallTheme();

  async function onCreateCamera(cameraId: number) {
    navigate(`/cameras/${cameraId}/configure-analytics`, {
      state: {
        showStepper: true,
      },
    });
  }

  return (
    <MainContainer
      returnAction
      title="Nova câmera"
      style={{ overflowX: "hidden" }}
    >
      <p className={styles.subtitle}>
        Crie uma nova câmera para cadastrar na plataforma do heimdall. Em
        seguida, configure os analíticos que você deseja para essa câmera.
      </p>
      <div
        className={`${styles.stepperContainer} ${
          isDark ? "surface-variant" : "surface"
        }`}
      >
        <Stepper activeStep={0}>
          {addCameraSteps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <CameraForm onCreateCamera={onCreateCamera} />
    </MainContainer>
  );
}
