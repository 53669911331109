import {
  Autocomplete,
  TextField,
  Button,
  IconButton,
  Switch,
  MenuItem,
  Divider,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getGroups } from "api/cameraGroup";
import { useToast } from "components/ui/use-toast";
import { CAMERA_GROUPS_QUERY_KEY } from "constants/apiQueryKeys";
import { BackendResponsePagination } from "interfaces/generic";
import { HeimdallCameraGroup } from "interfaces/heimdallCamera";
import { CamerasAndGroups } from "interfaces/occurrences";
import { cn } from "lib/classNames";
import { Fullscreen, ChevronLeft, ChevronRight } from "lucide-react";
import {
  GridLayout,
  INITIAL_SLIDESHOW_INTERVAL,
} from "pages/cameras-mosaic/CamerasMosaic";
import React, { useMemo, useState } from "react";

export default function CamerasMosaicSidebar({
  page,
  cameras,
  slideshow,
  gridLayout,
  selectedCameras,
  slideshowInterval,
  internalSelectedCameras,
  onMoveToNextPage,
  onMoveToPrevPage,
  onToggleSlideshow,
  onChangeGridLayout,
  onRequestFullscreen,
  onChangeSelectedCameras,
  onChangeSlideshowInterval,
}: {
  page: number;
  slideshow: boolean;
  gridLayout: GridLayout;
  slideshowInterval: number;
  internalSelectedCameras: CamerasAndGroups[];
  selectedCameras: { id: number; name: string }[];
  cameras: BackendResponsePagination<{ id: number; name: string }>;
  onMoveToNextPage: () => void;
  onMoveToPrevPage: () => void;
  onToggleSlideshow: () => void;
  onRequestFullscreen: () => void;
  onChangeGridLayout: (value: GridLayout) => void;
  onChangeSlideshowInterval: (value: number) => void;
  onChangeSelectedCameras: (
    value: { id: number; name: string }[],
    internalValue: CamerasAndGroups[]
  ) => void;
}) {
  const { data: groups, isLoading: isLoadingGroups } = useQuery({
    queryKey: [CAMERA_GROUPS_QUERY_KEY],
    queryFn: () => getGroups(),
  });
  const camsAndGroups = useMemo(() => {
    const newCamerasAndGroups: CamerasAndGroups[] = [];
    if (!!cameras) {
      // Creating an option that has every camera
      const allCamerasGroup = getAllCamerasGroup(cameras.data);
      newCamerasAndGroups.push({ ...allCamerasGroup, category: "Grupos" });
      // Adding every cameras to the options
      cameras.data.forEach((_camera) =>
        newCamerasAndGroups.push({ ..._camera, category: "Câmeras" })
      );
    }
    if (!!groups) {
      groups.data.forEach((_group) =>
        newCamerasAndGroups.push({ ..._group, category: "Grupos" })
      );
    }
    return newCamerasAndGroups;
  }, [cameras, groups]);
  const gridArea = useMemo(
    () => gridLayout.rows * gridLayout.columns,
    [gridLayout]
  );
  const hasNext = useMemo(
    () => cameras && gridArea * page < selectedCameras.length,
    [cameras, selectedCameras, gridArea, page]
  );
  const hasPrev = page > 1;
  const { toast } = useToast();
  const [internalInterval, setInternalInterval] = useState(
    slideshowInterval.toString()
  );
  const [isHidden, setIsHidden] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(true);
  const [expandButtonPosition, setExpandButtonPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 5,
    y: 200,
  });

  function handleIntervalChange() {
    if (!internalInterval) {
      // default value
      return onChangeSlideshowInterval(INITIAL_SLIDESHOW_INTERVAL);
    }

    try {
      const newInterval = parseInt(internalInterval);
      if (newInterval < 1) {
        throw new Error();
      }
      onChangeSlideshowInterval(newInterval);
      toast({
        title: "Intervalo salvo com sucesso",
        description: `Novo intervalo de ${newInterval} segundos para o slideshow`,
      });
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Erro ao definir intervalo",
        description:
          "O campo intervalo deve ser um número inteiro maior que zero",
      });
    }
  }

  function handleSelectedCameras(value: CamerasAndGroups[]) {
    if (value.length === 0) {
      return onChangeSelectedCameras(cameras.data, [
        { ...getAllCamerasGroup(cameras.data), category: "Grupos" },
      ]);
    }
    const addedElement = value[value.length - 1];
    /** Here the user chose to view `All Cameras` after he had selected
     * specific cameras, so we remove the specific cameras and maintain
     * only the `All cameras` object
     */
    if (addedElement.id === -1) {
      return onChangeSelectedCameras(
        (addedElement as HeimdallCameraGroup).cameras_in_group,
        [addedElement]
      );
    }
    /** Here the user chose to view a specific camera after he had selected
     * `All cameras`, so we remove the `All Cameras` object and maintain
     * only the sepecific camera
     */
    if (addedElement.id !== -1 && !!value.find((_value) => _value.id === -1)) {
      if (addedElement.category === "Grupos") {
        const camerasInGroup = (addedElement as HeimdallCameraGroup)
          .cameras_in_group;
        return onChangeSelectedCameras(camerasInGroup, [addedElement]);
      }
      if (addedElement.category === "Câmeras") {
        return onChangeSelectedCameras(
          [{ id: addedElement.id, name: addedElement.name }],
          [addedElement]
        );
      }
    }

    const newSelectedCameras = new Map<string, { id: number; name: string }>();

    for (let index = 0; index < value.length; index++) {
      const element = value[index];

      if (element.category === "Grupos") {
        const camerasInGroup = (element as HeimdallCameraGroup)
          .cameras_in_group;
        camerasInGroup.forEach((cam) =>
          newSelectedCameras.set(
            JSON.stringify({ id: cam.id, name: cam.name }),
            { id: cam.id, name: cam.name }
          )
        );
      }
      if (element.category === "Câmeras") {
        newSelectedCameras.set(
          JSON.stringify({ id: element.id, name: element.name }),
          { id: element.id, name: element.name }
        );
      }
    }
    onChangeSelectedCameras(Array.from(newSelectedCameras.values()), value);
  }

  return (
    <div
      className={cn(
        "relative flex-col w-72 pt-3 px-3 space-y-3 primary-container on-primary-container-text",
        isHidden ? "w-0 p-0" : "flex"
      )}
    >
      <div
        onMouseEnter={(ev) => {
          setShowExpandButton(true);
        }}
        onMouseMove={(ev) => {
          setExpandButtonPosition({ x: ev.clientX - 15, y: ev.clientY - 65 });
        }}
        onMouseLeave={(ev) => {
          setShowExpandButton(false);
          setExpandButtonPosition({ x: 5, y: 300 });
        }}
        className={cn(
          "absolute w-10 h-full",
          isHidden ? "-right-10" : "right-0 flex items-start pt-2"
        )}
      >
        <IconButton
          className={cn(
            isHidden ? "absolute" : "",
            isHidden && !showExpandButton ? "hidden" : ""
          )}
          sx={
            isHidden
              ? { top: expandButtonPosition.y, left: expandButtonPosition.x }
              : {}
          }
          color={isHidden ? "info" : "default"}
          onClick={() => {
            setIsHidden((prev) => !prev);
            setShowExpandButton(false);
          }}
        >
          {isHidden ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <div className={cn(isHidden ? "hidden" : "space-y-3")}>
        <h2 className="text-xl font-medium">Mosaico de câmeras</h2>
        <h3>Câmeras monitoradas</h3>
        <div className="max-h-52 overflow-y-auto">
          <Autocomplete
            multiple
            size="small"
            disablePortal
            loading={isLoadingGroups}
            id="cams-and-groups-autocomplete"
            options={camsAndGroups.sort((a, b) =>
              b.category.localeCompare(a.category)
            )}
            value={internalSelectedCameras}
            groupBy={(option) => option.category}
            getOptionLabel={(option) => option.name}
            onChange={(ev, newValue) => handleSelectedCameras(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                name="camerasIds"
                label="Câmeras e Grupos"
              />
            )}
          />
        </div>
        <h3>Tamanho da grade e paginação</h3>
        <div className="flex justify-between items-center">
          <GridLayoutSelector
            gridLayout={gridLayout}
            onChangeGridLayout={onChangeGridLayout}
          />
          <span>
            {1 + gridArea * (page - 1)}
            &ndash;{hasNext ? gridArea * page : selectedCameras.length} de{" "}
            {selectedCameras.length}
          </span>
          <IconButton
            size="small"
            disabled={!hasPrev}
            onClick={onMoveToPrevPage}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            size="small"
            disabled={!hasNext}
            onClick={onMoveToNextPage}
          >
            <ChevronRight />
          </IconButton>
        </div>
        <Divider />
        <div className="space-y-3">
          <span className="flex justify-between items-center">
            <label>Slideshow</label>
            <Switch checked={slideshow} onChange={onToggleSlideshow} />
          </span>
          {slideshow && (
            <span className="flex space-x-1">
              <TextField
                size="small"
                label="Intervalo entre slides (s)"
                value={internalInterval}
                onChange={(ev) => setInternalInterval(ev.target.value)}
              />
              <Button onClick={handleIntervalChange}>Salvar</Button>
            </span>
          )}
        </div>
        <Button
          color="primary"
          variant="outlined"
          onClick={onRequestFullscreen}
          startIcon={<Fullscreen />}
        >
          Modo tela cheia
        </Button>
      </div>
    </div>
  );
}

function GridLayoutSelector({
  gridLayout,
  onChangeGridLayout,
}: {
  gridLayout: GridLayout;
  onChangeGridLayout: (value: GridLayout) => void;
}) {
  return (
    <TextField
      select
      variant="standard"
      value={JSON.stringify(gridLayout)}
      onChange={(ev) => {
        const newGridLayout = JSON.parse(ev.target.value) as GridLayout;
        onChangeGridLayout(newGridLayout);
      }}
    >
      <MenuItem value={JSON.stringify({ rows: 1, columns: 1 })}>1x1</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 2, columns: 2 })}>2x2</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 2, columns: 3 })}>2x3</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 3, columns: 2 })}>3x2</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 3, columns: 3 })}>3x3</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 3, columns: 4 })}>3x4</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 4, columns: 3 })}>4x3</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 4, columns: 4 })}>4x4</MenuItem>
      <MenuItem value={JSON.stringify({ rows: 4, columns: 5 })}>4x5</MenuItem>
    </TextField>
  );
}

function getAllCamerasGroup(allCameras: { id: number; name: string }[]) {
  const allCamerasGroup: HeimdallCameraGroup = {
    id: -1,
    name: "Todas",
    cameras_in_group: allCameras,
    cameras_out_group: [],
  };
  return allCamerasGroup;
}
