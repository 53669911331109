import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export default function DispatcherInUseDialog({
  open,
  onClose,
  title,
  analytics,
}: {
  open: boolean;
  onClose: () => void;
  title?: string;
  analytics: { cameraName: string; analyticType: string }[];
  isLoading?: boolean;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="div">
          <h3>As seguintes câmeras estão usando esse despachador:</h3>
          <ul style={{ listStyle: "inside", marginTop: "5px" }}>
            {analytics.map((analytic, index) => (
              <li key={index}>
                {analytic.cameraName} -&gt; {analytic.analyticType}
              </li>
            ))}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
