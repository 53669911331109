import { Preferences } from "@capacitor/preferences";
import { getUser } from "api/user";

// This loader is used in the AuthLayout component and in the routes definition

const redirectToAppLoader = async () => {
  const token = await Preferences.get({ key: "token" });
  console.log("Running redirect to app loader");

  if (token.value) {
    return await getUser()
      .then((user) => {
        return user;
      })
      .catch(() => {
        return null;
      });
  }

  return null;
};

export default redirectToAppLoader;
