import { BackendResponsePagination } from "interfaces/generic";
import axiosRoot from "./rootConfig";
import { HeimdallCamera } from "interfaces/heimdallCamera";
import { HeimdallVmsCameraForm } from "interfaces/vms";
import { CAMERAS_MAP_QUERY_KEY } from "constants/apiQueryKeys";

interface GetCamerasParameters {
  page?: number;
  pageSize?: number;
  cameraName?: string;
  cameraLocation?: string;
  status?: HeimdallCamera["status"] | "";
  sharedCamerasOnly?: boolean;
}

export async function getCameras(
  {
    page,
    pageSize,
    cameraName,
    cameraLocation,
    status,
    sharedCamerasOnly,
  }: GetCamerasParameters = {
    page: 1,
    pageSize: 10000,
  }
) {
  let requestURL = "/api/camera?page=" + page;

  if (cameraName) requestURL += "&filter%5Bname%5D=" + cameraName;
  if (cameraLocation) requestURL += "&filter%5Baddress%5D=" + cameraLocation;
  if (status) requestURL += "&filter%5Bstatus%5D=" + status;
  if (sharedCamerasOnly)
    requestURL += "&filter%5Bis_shared%5D=" + sharedCamerasOnly;

  return await axiosRoot
    .get<BackendResponsePagination<HeimdallCamera>>(requestURL, {
      headers: {
        paginate: pageSize,
      },
      timeout: 15000,
    })
    .then((response) => response.data);
}

/**
 * This method provides only the camera's id and name fields, and is way faster than
 * normal getCameras method
 * @param page the page you want to fetch (pagination)
 * @param pageSize the amout of rows you want to use to display your results, per page
 * @returns
 */
export async function getSimplifiedCameras(
  {
    page = 1,
    pageSize = 1000000,
  }: Omit<GetCamerasParameters, "cameraName" & "cameraLocation"> = {
    page: 1,
    pageSize: 1000000,
  }
) {
  return await axiosRoot
    .get<BackendResponsePagination<{ id: number; name: string }>>(
      "/api/dashboard_cameras?page=" + page,
      {
        headers: {
          paginate: pageSize,
        },
      }
    )
    .then((res) => res.data);
}

/**
 * This method provides only the camera's id, name, address and video fields, and is way faster than
 * normal getCameras method. It is used in the cameras map page
 * @param page the page you want to fetch (pagination)
 * @param pageSize the amout of rows you want to use to display your results, per page
 * @returns
 */
export async function getCamerasForMap(
  {
    page = 1,
    pageSize = 1000000,
  }: Omit<GetCamerasParameters, "cameraName" & "cameraLocation"> = {
    page: 1,
    pageSize: 1000000,
  }
) {
  return await axiosRoot
    .get<
      BackendResponsePagination<
        Pick<
          HeimdallCamera,
          "id" | "name" | "camera_address" | "video_media_mtx"
        >
      >
    >(`${CAMERAS_MAP_QUERY_KEY}?page=${page}`, {
      headers: {
        paginate: pageSize,
      },
    })
    .then((res) => res.data);
}

export async function RqGetCameras(
  { page, pageSize, cameraName, cameraLocation }: GetCamerasParameters = {
    page: 1,
    pageSize: 10000,
  }
) {
  let requestURL = "/api/camera?page=" + page;

  if (cameraName) requestURL += "&filter%5Bname%5D=" + cameraName;
  if (cameraLocation) requestURL += "&filter%5Baddress%5D=" + cameraLocation;

  const { data } = await axiosRoot.get<
    BackendResponsePagination<HeimdallCamera>
  >(requestURL, {
    headers: {
      paginate: pageSize,
    },
    timeout: 15000,
  });

  return data;
}

export async function createCamera(data: any) {
  return await axiosRoot
    .post("/api/camera", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 30000,
    })
    .then((response) => response.data);
}

export async function createVmsCamera(camera: HeimdallVmsCameraForm) {
  const oneMinuteInMilli = 60 * 1000;
  return await axiosRoot
    .post("/api/camera_from_vms", camera, { timeout: oneMinuteInMilli })
    .then((response) => response.data)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function getCamera(id: number | string) {
  return await axiosRoot
    .get<{ data: HeimdallCamera }>("/api/camera/" + id)
    .then((response) => response.data.data);
}

export async function getAnalyticFromCamera(id: number | string) {
  return await axiosRoot
    .get("/api/analytic_by_camera/" + id)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function editCamera(id: number | string, data: any) {
  return await axiosRoot
    .post("/api/camera/" + id + "?_method=PUT", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 40000,
    })
    .then((response) => response.data);
}

export async function deleteCamera(id: number | string, timeout?: number) {
  return await axiosRoot
    .delete("/api/camera/" + id, { timeout })
    .then((response) => response.data)
    .catch((err) => console.error(err));
}
