import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { LprLog } from "interfaces/analytics";
import { apiConfig } from "api/rootConfig";
import { translateColor } from "lib/lprColorsTranslation";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { BackendResponsePagination } from "interfaces/generic";
import { getResizedImagePath } from "lib/resizedImage";

export function LprList({
  page,
  lprLogs,
  pageSize,
  onPageChange,
  onPageSizeChange,
  setSelectedImage,
}: {
  page: number;
  pageSize: number;
  lprLogs?: BackendResponsePagination<LprLog>;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  setSelectedImage: (value: string) => void;
}) {
  return (
    <div
      style={{
        marginTop: "15px",
      }}
    >
      <header className="flex justify-between items-center mb-3">
        <h2 className="text-xl mb-3">Resultados da busca</h2>
        <LprPagination
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          page={page}
          lprLogs={lprLogs}
          onPageChange={onPageChange}
        />
      </header>
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {!lprLogs && (
          <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => (
              <Skeleton key={val} variant="rounded" width="100%" height={290} />
            ))}
          </>
        )}
        {lprLogs?.data.map((vehicle) => (
          <Card key={vehicle.id} sx={{ width: "100%" }}>
            <CardActionArea
              onClick={() => {
                setSelectedImage(apiConfig.baseUrl + vehicle.url);
              }}
            >
              <CardMedia
                sx={{
                  height: 180,
                  width: "auto",
                }}
                image={
                  apiConfig.baseUrl + getResizedImagePath(vehicle.url, 400)
                }
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {vehicle.plate_value}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Câmera: {vehicle.camera.camera_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Data: {vehicle.created_at}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Cores:{" "}
                {vehicle.car_color.map((color) => `${translateColor(color)} `)}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}

function LprPagination({
  page,
  pageSize,
  lprLogs,
  onPageChange,
  onPageSizeChange,
}: {
  page: number;
  pageSize: number;
  lprLogs: BackendResponsePagination<LprLog> | undefined;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}) {
  return (
    <div className="flex items-center space-x-2">
      <TextField
        select
        size="small"
        value={pageSize}
        variant="standard"
        sx={{ minWidth: "45px" }}
        aria-label="Resultados por página"
        onChange={(ev) => onPageSizeChange(parseInt(ev.target.value))}
      >
        <MenuItem value="25">25</MenuItem>
        <MenuItem value="50">50</MenuItem>
        <MenuItem value="100">100</MenuItem>
      </TextField>
      <p>
        {1 + pageSize * (page - 1)}-
        {Math.min(pageSize * page, lprLogs?.meta.total || 0)} de{" "}
        {lprLogs?.meta.total || 0}
      </p>
      <Tooltip title="Página anterior" placement="top">
        <span>
          <IconButton
            onClick={() => onPageChange(page - 1)}
            disabled={page === 1}
          >
            <ChevronLeft />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Próxima página" placement="top">
        <span>
          <IconButton
            onClick={() => onPageChange(page + 1)}
            disabled={!lprLogs || page === lprLogs.meta.last_page}
          >
            <ChevronRight />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}
