import React, { StrictMode } from "react";
import ReactDOMClient from "react-dom/client";

import heimdallRoutes from "routes";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HeimdallThemeProvider } from "context/HeimdallThemeProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LocalizationProvider } from "@mui/x-date-pickers";
import GlobalContextProvider from "./context/GlobalContextProvider";
import { Toaster } from "./components/ui/toaster";
import { isPlatform, setupIonicReact } from "@ionic/react";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import {
  checkDevelopmentApiUrl,
  checkGoogleMapsApiKey,
  checkPusherKey,
} from "lib/startupUtils";
// Global styles - The order of import matters!
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

import "App.css";
import "styles/md3-theme/theme.css";
import { ScreenOrientation } from "@capacitor/screen-orientation";

dayjs.locale("pt-br");

setupIonicReact();

export const isMobile = isPlatform("mobile") && !isPlatform("mobileweb");

if (isMobile) {
  ScreenOrientation.lock({ orientation: "portrait" });
}

const container = document.getElementById("root") as HTMLElement;

const root = ReactDOMClient.createRoot(container);

// Router singleton created
const router = createBrowserRouter(heimdallRoutes);

// Create a client
const queryClient = new QueryClient();

const isGoogleMapsApiKeySet = checkGoogleMapsApiKey();
const isDevApiUrlSet = checkDevelopmentApiUrl();
const isPusherKeySet = checkPusherKey();

if (
  !isGoogleMapsApiKeySet ||
  !isPusherKeySet ||
  (process.env.NODE_ENV === "development" && !isDevApiUrlSet)
)
  root.render(
    <div>
      <h1 className="text-xl">
        Opa! As seguintes variáveis de ambiente precisam ser configuradas:
      </h1>
      <ul className="my-5">
        {!isGoogleMapsApiKeySet && <li>Google maps api key</li>}
        {!isDevApiUrlSet && <li>Dev api url</li>}
        {!isPusherKeySet && <li>Pusher key</li>}
      </ul>
      <p>Após configurá-las, reinicie o projeto.</p>
      <p>
        Caso tenha alguma dúvida, consulte o guia de instalação do projeto no
        arquivo README.md.
      </p>
    </div>
  );
else
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <GlobalContextProvider>
            <HeimdallThemeProvider>
              <RouterProvider router={router} />
              <Toaster />
              {/* <ReactQueryDevtools /> */}
            </HeimdallThemeProvider>
          </GlobalContextProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </StrictMode>
  );
