import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Skeleton } from "@mui/material";
import { canMoveRegion, isPointInPolygon, Vector2d } from "lib/canvas";
import { cn } from "lib/classNames";

export interface PolygonI {
  points: Vector2d[];
  color: string;
  name: string;
  id: string;
}

interface RadarCanvasProps {
  region: PolygonI;
  className?: React.ComponentProps<"div">["className"];
  dimensions: { width: number; height: number };
  cameraFrame: {
    img: string;
    isLoading: boolean;
    isStale: boolean;
    refetchFrame: () => Promise<void>;
  };
  onChangeRegion: (newRegion: PolygonI) => void;
}

const VERTICES_SIZE = 10;
const VERTICES_COLOR = "rgb(243,214,83)";
const REGION_COLOR = "rgba(13,239,65,0.26)";

export default function RadarCanvas({
  region,
  className,
  dimensions,
  cameraFrame,
  onChangeRegion,
}: RadarCanvasProps) {
  // Canvas reference, nothing special
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // Keeps current region in a ref to access its value inside a listener
  const internalRegion = useRef<PolygonI | null>(region);
  // True if the user is pressing down somewhere inside the canvas boundaries
  const isMouseDown = useRef(false);
  // Saves region as soon as it starts moving
  const regionMoveStart = useRef<PolygonI | null>(null);
  // The initial point the user clicked when they started to move the region
  const mouseDownShapeStart = useRef<Vector2d | null>(null);
  // True if the user is pressing down inside a region
  const mouseDownShape = useRef(false);
  // The index of the vertex the user pressed down into (i.e. the index of the vertex being moved)
  const mouseDownVertexIndex = useRef<number | null>(null);
  // Denormalizing region
  const denormalizedRegion = useMemo(
    () => ({
      ...region,
      points: region.points.map((value) => ({
        x: value.x * dimensions.width,
        y: value.y * dimensions.height,
      })),
    }),
    [region, dimensions]
  );

  function resetMovementFlags() {
    isMouseDown.current = false;
    mouseDownShape.current = false;
    mouseDownVertexIndex.current = null;
  }

  const drawRegion = useCallback(() => {
    const context = canvasRef.current?.getContext("2d");
    if (!context) return;
    context.clearRect(0, 0, dimensions.width, dimensions.height);
    internalRegion.current = denormalizedRegion;

    // Drawing edges
    context.beginPath();
    context.lineWidth = 3;
    context.moveTo(
      denormalizedRegion.points[0].x,
      denormalizedRegion.points[0].y
    );

    // Draw the first dashed line (0 -> 1)
    context.setLineDash([10, 15]);
    context.lineTo(
      denormalizedRegion.points[1].x,
      denormalizedRegion.points[1].y
    );
    context.stroke(); // Stroke the dashed segment
    context.beginPath(); // Start a new path for the next line
    context.lineWidth = 2;

    // Draw the second solid line (1 -> 2)
    context.setLineDash([]); // Solid line
    context.moveTo(
      denormalizedRegion.points[1].x,
      denormalizedRegion.points[1].y
    );
    context.lineTo(
      denormalizedRegion.points[2].x,
      denormalizedRegion.points[2].y
    );
    context.stroke(); // Stroke the solid segment
    context.beginPath(); // Start a new path for the next line
    context.lineWidth = 3;

    // Draw the third dashed line (2 -> 3)
    context.setLineDash([10, 15]); // Dashed line
    context.moveTo(
      denormalizedRegion.points[2].x,
      denormalizedRegion.points[2].y
    );
    context.lineTo(
      denormalizedRegion.points[3].x,
      denormalizedRegion.points[3].y
    );
    context.stroke(); // Stroke the dashed segment
    context.beginPath(); // Start a new path for the next line
    context.lineWidth = 2;

    // Draw the fourth solid line (3 -> 0)
    context.setLineDash([]); // Solid line
    context.moveTo(
      denormalizedRegion.points[3].x,
      denormalizedRegion.points[3].y
    );
    context.lineTo(
      denormalizedRegion.points[0].x,
      denormalizedRegion.points[0].y
    );
    context.stroke(); // Stroke the solid segment

    // Optional: Fill the polygon if needed
    context.fillStyle = REGION_COLOR; // Set fill color
    context.fill(); // Fill the shape if desired

    // Reset dash pattern to default for any future drawings
    context.setLineDash([]);
    context.closePath();

    context.beginPath();
    context.moveTo(
      denormalizedRegion.points[0].x,
      denormalizedRegion.points[0].y
    );

    denormalizedRegion.points.forEach((vertex, idx) => {
      if (idx !== 0) {
        context.lineTo(vertex.x, vertex.y);
      }
    });

    context.lineTo(
      denormalizedRegion.points[0].x,
      denormalizedRegion.points[0].y
    );
    context.fill();
    context.closePath();

    context.lineWidth = 1;
    context.font = "12px Roboto";
    // Drawing vertices
    context.fillStyle = VERTICES_COLOR;
    denormalizedRegion.points.forEach((vertex, idx) => {
      context.beginPath();
      context.arc(vertex.x, vertex.y, VERTICES_SIZE, 0, Math.PI * 2);
      context.closePath();
      context.fill();
      context.strokeText((idx + 1).toString(), vertex.x - 4, vertex.y + 4);
    });
  }, [dimensions, denormalizedRegion]);

  // Draws polygon and vertices on region change
  useEffect(() => {
    if (!cameraFrame.isLoading) {
      drawRegion();
    }
  }, [cameraFrame, drawRegion]);

  // Handles vertices movement and region movement
  useEffect(() => {
    const currentRef = canvasRef.current;
    const context = currentRef?.getContext("2d");
    function mouseDownHandler(ev: MouseEvent) {
      for (
        let index = 0;
        index < internalRegion.current!.points.length;
        index++
      ) {
        const vertex = internalRegion.current!.points[index];

        context?.beginPath();
        context?.arc(vertex.x, vertex.y, VERTICES_SIZE, 0, Math.PI * 2);
        if (context?.isPointInPath(ev.offsetX, ev.offsetY)) {
          isMouseDown.current = true;
          mouseDownVertexIndex.current = index;
          // console.log("is in vertex", index);
          return;
        }
      }

      if (
        isPointInPolygon(
          { x: ev.offsetX, y: ev.offsetY },
          internalRegion.current!.points
        )
      ) {
        isMouseDown.current = true;
        mouseDownShape.current = true;
        mouseDownShapeStart.current = { x: ev.offsetX, y: ev.offsetY };
        regionMoveStart.current = internalRegion.current;
        // console.log("is in polygon");
        return;
      }
    }

    function mouseMoveHandler(ev: MouseEvent) {
      if (isMouseDown.current && mouseDownVertexIndex.current !== null) {
        const newPoints = [...internalRegion.current!.points];
        // Cap the vertex movement to stay within canvas boundaries
        newPoints[mouseDownVertexIndex.current] = capToCanvas(
          { x: ev.offsetX, y: ev.offsetY },
          dimensions
        );
        return onChangeRegion({
          ...internalRegion.current!,
          points: newPoints,
        });
      }
      if (
        isMouseDown.current &&
        mouseDownShape.current &&
        mouseDownShapeStart.current !== null
      ) {
        const displacement = {
          x: ev.offsetX - mouseDownShapeStart.current.x,
          y: ev.offsetY - mouseDownShapeStart.current.y,
        };
        if (
          canMoveRegion(
            regionMoveStart.current!.points,
            displacement,
            dimensions
          )
        ) {
          const newPoints = regionMoveStart.current!.points.map((vertex) => {
            return {
              x: vertex.x + displacement.x,
              y: vertex.y + displacement.y,
            };
          });
          return onChangeRegion({
            ...internalRegion.current!,
            points: newPoints,
          });
        }
      }
    }
    if (currentRef) {
      currentRef.addEventListener("mousedown", mouseDownHandler);
      currentRef.addEventListener("mouseup", resetMovementFlags);
      currentRef.addEventListener("mouseleave", resetMovementFlags);
      currentRef.addEventListener("mousemove", mouseMoveHandler);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("mousedown", mouseDownHandler);
        currentRef.removeEventListener("mouseup", resetMovementFlags);
        currentRef.removeEventListener("mouseleave", resetMovementFlags);
        currentRef.removeEventListener("mousemove", mouseMoveHandler);
      }
    };
  }, [dimensions, onChangeRegion]);

  return (
    <div className={cn("relative", className)}>
      {cameraFrame.isLoading ? (
        <Skeleton
          width={dimensions.width}
          height={dimensions.height}
          variant="rounded"
        />
      ) : (
        <img
          className="select-none"
          src={`data:image/jpeg;base64,${cameraFrame.img}`}
          alt="Frame da câmera"
          width={dimensions.width}
          style={{
            width: dimensions.width,
            height: dimensions.height,
          }}
        />
      )}
      <div>
        <canvas
          ref={canvasRef}
          className="absolute top-0 left-0 select-none"
          width={dimensions.width}
          height={dimensions.height}
        ></canvas>
        <div className="flex items-center mt-2">
          <div className="size-7 border-2 border-slate-600 flex items-center justify-center"></div>
          <span className="ml-2 mr-3">Segue o sentido da via</span>
          <div className="size-7 border-2 border-slate-600 border-dashed flex items-center justify-center"></div>
          <span className="ml-2">Passagem de veículos</span>
        </div>
      </div>
    </div>
  );
}

function capToCanvas(
  vertex: Vector2d,
  dimensions: { width: number; height: number }
) {
  const cappedX = Math.max(0, Math.min(vertex.x, dimensions.width));
  const cappedY = Math.max(0, Math.min(vertex.y, dimensions.height));

  return { x: cappedX, y: cappedY };
}
