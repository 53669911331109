import { Preferences } from "@capacitor/preferences";

/**
 * Handles local storage and session storage clean up
 */
export async function cleanUp() {
  await Preferences.remove({ key: "token" });
  localStorage.removeItem("pusherTransportNonTLS");
  sessionStorage.clear();
}
