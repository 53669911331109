import React from "react";
import { VideocamOff } from "@mui/icons-material";
import { cn } from "lib/classNames";

interface CanvasErrorProps extends React.HTMLAttributes<HTMLDivElement> {
  myIconSize?: "inherit" | "small" | "medium" | "large";
}

export function CanvasError(props: CanvasErrorProps) {
  return (
    <div
      {...props}
      className={cn(
        "flex justify-center items-center, w-16 h-10 mb-2 border",
        props.className
      )}
    >
      <div className="flex flex-col items-center justify-center">
        {props.myIconSize === "small" ? (
          <VideocamOff fontSize="small" />
        ) : (
          <>
            <VideocamOff fontSize={props.myIconSize} />
            <span>Erro ao obter</span>
            <span>imagem da câmera</span>
          </>
        )}
      </div>
    </div>
  );
}
