import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "api/auth";
import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import PasswordInput from "components/PasswordInput/PasswordInput";
import styles from "./SignInForm.module.css";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import { HeimdallAuth } from "interfaces/generic";

export default function SignInForm({
  onSignIn,
}: {
  onSignIn: (user: HeimdallAuth) => Promise<void>;
}) {
  const { toast } = useToast();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [staySigned, setStaySigned] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSignIn(ev: FormEvent) {
    ev.preventDefault();
    setIsLoading(true);
    await signIn(username, password)
      .then((response) => onSignIn(response.data))
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;

          if (status === 422) {
            setErrorMessage("Senha incorreta");
          } else if (status === 401) {
            setErrorMessage("Usuário ou senha incorreta");
          } else if (status === 403) {
            setErrorMessage("Usuário bloqueado");
          } else {
            toast({
              variant: "destructive",
              description: getErrorMessage(error),
            });
          }
          setPasswordError(true);
          setPassword("");
          return;
        }
        setErrorMessage("Um erro inesperado ocorreu");
        setPasswordError(true);
        setPassword("");
      });

    setIsLoading(false);
  }

  return (
    <form className={styles.form}>
      <TextField
        fullWidth
        id="username"
        label="Usuário"
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
      <PasswordInput
        fullWidth
        label="Senha"
        id="password"
        value={password}
        error={passwordError}
        helperText={passwordError ? errorMessage : ""}
        onChange={(event) => {
          setPasswordError(false);
          setPassword(event.target.value);
        }}
      />
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isLoading}
        onClick={handleSignIn}
      >
        Entrar
      </LoadingButton>
      <Button
        variant="text"
        fullWidth
        onClick={() => {
          navigate("lost-password");
        }}
      >
        Esqueci minha senha
      </Button>
    </form>
  );
}
