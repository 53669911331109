import { useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import BorderedDataGrid, {
  BorderedDataGridParams,
} from "components/BorderedDataGrid/BorderedDataGrid";
import { CombinedLog, LprLog, RadarLog } from "interfaces/analytics";
import { LogsCardViewer } from "./LogsCardViewer";
import { BackendResponsePagination } from "interfaces/generic";
import MobilePagination from "components/MobilePagination/MobilePagination";

interface LogsMultiViewerParams extends BorderedDataGridParams {
  setSelectedOccurrence: ((log: RadarLog) => void) | ((log: LprLog) => void);
  setOpenExpanded: (value: boolean) => void;
  data?: BackendResponsePagination<CombinedLog>;
}

export type MobileLogsViewType = "compact" | "spacious";

const defaultPageSize = 15;

export default function LogsMultiViewer({
  data,
  setOpenExpanded,
  setSelectedOccurrence,
  ...borderedGridParams
}: LogsMultiViewerParams) {
  const isSm = useMediaQuery("(max-width: 600px)");
  const paginationModel = useMemo(
    () => borderedGridParams.paginationModel!,
    [borderedGridParams]
  );

  if (isSm) {
    return (
      <div className="h-full">
        <header className="flex items-center justify-between mb-3">
          <MobilePagination
            data={data}
            defaultPageSize={15}
            page={paginationModel.page}
            pageSize={paginationModel.pageSize || defaultPageSize}
            onPageChange={(page) => {
              borderedGridParams.onPaginationModelChange?.(
                {
                  page,
                  pageSize: paginationModel.pageSize,
                },
                {
                  api: borderedGridParams.apiRef!.current,
                }
              );
            }}
            onPageSizeChange={(pageSize) => {
              borderedGridParams.onPaginationModelChange?.(
                {
                  page: 0,
                  pageSize,
                },
                {
                  api: borderedGridParams.apiRef!.current,
                }
              );
            }}
          />
        </header>
        {borderedGridParams.loading ||
        (borderedGridParams.rows && borderedGridParams.rows.length > 0) ? (
          <LogsCardViewer
            isLoading={borderedGridParams.loading}
            rows={borderedGridParams.rows as CombinedLog[]}
            setSelectedOccurrence={setSelectedOccurrence}
            setOpenExpanded={setOpenExpanded}
          />
        ) : (
          <p>Os registros de ocorrências serão mostrados aqui</p>
        )}
      </div>
    );
  }

  return <BorderedDataGrid {...borderedGridParams} />;
}
