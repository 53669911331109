import React, { SVGProps } from "react";
import { ReactComponent as StatisticsIcon } from "assets/icons/statistics.svg";
import { ReactComponent as CftvIcon } from "assets/icons/cftv.svg";
import { ReactComponent as CarCrashIcon } from "assets/icons/carCrash.svg";
import { ReactComponent as CarSearchIcon } from "assets/icons/carSearch.svg";
import { ReactComponent as CarMultipleIcon } from "assets/icons/carMultiple.svg";
import { ReactComponent as CarArrowRightIcon } from "assets/icons/carArrowRight.svg";
import { ReactComponent as StandedPersonIcon } from "assets/icons/forenseSearch.svg";
import { ReactComponent as CameraGroupIcon } from "assets/icons/cameraGroup.svg";
import { ReactComponent as CameraGroupFilledIcon } from "assets/icons/cameraGroupFilled.svg";
import { ReactComponent as CameraConfigIcon } from "assets/icons/cameraConfig.svg";
import { ReactComponent as MoniIntegrationIcon } from "assets/icons/moniIntegration.svg";
import { ReactComponent as SettingsVideoCameraIcon } from "assets/icons/settings_video_camera.svg";
import { ReactComponent as NeuralNetworkIcon } from "assets/icons/neural_network1.svg";
import { ReactComponent as NeuralNetworkIcon2 } from "assets/icons/neural_network2.svg";
import { ReactComponent as ChromeIcon } from "assets/icons/chrome.svg";
import { ReactComponent as EdgeIcon } from "assets/icons/edge.svg";
import { ReactComponent as FirefoxIcon } from "assets/icons/firefox.svg";
import { ReactComponent as OperaIcon } from "assets/icons/opera.svg";
import { ReactComponent as SafariIcon } from "assets/icons/safari.svg";

export function Statistics(props: SVGProps<SVGElement>) {
  return <StatisticsIcon {...props} />;
}

export function Cftv(props: SVGProps<SVGElement>) {
  return <CftvIcon {...props} />;
}

export function CarSearch(props: SVGProps<SVGElement>) {
  return <CarSearchIcon {...props} />;
}

export function CarMultiple(props: SVGProps<SVGElement>) {
  return <CarMultipleIcon {...props} />;
}

export function CarArrowRight(props: SVGProps<SVGElement>) {
  return <CarArrowRightIcon {...props} />;
}

export function CarCrash(props: SVGProps<SVGElement>) {
  return <CarCrashIcon {...props} />;
}

export function StandedPerson(props: SVGProps<SVGElement>) {
  return <StandedPersonIcon {...props} />;
}

export function CameraGroup(props: SVGProps<SVGElement>) {
  return <CameraGroupIcon {...props} />;
}

export function CameraConfig(props: SVGProps<SVGElement>) {
  return <CameraConfigIcon {...props} />;
}

export function CameraGroupFilled(props: SVGProps<SVGElement>) {
  return <CameraGroupFilledIcon {...props} />;
}

export function MoniIntegration(props: SVGProps<SVGElement>) {
  return <MoniIntegrationIcon {...props} />;
}

export function SettingsVideoCamera(props: SVGProps<SVGElement>) {
  return <SettingsVideoCameraIcon {...props} />;
}
export function NeuralNetwork(props: SVGProps<SVGElement>) {
  return <NeuralNetworkIcon {...props} />;
}
export function NeuralNetwork2(props: SVGProps<SVGElement>) {
  return <NeuralNetworkIcon2 {...props} />;
}

export function Chrome(props: SVGProps<SVGElement>) {
  return <ChromeIcon {...props} />;
}

export function Edge(props: SVGProps<SVGElement>) {
  return <EdgeIcon {...props} />;
}

export function Firefox(props: SVGProps<SVGElement>) {
  return <FirefoxIcon {...props} />;
}

export function Opera(props: SVGProps<SVGElement>) {
  return <OperaIcon {...props} />;
}

export function Safari(props: SVGProps<SVGElement>) {
  return <SafariIcon {...props} />;
}
