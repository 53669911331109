import React from "react";
import {
  CarArrowRight,
  CarSearch,
  CarMultiple,
  StandedPerson,
} from "components/icons/thirdPartyIcons";
import {
  AirlineSeatReclineExtra,
  DirectionsRun,
  ErrorOutlineOutlined,
  Groups,
  MoreHoriz,
  Pets,
  Speed,
} from "@mui/icons-material";
import { AnalyticType } from "interfaces/analytics";

interface LogIconProps {
  logType: AnalyticType | string;
  color?: string;
  marginH?: string;
  fontSize?: "small" | "medium" | "large" | "inherit";
}

export function LogIcon({
  logType,
  color,
  marginH,
  fontSize = "medium",
}: LogIconProps) {
  switch (logType) {
    case "...":
      return <MoreHoriz />;
    case "Aglomeração":
    case "/log/agglomeration":
      return (
        <Groups
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Evasão":
    case "/log/evasion":
      return (
        <DirectionsRun
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
            transform: "scale(-1,1)",
            marginInline: marginH,
          }}
        />
      );
    case "Intrusão":
    case "/log/intrusion":
      return (
        <DirectionsRun
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Permanência":
    case "/log/permanence":
      return (
        <StandedPerson
          style={{
            width: 24,
            height: 24,
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Radar":
    case "/log/radar":
      return (
        <Speed
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
          }}
        />
      );
    case "Congestionamento":
    case "/log/vehicle-agglomeration":
      return (
        <CarMultiple
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Ausência de Veículo":
    case "/log/vehicle-evasion":
      return (
        <CarSearch
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Detecção de Veículo":
    case "/log/vehicle-intrusion":
      return (
        <CarArrowRight
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Detecção de Animal":
    case "/log/animal":
      return (
        <Pets
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Inatividade":
    case "/log/inactivity":
      return (
        <AirlineSeatReclineExtra
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Lpr":
    case "/log/lpr":
      return (
        <CarSearch
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    case "Pânico":
    case "/log/panic":
      return (
        <ErrorOutlineOutlined
          fontSize={fontSize}
          style={{
            fill: color || "var(--root-color-secondary)",
            marginInline: marginH,
          }}
        />
      );
    default:
      return null;
  }
}
