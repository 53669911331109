import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useReducer } from "react";
import { ReceiverType } from "./ConfigureAnalytic";
import {
  dispatcherReducer,
  getDefaultSimpleDispatcher,
} from "lib/dispatcherReducer";
import { useToast } from "components/ui/use-toast";
import { TimePicker } from "@mui/x-date-pickers";
import  { Dayjs } from "dayjs";
import { SimpleReceiverField } from "./SimpleReceiverField";
import { SimpleReceiver } from "./SimpleReceiver";
import {
  TelegramReceiver,
  UserReceiver,
  WhatsAppGroupReceiver,
} from "interfaces/newAlertSystem";
import { AnalyticType } from "interfaces/analytics";
import { Close, Save } from "@mui/icons-material";
import {
  canCreateNewDispatcher,
  createNewDispatcherMiddleware,
  DispatcherFormError,
} from "lib/alertSystem";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DISPATCHERS_QUERY_KEY } from "constants/apiQueryKeys";

export default function CreateSimpleDispatcherDialog({
  analyticType,
  openNewDispatcher,
  onClose,
}: {
  analyticType: AnalyticType;
  openNewDispatcher: boolean;
  onClose: () => void;
}) {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [dispatcher, dispatch] = useReducer(
    dispatcherReducer,
    getDefaultSimpleDispatcher()
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Sets the analytic type
  useEffect(() => {
    dispatch({
      type: "SET_ANALYTIC_TYPE",
      payload: analyticType,
    });
  }, [analyticType]);

  const { mutate, isPending } = useMutation({
    mutationFn: () => handleCreateDispatcher(),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [DISPATCHERS_QUERY_KEY] });
      onClose();
      toast({
        description: "Sistema de alerta criado com sucesso",
      });
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Erro ao criar sistema de alerta",
        description: "Um erro inesperado ocorreu",
      });
    },
  });

  async function handleCreateDispatcher() {
    const dispatcherError = canCreateNewDispatcher(dispatcher);
    const hasNoError = dispatcherError.error === DispatcherFormError.noErrors;
    console.log(dispatcher);
    if (hasNoError && (await createNewDispatcherMiddleware(dispatcher))) {
      dispatch({
        type: "RESET",
        payload: null,
      });
      return;
    }
    throw new Error("Error creating dispatcher");
  }

  function handleAnalyticTriggerTime(triggerTime: Dayjs | null) {
    dispatch({
      type: "SET_ANALYTIC_TRIGGER_TIME",
      payload: {
        triggerIndex: 0,
        triggerTime,
      },
    });
  }

  function addTelegramReceiver(chatId: string) {
    console.log(chatId);
    const newReceiver: TelegramReceiver = {
      isVideo: false,
      id: chatId,
    };
    dispatch({
      type: "ADD_RECEIVER",
      payload: {
        triggerIndex: 0,
        receiver: newReceiver,
      },
    });
  }

  function addWhatsappGroupReceiver(groupId: string) {
    const newReceiver: WhatsAppGroupReceiver = {
      isVideo: false,
      groupId: groupId,
    };
    dispatch({
      type: "ADD_RECEIVER",
      payload: {
        triggerIndex: 0,
        receiver: newReceiver,
      },
    });
  }

  function addUserReceiver(userInfo: string) {
    const [userId, name] = userInfo.split("/");
    const newReceiver: UserReceiver = {
      id: userId,
      name,
    };
    dispatch({
      type: "ADD_RECEIVER",
      payload: {
        triggerIndex: 0,
        receiver: newReceiver,
      },
    });
  }

  function handleAddReceiver(receiverType: ReceiverType, receiverInfo: string) {
    switch (receiverType) {
      case ReceiverType.whatsapp:
        return addWhatsappGroupReceiver(receiverInfo);
      case ReceiverType.telegram:
        return addTelegramReceiver(receiverInfo);
      case ReceiverType.user:
        return addUserReceiver(receiverInfo);
      case null:
        return toast({
          variant: "destructive",
          description: "Você deve selecionar um tipo de destinatário",
        });
      default:
        throw new Error("Receiver type not defined");
    }
  }

  function handleRemoveReceiver(receiverIndex: number) {
    dispatch({
      type: "REMOVE_RECEIVER",
      payload: { triggerIndex: 0, receiverIndex },
    });
  }

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={openNewDispatcher}
      onClose={onClose}
    >
      <DialogTitle className="flex items-center justify-between">
        <p className="text-lg">Novo sistema de alerta</p>
        <IconButton onClick={onClose} color="inherit">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="pt-2 flex flex-col space-y-3">
        <TextField
          value={dispatcher.analyticType}
          size="small"
          label="Tipo"
          disabled
          select
        >
          <MenuItem value="Intrusão">Intrusão</MenuItem>
          <MenuItem value="Evasão">Evasão</MenuItem>
          <MenuItem value="Aglomeração">Aglomeração</MenuItem>
          <MenuItem value="Permanência">Permanência</MenuItem>
          <MenuItem value="Inatividade">Inatividade</MenuItem>
          <MenuItem value="Detecção de Veículo">Detecção de Veículo</MenuItem>
          <MenuItem value="Ausência de Veículo">Ausência de Veículo</MenuItem>
          <MenuItem value="Congestionamento">Congestionamento</MenuItem>
          <MenuItem value="Pânico">Pânico</MenuItem>
          <MenuItem value="Detecção de Animais">Detecção de Animais</MenuItem>
          <MenuItem value="Radar">Radar</MenuItem>
          <MenuItem value="Lpr">LPR</MenuItem>
        </TextField>
        <TextField
          size="small"
          label="Nome"
          value={dispatcher.name}
          onChange={(ev) =>
            dispatch({ type: "SET_NAME", payload: ev.target.value })
          }
        />
        {analyticType !== "Intrusão" && (
          <TimePicker
            format="mm:ss"
            label="Tempo para acionamento do analítico"
            slotProps={{ textField: { size: "small" } }}
            value={dispatcher.triggers[0].analyticTriggerTime}
            onChange={(value) => handleAnalyticTriggerTime(value)}
          />
        )}

        <div>Destinatários</div>
        <div className="flex items-center justify-between space-x-1 pb-2 border-b border-[rgba(0,0,0,0.3)]">
          <SimpleReceiverField onAddReceiver={handleAddReceiver} />
        </div>
        {dispatcher.triggers[0].receivers.map((receiver, idx) => (
          <SimpleReceiver
            key={idx}
            receiver={receiver}
            onRemove={() => handleRemoveReceiver(idx)}
          />
        ))}
        <LoadingButton
          disabled={dispatcher.triggers[0].receivers.length === 0}
          variant="contained"
          loading={isPending}
          startIcon={<Save />}
          onClick={() => mutate()}
        >
          Criar sistema de alerta
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
}
