import { ScheduleTime } from "../interfaces/heimdallCamera";
import { updateAnalytic } from "api/analytics";
import { AnalyticForm, CreateAnalytic } from "../interfaces/analytics";
import { putAlertAssociation } from "api/alertSystem";

export function getHHmmssFromSeconds(value: number) {
  const rawHours = value >= 3600 ? value / 3600 : 0;
  const rawMinutes = value >= 60 ? value / 60 : 0;
  const rawSeconds =
    value < 60 ? value : value < 3600 ? value % 60 : value % 3600;

  const hours = rawHours;
  const minutes = rawMinutes >= 60 ? rawMinutes - 60 : rawMinutes;
  const seconds = rawSeconds >= 60 ? rawSeconds % 60 : rawSeconds;

  return {
    hours: Math.floor(hours),
    minutes: Math.floor(minutes),
    seconds: Math.floor(seconds),
  };
}

export function canUpdateAnalytic(
  analytic: AnalyticForm,
  {
    checked,
    setRegionError,
    handleDeleteRegion,
    setTimeScheduleError,
    setAlertIntervalError,
  }: {
    checked: boolean[];
    setRegionError?: (value: boolean) => void;
    setAlertIntervalError?: (value: boolean) => void;
    setTimeScheduleError?: (value: boolean) => void;
    handleDeleteRegion?: (index: number) => void;
  }
) {
  if (analytic.analytic_type === "Radar") {
    return (
      !!analytic.radar?.target_regions &&
      analytic.radar.distance !== null &&
      analytic.radar.max_velocity > 0
    );
  }
  const regionMonitorAux = [...analytic.region_monitor];
  // Clearing empty regions
  for (let index = regionMonitorAux.length - 1; index >= 0; index--) {
    const region = regionMonitorAux[index];

    if (region.length === 0) {
      regionMonitorAux.splice(index, 1);
      if (index > 0) handleDeleteRegion?.(index);
    }
  }

  if (regionMonitorAux.length === 0 || regionMonitorAux[0].length === 0) {
    setRegionError?.(true);
  }

  // Early return, in case the user is just deactivating the analytic.
  // In this case, we don't need to check if the analytic is well formatted
  if (analytic.activated) return true;

  if (
    !analytic.service_sleep_duration ||
    (analytic.service_sleep_duration.minute() === 0 &&
      analytic.service_sleep_duration.second() === 0)
  ) {
    setAlertIntervalError?.(true);
    return false;
  }

  // Checking if all time fields are empty
  let allTimeNull = true;

  // FIXME:
  Object.values(analytic.schedule_time).forEach((timeRanges, dayIdx) => {
    for (let index = 0; index < timeRanges.length; index++) {
      const timeRange = timeRanges[index];
      if (timeRange.initTime && timeRange.stopTime && checked[dayIdx + 1]) {
        allTimeNull = false;
        break;
      }
    }
  });

  if (allTimeNull || !checked.includes(true)) setTimeScheduleError?.(true);

  if (
    analytic.region_monitor.length === 0 ||
    analytic.region_monitor[0].length === 0 ||
    allTimeNull ||
    !checked.includes(true)
  ) {
    return false;
  }

  return true;
}

export function translateDayToPortuguese(day: string): string {
  switch (day) {
    case "monday":
      return "Segunda";
    case "tuesday":
      return "Terça";
    case "wednesday":
      return "Quarta";
    case "thursday":
      return "Quinta";
    case "friday":
      return "Sexta";
    case "saturday":
      return "Sábado";
    case "sunday":
      return "Domingo";
    default:
      throw new Error("Invalid day of the week");
  }
}

export function normalizeAnalyticRegion(
  denormalizedRegions: [number, number][][],
  canvasSetting: {
    width: number;
    height: number;
  }
) {
  console.log("normalizing regions");
  const normalizedRegions: [number, number][][] = [];

  denormalizedRegions.forEach((denormalizedRegion) => {
    const normalizedRegion: [number, number][] = denormalizedRegion.map(
      (vertex) => [
        vertex[0] / canvasSetting.width,
        vertex[1] / canvasSetting.height,
      ]
    );
    normalizedRegions.push(normalizedRegion);
  });

  return normalizedRegions;
}

interface UpdateAnalyticMiddlewareParams {
  analytic: AnalyticForm;
  checkedDaysOfWeek: boolean[];
}
export async function updateAnalyticMiddleware({
  checkedDaysOfWeek,
  analytic,
}: UpdateAnalyticMiddlewareParams) {
  if (analytic.analytic_type === "Radar") {
    throw new Error(
      'This method cannot be called when analytic type is "Radar"'
    );
  }
  // Formatting time range fields for submit
  // const scheduleTime = formatStartAndEndTime(startTime, endTime);
  const formattedScheduleTime: ScheduleTime = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  // Removing null fields
  Object.keys(analytic.schedule_time).forEach((dayOfWeek) => {
    analytic.schedule_time[dayOfWeek as keyof ScheduleTime].forEach(
      (timeRange) => {
        if (timeRange.initTime !== null && timeRange.stopTime !== null)
          formattedScheduleTime[dayOfWeek as keyof ScheduleTime].push({
            init_time: timeRange.initTime.format("HH:mm"),
            stop_time: timeRange.stopTime.format("HH:mm"),
          });
      }
    );
    // Avoiding sending empty arrays
    if (formattedScheduleTime[dayOfWeek as keyof ScheduleTime].length === 0)
      formattedScheduleTime[dayOfWeek as keyof ScheduleTime].push({
        init_time: null,
        stop_time: null,
      });
  });

  // Removing all unchecked fields
  checkedDaysOfWeek.forEach((check, index) => {
    if (index === 1 && !check)
      formattedScheduleTime["monday"] = [{ init_time: null, stop_time: null }];
    else if (index === 2 && !check)
      formattedScheduleTime["tuesday"] = [{ init_time: null, stop_time: null }];
    else if (index === 3 && !check)
      formattedScheduleTime["wednesday"] = [
        { init_time: null, stop_time: null },
      ];
    else if (index === 4 && !check)
      formattedScheduleTime["thursday"] = [
        { init_time: null, stop_time: null },
      ];
    else if (index === 5 && !check)
      formattedScheduleTime["friday"] = [{ init_time: null, stop_time: null }];
    else if (index === 6 && !check)
      formattedScheduleTime["saturday"] = [
        { init_time: null, stop_time: null },
      ];
    else if (index === 7 && !check)
      formattedScheduleTime["sunday"] = [{ init_time: null, stop_time: null }];
  });

  // Building request data
  const data: Partial<
    Omit<CreateAnalytic, "region_monitor"> & { region_monitor: string }
  > = {
    activated: analytic.activated ? false : true,
    similarity: analytic.similarity,
    region_monitor: JSON.stringify(analytic.region_monitor),
    schedule_time: JSON.stringify(formattedScheduleTime),
    service_sleep_duration: analytic.service_sleep_duration!.format("mm:ss"),
    integration_params: analytic.integration_params,
  };

  if (analytic.analytic_type === "Evasão") {
    data.target_detection_number = analytic.evasion!.target_detection_number;
  }
  if (analytic.analytic_type === "Aglomeração")
    data.target_detection_number =
      analytic.agglomeration!.target_detection_number;
  if (analytic.analytic_type === "Congestionamento") {
    data.target_detection_number =
      analytic.vehicle_agglomeration!.target_detection_number;
  }
  if (analytic.analytic_type === "Inatividade") {
    // Slider vai de 0 a 100, mas backend espera string de 0 a 10.
    data.movement_threshold = (
      analytic.inactivity!.movement_threshold / 10
    ).toString();
  }

  const alertSystemResponse = putAlertAssociation(
    analytic.alerts_analytics[0].alert_system_analytic_id!,
    {
      alert_system_id: analytic.alerts_analytics[0].alert_system_id,
    }
  );

  const analyticResponse = updateAnalytic(
    data,
    analytic.analytic_type,
    analytic.id
  );

  return await Promise.all([alertSystemResponse, analyticResponse])
    .then(() => true)
    .catch(() => false);
}

export function configureAnalyticReducer(
  analytic: AnalyticForm,
  action: any
): AnalyticForm {
  console.log("Dispatching", action.type);
  switch (action.type) {
    case "activeStatus": {
      return { ...analytic, activated: action.payload };
    }
    case "pauseStatus": {
      return { ...analytic, pause: action.payload };
    }
    case "addedRegion": {
      const newRegions = [...analytic.region_monitor];
      newRegions.push([]);
      return { ...analytic, region_monitor: newRegions };
    }
    case "drawedRegion": {
      const newRegions = [...analytic.region_monitor];
      newRegions[action.index] = action.polygon;
      return { ...analytic, region_monitor: newRegions };
    }
    case "deletedRegion": {
      const newRegions = [...analytic.region_monitor];
      newRegions.splice(action.index, 1);
      return { ...analytic, region_monitor: newRegions };
    }
    case "resetRegions": {
      return { ...analytic, region_monitor: action.payload };
    }
    case "addedAllTimeSchedule": {
      const newTimeSchedule = { ...analytic.schedule_time };
      Object.values(newTimeSchedule).forEach((timeRange) =>
        timeRange.push({ initTime: null, stopTime: null })
      );
      return { ...analytic, schedule_time: newTimeSchedule };
    }
    case "addedTimeSchedule": {
      const newTimeSchedule = { ...analytic.schedule_time };
      newTimeSchedule[action.dayOfWeek as keyof ScheduleTime].push({
        initTime: null,
        stopTime: null,
      });
      return { ...analytic, schedule_time: newTimeSchedule };
    }
    case "changedAllTimeSchedule": {
      const newTimeSchedule = { ...analytic.schedule_time };
      Object.values(newTimeSchedule).forEach((timeRange) => {
        if (action.index < timeRange.length) {
          timeRange[action.index] = {
            initTime: action.timeRange[0],
            stopTime: action.timeRange[1],
          };
        }
      });
      return { ...analytic, schedule_time: newTimeSchedule };
    }
    case "changedTimeSchedule": {
      const newTimeSchedule = { ...analytic.schedule_time };
      newTimeSchedule[action.dayOfWeek as keyof ScheduleTime][action.index] = {
        initTime: action.timeRange[0],
        stopTime: action.timeRange[1],
      };
      return { ...analytic, schedule_time: newTimeSchedule };
    }
    case "deletedAllTimeSchedule": {
      const newTimeSchedule = { ...analytic.schedule_time };
      const timeRanges = Object.values(newTimeSchedule);
      timeRanges.forEach((timeRange) => {
        if (action.index < timeRange.length) {
          timeRange.splice(action.index);
        }
      });

      return { ...analytic, schedule_time: newTimeSchedule };
    }
    case "deletedTimeSchedule": {
      const newTimeSchedule = { ...analytic.schedule_time };
      newTimeSchedule[action.dayOfWeek as keyof ScheduleTime].splice(
        action.index,
        1
      );
      return { ...analytic, schedule_time: newTimeSchedule };
    }
    case "changedTargetDetectionNumber": {
      if (!!analytic.evasion) {
        return {
          ...analytic,
          evasion: {
            ...analytic.evasion,
            target_detection_number: action.payload,
          },
        };
      }
      if (!!analytic.agglomeration) {
        return {
          ...analytic,
          agglomeration: {
            ...analytic.agglomeration,
            target_detection_number: action.payload,
          },
        };
      }
      if (!!analytic.vehicle_agglomeration) {
        return {
          ...analytic,
          vehicle_agglomeration: {
            ...analytic.vehicle_agglomeration!,
            target_detection_number: action.payload,
          },
        };
      }
      throw new Error(
        'Analytic type does not support "target detection number"'
      );
    }
    case "changedAlertInterval": {
      return {
        ...analytic,
        service_sleep_duration: action.payload,
      };
    }
    case "changedSimilarity": {
      return { ...analytic, similarity: action.payload };
    }
    case "changedMovementThreshold": {
      return {
        ...analytic,
        inactivity: {
          ...analytic.inactivity!,
          movement_threshold: action.payload,
        },
      };
    }
    case "changedDispatcher": {
      return {
        ...analytic,
        alerts_analytics: [
          { ...analytic.alerts_analytics![0], alert_system_id: action.payload },
          ...analytic.alerts_analytics!,
        ],
      };
    }
    case "changedMoniSector": {
      const newMoniIntegrationParam = {
        moniIntegrationId: action.payload.moniIntegrationId,
        sectorId: action.payload.sectorId,
      };
      if (!!analytic.integration_params) {
        const newMoniIntegrations = [...analytic.integration_params.moni];
        const prevMoniIntegrationIdx = newMoniIntegrations.findIndex(
          (value) =>
            value.moniIntegrationId ===
            newMoniIntegrationParam.moniIntegrationId
        );
        newMoniIntegrations[prevMoniIntegrationIdx] = newMoniIntegrationParam;
        return {
          ...analytic,
          integration_params: {
            ...analytic.integration_params,
            moni: newMoniIntegrations,
          },
        };
      }
      return {
        ...analytic,
        integration_params: {
          moni: [newMoniIntegrationParam],
          gear: [],
        },
      };
    }
    default:
      throw new Error("Action type not defined!");
  }
}
