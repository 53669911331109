import React from "react";
import {
  CameraGroup,
  CarSearch,
  MoniIntegration,
  Statistics,
} from "components/icons/thirdPartyIcons";
import {
  GroupAdd,
  ErrorOutlineOutlined,
  RecordVoiceOver,
  Speed,
  SettingsInputComponent,
  Palette,
  Apartment,
  PersonSearch,
  DesktopWindowsOutlined
} from "@mui/icons-material";
import styles1 from "./Sidebar.module.css";
import styles2 from "./DrawerSidebar/SidebarForDrawer.module.css";
import { Video } from "lucide-react";
import { cn } from "lib/classNames";

export function getMatchingIcon(routePath: string, isDrawer?: boolean) {
  const styles = isDrawer ? styles2 : styles1;

  switch (routePath) {
    case "machines":
      return <DesktopWindowsOutlined className={styles.navLinkIcon} />
    case "operators":
      return <GroupAdd className={styles.navLinkIcon} />;
    case "cameras":
      return <Video className={cn(styles.navLinkIcon, "stroke-none")} />;
    case "camera-groups":
      return (
        <CameraGroup width={24} height={24} className={styles.navLinkIcon} />
      );
    case "log-radar":
      return <Speed className={styles.navLinkIcon} />;
    case "forense":
      return <PersonSearch className={styles.navLinkIcon} />;
    case "lpr":
      return (
        <CarSearch width={24} height={24} className={styles.navLinkIcon} />
      );
    case "dispatchers":
      return <RecordVoiceOver className={styles.navLinkIcon} />;
    case "vms":
      return (
        <svg className={styles.navLinkIcon} height="24" width="27.5">
          <text
            x="0"
            y="18"
            fontStyle="bold"
            style={{ fontWeight: "700", fontSize: "13px" }}
          >
            VMS
          </text>
        </svg>
      );
    case "moni":
      return (
        <MoniIntegration
          className={styles.navLinkIcon}
          height="24"
          width="30"
        />
      );
    case "io-devices":
      return <SettingsInputComponent className={styles.navLinkIcon} />;
    case "panic":
      return <ErrorOutlineOutlined className={styles.navLinkIcon} />;
    case "themes":
      return <Palette className={styles.navLinkIcon} />;
    case "companies":
      return <Apartment className={styles.navLinkIcon} />;
    default:
      return (
        <Statistics width={22} height={22} className={styles.navLinkIcon} />
      );
  }
}
