import { BackendResponsePagination } from "interfaces/generic";
import { SharedCameraRelation } from "interfaces/sharedCameras";
import axiosRoot from "./rootConfig";

interface SharedCamerasParams {
  page: number;
  pageSize: number;
  filters?: {
    cameraId?: number;
    receiverId?: number;
    status?: "active" | "pending";
  };
}

/**
 *
 * @returns A list of all the shared cameras relations in the server
 */
export async function getAllSharedCamerasRelations(
  params: SharedCamerasParams = {
    page: 1,
    pageSize: 100000,
  }
) {
  let requestUrl = "/api/camera_share?page=" + params.page;

  if (!!params.filters?.cameraId) {
    requestUrl += "&filter%5Bcamera_id%5D=" + params.filters.cameraId;
  }
  if (!!params.filters?.receiverId) {
    requestUrl += "&filter%5Breceiver_id%5D=" + params.filters.receiverId;
  }
  if (!!params.filters?.status) {
    requestUrl += "&filter%5Bstatus%5D=" + params.filters.status;
  }

  return await axiosRoot
    .get<BackendResponsePagination<SharedCameraRelation>>(requestUrl, {
      headers: {
        paginate: params.pageSize,
      },
    })
    .then((res) => res.data);
}

export async function getAllCamerasSharedWithMe(
  params: SharedCamerasParams = {
    page: 1,
    pageSize: 100000,
  }
) {
  let requestUrl = "/api/camera_share_others?page=" + params.page;

  if (!!params.filters?.cameraId) {
    requestUrl += "&filter%5Bcamera_id%5D=" + params.filters.cameraId;
  }
  if (!!params.filters?.receiverId) {
    requestUrl += "&filter%5Breceiver_id%5D=" + params.filters.receiverId;
  }
  if (!!params.filters?.status) {
    requestUrl += "&filter%5Bstatus%5D=" + params.filters.status;
  }

  return await axiosRoot
    .get<BackendResponsePagination<SharedCameraRelation>>(requestUrl, {
      headers: {
        paginate: params.pageSize,
      },
    })
    .then((res) => res.data);
}

export async function shareCamera({
  camera_id,
  receiver_id,
}: {
  camera_id: number;
  receiver_id: number;
}) {
  return await axiosRoot
    .post("/api/camera_share", { camera_id, receiver_id })
    .then((res) => res.data);
}

export async function acceptCameraSharing(
  shareRelationId: number,
  cameraId: number
) {
  return await axiosRoot
    .put("/api/camera_share/" + shareRelationId, {
      camera_id: cameraId,
      status: "active",
    })
    .then((res) => res.data);
}

/**
 * @deprecated Should use `deleteSharedCameraRelation` instead
 * @param shareRelationId
 * @param cameraId
 * @returns
 */
export async function declineCameraSharing(
  shareRelationId: number,
  cameraId: number
) {
  return await axiosRoot
    .put("/api/camera_share/" + shareRelationId, {
      camera_id: cameraId,
      status: "reject",
    })
    .then((res) => res.data);
}

export async function deleteSharedCameraRelation(relationId: number) {
  return await axiosRoot
    .delete("/api/camera_share/" + relationId)
    .then((res) => res.data);
}
