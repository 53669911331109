import { Fullscreen } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { isMobile } from "index";
import { cn } from "lib/classNames";
import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";

const TIMEOUT_FOR_CONTROLS = 4.5 * 1000;

interface CustomHlsPlayerProps extends React.ComponentProps<"video"> {
  containerProps?: React.ComponentProps<"div">;
}

export default function CustomHlsPlayer({
  containerProps,
  ...props
}: CustomHlsPlayerProps) {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [showControls, setShowControls] = useState(false);
  const [hlsController, setHlsController] = useState<Hls | null>(null);
  const [controlsTimerId, setControlsTimerId] = useState<NodeJS.Timeout>();

  // Handles the HLS player
  useEffect(() => {
    const myPlayerRef = playerRef.current;

    if (myPlayerRef && Hls.isSupported()) {
      myPlayerRef.addEventListener("waiting", () => {
        setIsLoading(true);
      });
      myPlayerRef.addEventListener("canplay", () => {
        setIsLoading(false);
      });

      // If you are using the ESM version of the library (hls.mjs), you
      // should specify the "workerPath" config option here if you want
      // web workers to be used. Note that bundlers (such as webpack)
      // will likely use the ESM version by default.
      var hls = new Hls({
        enableWorker: true,
        workerPath: "/hls.worker.js",
      });
      setHlsController(hls);

      // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log("video and hls.js are now bound together !");
      });
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
          "manifest loaded, found " + data.levels.length + " quality level"
        );
        setError("");
        if (props.autoPlay) {
          myPlayerRef.muted = true;
          myPlayerRef
            .play()
            .then(() => {})
            .catch((err) => console.log(err));
        }
      });
      hls.on(Hls.Events.ERROR, function (event, data) {
        var errorType = data.type;
        // var errorDetails = data.details;
        var errorFatal = data.fatal;

        console.log(data.details, data.error);

        if (errorFatal) {
          setError(data.error.message);
          setIsLoading(false);
        }
        if (errorType === Hls.ErrorTypes.MEDIA_ERROR && errorFatal) {
          hls.recoverMediaError();
        }
        // switch (data.details) {
        //   case Hls.ErrorDetails.FRAG_LOAD_ERROR:
        //     // ....
        //     break;
        //   default:
        //     break;
        // }
      });
      if (props.src) {
        hls.loadSource(props.src);
        // bind them together
        hls.attachMedia(myPlayerRef);
      }
    }

    return () => {
      hls.destroy();
      myPlayerRef?.removeEventListener("waiting", () => {
        setIsLoading(true);
      });
      myPlayerRef?.removeEventListener("canplay", () => {
        setIsLoading(false);
      });
    };
  }, [props.src, props.autoPlay]);

  function handleRetry() {
    if (props.src && hlsController) {
      hlsController.loadSource(props.src);
      // bind them together
      // hlsController.attachMedia(playerRef.current!);
    }
  }

  function setTimerForControls() {
    clearTimeout(controlsTimerId);
    const id = setTimeout(() => {
      setShowControls(false);
    }, TIMEOUT_FOR_CONTROLS);
    setControlsTimerId(id);
  }

  function handleMouseOut() {
    if (!isMobile) setShowControls(false);
  }

  if (!Hls.isSupported()) {
    return (
      <div>
        <p>Seu navegador não suporta streaming HLS</p>
      </div>
    );
  }

  return (
    <div {...containerProps}>
      <video ref={playerRef} {...props} />
      <div
        onMouseMove={() => {
          setShowControls(true);
          setTimerForControls();
        }}
        onClick={() => {
          setShowControls(true);
          setTimerForControls();
        }}
        onMouseOut={handleMouseOut}
        className="absolute bottom-0 left-0 flex items-end justify-between w-full h-full z-20 bg-blend-multiply"
      >
        {isLoading && (
          <div className="absolute w-10 h-14 inset-0 m-auto">
            <CircularProgress />
          </div>
        )}
        {error !== "" ? (
          <div className="absolute w-60 h-32 inset-0 m-auto flex flex-col justify-center">
            <p>{error}</p>
            <Button fullWidth color="primary" onClick={handleRetry}>
              Tentar novamente
            </Button>
          </div>
        ) : (
          <div
            className={cn(
              "flex items-center justify-between w-full bg-gradient-to-t from-[#00000050] to-transparent z-0 pt-3",
              showControls ? "fade-in-0" : "fade-out-0 hidden"
            )}
          >
            <div className="flex items-center px-2 space-x-2 rounded-sm">
              <div className="rounded-full bg-red-600 size-2" />
              <span className="text-white font-medium">AO VIVO</span>
            </div>
            <IconButton
              className="text-white"
              id="fullscreenIconButton"
              onClick={() => playerRef.current?.requestFullscreen()}
            >
              <Fullscreen fontSize="large" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}
