import axiosRoot, { apiConfig } from "./rootConfig";
import {
  BackendResponsePagination,
  HeimdallInstanceUser,
  HeimdallUser,
} from "interfaces/generic";
import { NewEmail } from "components/Modals/ChangeEmailDialog";
import { NewPassword } from "components/Modals/ChangePasswordDialog";
import { SimpleDeviceInfo } from "lib/deviceInfo";
import { USERS_FROM_COMPANY_AND_SHARED_CAMERAS } from "constants/apiQueryKeys";

export async function getUser() {
  return await axiosRoot
    .get<HeimdallUser>("/api/user_profile")
    .then(function (response) {
      return response.data;
    });
}

export async function sendNewPasswordRequest(email: string) {
  return await axiosRoot
    .post("/api/forgot?app_url=" + apiConfig.baseUrl, { email })
    .then(function (response) {
      return response;
    })
    .catch((error) => error);
}

export async function resetPassword(data: {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}) {
  return await axiosRoot.post("/api/reset", data).then(function (response) {
    return response;
  });
}

export async function changePassword(userId: number, data: NewPassword) {
  return await axiosRoot
    .put("/api/user_company_change_password/" + userId, data)
    .then((res) => res.data);
}

export async function changeEmail(userId: number, data: NewEmail) {
  return await axiosRoot
    .put("/api/user_company_change_email/" + userId, data)
    .then((res) => res.data);
}

export async function registerDevice(
  userId: number,
  data: {
    device_id: string;
    device_token?: string;
    device_info: SimpleDeviceInfo;
  }
) {
  const requestData = new FormData();

  requestData.append("device_id", data.device_id);
  if (data.device_token) requestData.append("device_token", data.device_token);
  requestData.append("device_info", JSON.stringify(data.device_info));

  return await axiosRoot
    .post("/api/user/store_device_token/" + userId, requestData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
}

export async function removeConnectedDevice(userId: number, device_id: string) {
  return await axiosRoot
    .delete(`/api/user/remove_device_token/${userId}?device_id=${device_id}`)
    .then((res) => res.data);
}

interface InstanceUsersParams {
  page: number;
  pageSize: number;
}

/**
 * Gets all users registered in the current machine (instance), from every
 * company except for the current user's company
 */
export async function getAllInstanceUsers(
  params: InstanceUsersParams = {
    page: 1,
    pageSize: 100000,
  }
) {
  return await axiosRoot
    .get<BackendResponsePagination<HeimdallInstanceUser>>(
      "/api/camera_share_users?page=" + params.page,
      {
        headers: {
          paginate: params.pageSize,
        },
      }
    )
    .then((res) => res.data);
}

interface CustomBackendResponsePagination<T>
  extends BackendResponsePagination<T> {
  data_shared: T[];
}

interface SharedCameraUser
  extends Pick<
    HeimdallUser,
    | "id"
    | "name"
    | "role"
    | "status"
    | "username"
    | "company"
    | "created_at"
    | "email"
  > {
  status_shared: boolean;
}

export async function getUsersFromCompanyAndSharedCameras() {
  return await axiosRoot
    .get<
      CustomBackendResponsePagination<SharedCameraUser>
    >(`${USERS_FROM_COMPANY_AND_SHARED_CAMERAS}`)
    .then((res) => res.data);
}
