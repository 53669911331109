import { Refresh } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import React from "react";

export function RefreshContainer({
  buttonClassname = "w-5 h-5",
  isStale,
  isRefreshingFrame,
  disableRefresh,
  onRefresh,
  children,
}: {
  buttonClassname?: React.ComponentProps<"div">["className"];
  isStale: boolean;
  disableRefresh?: boolean;
  isRefreshingFrame: boolean;
  onRefresh: () => Promise<void>;
  children: React.ReactNode;
}) {
  return (
    <div className="relative">
      {children}
      {isStale && !disableRefresh && (
        <div
          className={`size flex items-center justify-center absolute top-[1px] right-[1px] rounded-bl-md rounded-tr-md bg-[#00000055] cursor-pointer ${buttonClassname}`}
          onClick={(ev) => {
            ev.stopPropagation();
            onRefresh();
          }}
        >
          <Refresh fontSize="small" sx={{ fill: "#dddddd" }} />
        </div>
      )}
      {isRefreshingFrame && <LinearProgress />}
    </div>
  );
}
