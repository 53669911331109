import { ScreenOrientation } from "@capacitor/screen-orientation";
import { isPlatform } from "@ionic/react";
import { PlayArrow } from "@mui/icons-material";
import { Button, IconButton, Skeleton, useMediaQuery } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getAllCamerasSharedWithMe } from "api/cameraSharing";
import { getCachedCameraFrame, getCameraFrame } from "api/flaskApiMethods";
import { apiConfig } from "api/rootConfig";
import { RefreshContainer } from "components/cameras-grid/RefreshContainer";
import CustomHlsPlayer from "components/CustomHlsPlayer/CustomHlsPlayer";
import MainContainer from "components/MainContainer/MainContainer";
import { SHARED_CAMERAS_RELATIONS_QUERY_KEY } from "constants/apiQueryKeys";
import { AnalyticType } from "interfaces/analytics";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const staleTime = 5 * 60 * 1000; // five minutes
const initialIsStaleDefinitionDelay = 2 * 1000; // 2 seconds

export default function SharedCameraDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    data: camera,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: [
      SHARED_CAMERAS_RELATIONS_QUERY_KEY,
      { page: 1, pageSize: 10, filters: { cameraId: params.id } },
    ],
    queryFn: () =>
      getAllCamerasSharedWithMe({
        page: 1,
        pageSize: 10,
        filters: { cameraId: parseInt(params.id!) },
      }),
  });
  const [showVideo, setShowVideo] = useState(false);
  const isXxs = useMediaQuery("(max-width: 450px)");
  const [frameError, setFrameError] = useState(false);
  const [hasFreshFrame, setHasFreshFrame] = useState(false);
  const [initialIsStale, setInitialIsStale] = useState(false);
  const {
    data: freshCameraFrame,
    isFetching: isRefreshingFrame,
    isStale,
    refetch: refetchFrame,
  } = useQuery({
    queryKey: ["camera-frame", camera?.data[0].camera_shared.rtsp],
    queryFn: () => getCameraFrame(camera!.data[0].camera_shared.rtsp, 500),
    enabled: false,
    staleTime,
  });

  const cameraFrameSource = useMemo(() => {
    if (!camera) return "";
    if (hasFreshFrame && !!freshCameraFrame) {
      setFrameError(false);
      return `data:image/jpeg;base64,${freshCameraFrame.img}`;
    }
    if (isXxs) {
      return getCachedCameraFrame(camera!.data[0].camera_shared.rtsp, 425);
    }
    return getCachedCameraFrame(camera!.data[0].camera_shared.rtsp);
  }, [camera, isXxs, hasFreshFrame, freshCameraFrame]);

  useEffect(() => {
    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      ScreenOrientation.unlock();
    }
    setTimeout(() => {
      setInitialIsStale(true);
    }, initialIsStaleDefinitionDelay);

    return () => {
      ScreenOrientation.lock({ orientation: "portrait" });
    };
  }, []);

  useEffect(() => {
    if (!!freshCameraFrame) {
      setHasFreshFrame(true);
    }
  }, [freshCameraFrame]);

  async function refreshCameraFrame() {
    if (initialIsStale) {
      await refetchFrame();
      setInitialIsStale(false);
      return;
    }
    if (isStale) await refetchFrame();
  }

  function handleConfigureAnalytic(analyticType: AnalyticType) {
    navigate(`configure-analytics/${analyticType}`);
  }

  if (!!error) {
    return (
      <MainContainer returnAction title="Visualização câmera">
        <p>Erro na obtenção de dados da câmera</p>
        <Button onClick={() => refetch()} variant="outlined">
          Tentar novamente
        </Button>
      </MainContainer>
    );
  }

  if (isLoading) {
    return (
      <MainContainer returnAction title="Visualização câmera">
        <div className="space-y-3">
          <Skeleton className="w-full h-48" variant="rounded"></Skeleton>
          <Skeleton className="w-full h-24" variant="rounded"></Skeleton>
          <Skeleton className="w-full h-24" variant="rounded"></Skeleton>
          <Skeleton className="w-full h-24" variant="rounded"></Skeleton>
          <Skeleton className="w-full h-24" variant="rounded"></Skeleton>
        </div>
      </MainContainer>
    );
  }

  return (
    <MainContainer returnAction title={camera!.data[0].camera_shared.name}>
      <RefreshContainer
        isStale={(isStale && !isRefreshingFrame) || initialIsStale}
        onRefresh={refreshCameraFrame}
        isRefreshingFrame={isRefreshingFrame}
        buttonClassname="w-7 h-7"
      >
        {showVideo ? (
          <CustomHlsPlayer
            className="w-full h-48 md:h-96"
            src={`${apiConfig.baseUrlNoPort}/media/${camera?.data[0].camera_shared.camera_id}/index.m3u8`}
          />
        ) : (
          <div className="relative">
            <img
              src={cameraFrameSource}
              className="h-48"
              width="100%"
              alt={`quadro da câmera ${camera!.data[0].camera_shared.name}`}
            />
            <div className="absolute inset-0 flex items-center justify-center z-30 opacity-80">
              <div className="bg-black rounded-full">
                <IconButton
                  size="large"
                  color="inherit"
                  sx={{ zIndex: 30 }}
                  onClick={() => setShowVideo(true)}
                >
                  <PlayArrow sx={{ fontSize: "32px", fill: "white" }} />
                </IconButton>
              </div>
            </div>
          </div>
        )}
      </RefreshContainer>
    </MainContainer>
  );
}
