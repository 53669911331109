import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { apiConfig } from "api/rootConfig";
import { HeimdallCamera } from "interfaces/heimdallCamera";

export default function AdvancedMarkerWithInfoWindow({
  camera,
}: {
  camera: Pick<
    HeimdallCamera,
    "id" | "name" | "camera_address" | "video_media_mtx"
  >;
}) {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <div>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(true)}
        position={{
          lat: parseFloat(camera.camera_address.coordinate!.latitude),
          lng: parseFloat(camera.camera_address.coordinate!.longitude),
        }}
        title={`Marcador da câmera ${camera.name}`}
      />
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={384}
          headerContent={
            <div className="-mt-2">
              <p className="font-medium">{camera.name}</p>
              <p>{camera.camera_address.address}</p>
            </div>
          }
          onCloseClick={() => setInfowindowOpen(false)}
        >
          <iframe
            key={camera.id}
            allowFullScreen
            className="w-full"
            title="Camera video"
            src={apiConfig.baseUrlNoPort + camera.video_media_mtx}
          ></iframe>
        </InfoWindow>
      )}
    </div>
  );
}
