import { useQuery } from "@tanstack/react-query";
import { getCamera } from "api/cameras";
import SelectAnalytic from "components/AddCamera/SelectAnalytic/SelectAnalytic";
import MainContainer from "components/MainContainer/MainContainer";
import { CAMERA_BY_ID_QUERY_KEY } from "constants/apiQueryKeys";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfigureAnalytic from "components/AddCamera/ConfigureAnalytic/ConfigureAnalytic";
import { AnalyticType } from "interfaces/analytics";
import {
  convertAnalyticToAnalyticForm,
  getMatchingAnalytic,
} from "lib/addCamera";
import { getCameraFrame } from "api/flaskApiMethods";
import ReturnButton from "components/ReturnButton/ReturnButton";
import { Skeleton, Step, StepLabel, Stepper } from "@mui/material";
import { addCameraSteps } from "../AddCamera/AddCamera";

export default function ConfigureAnalyticsPage() {
  const params = useParams();
  const router = useLocation();
  const navigate = useNavigate();
  const {
    data: camera,
    isLoading,
    refetch: refetchCamera,
  } = useQuery({
    queryKey: [CAMERA_BY_ID_QUERY_KEY(params.cameraId!)],
    queryFn: () => getCamera(params.cameraId!),
  });
  const {
    isStale,
    data: cameraFrame,
    isFetching: isLoadingFrame,
    refetch: refetchFrame,
  } = useQuery({
    staleTime: 5 * 60 * 1000,
    enabled: !!camera,
    queryKey: ["camera-frame", camera?.rtsp],
    queryFn: () => getCameraFrame(camera!.rtsp),
  });
  const [analyticType, setAnalyticType] = useState<AnalyticType | null>(null);
  const title = useMemo(() => {
    return (
      <div>
        <h2 className="text-lg sm:text-xl max-w-xl line-clamp-1">
          {`${
            analyticType ? analyticType : "Configurar analíticos"
          } - ${camera?.name}`}
        </h2>
        <h2 className="max-w-xl">{camera?.camera_address.address}</h2>
      </div>
    );
  }, [camera, analyticType]);
  const cameraAnalytic = useMemo(() => {
    if (!!camera && !!analyticType) {
      return convertAnalyticToAnalyticForm(
        getMatchingAnalytic(camera, analyticType),
        camera.integration_params
      );
    }
    return null;
  }, [camera, analyticType]);

  function handleReturn() {
    if (!!analyticType) {
      setAnalyticType(null);
      return;
    }

    navigate(-1);
  }

  return (
    <MainContainer
      title={title}
      returnAction={<ReturnButton onClick={handleReturn} />}
    >
      {router.state?.showStepper && (
        <>
          <Stepper activeStep={1} className="-ml-2">
            {addCameraSteps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <br />
        </>
      )}
      {!analyticType && isLoading && (
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
            <Skeleton key={value} width="100%" height={330} variant="rounded" />
          ))}
        </div>
      )}
      {!analyticType && !isLoading && (
        <SelectAnalytic
          camera={camera}
          setAnalyticType={setAnalyticType}
          onActivateAnalytic={async () => {
            await refetchCamera();
          }}
        />
      )}
      {cameraAnalytic && (
        <ConfigureAnalytic
          cameraFrame={{
            img: cameraFrame ? cameraFrame.img : "",
            isLoading: isLoadingFrame,
            refetchFrame: async () => {
              if (isStale) await refetchFrame();
            },
            isStale,
          }}
          cameraAnalytic={cameraAnalytic}
          updateCamera={() => refetchCamera()}
        />
      )}
    </MainContainer>
  );
}
