import axios from "axios";
import { RadarLog } from "interfaces/analytics";
import { ForenseFace } from "interfaces/forense";
import { apiConfig } from "./rootConfig";

// const pythonAPI = {
//   // urlLink: 'http://localhost/api/',
//   // socket: 'localhost',
//   pyToken: "0pHMplzhDuV2FW2dOFWuWMqBIoh3ARmWvuR4jMGR7xAuXFKlXKnkIF2akcCEOk96",
//   urlLink: "http://zheimdall.ddns.net/api/",
//   socket: "zheimdall.ddns.net",
// };

export async function getPDF(info: RadarLog) {
  const requestBody = {
    data: {
      id: info.id,
      url: apiConfig.baseUrl + info.url,
      velocity: info.velocity,
      cur_timestamp: info.created_at,
      license_plate: info.license_plate,
      description: info.check_description,
      camera: {
        description: `${info.camera_id} - ${info.camera.camera_name}`,
      },
    },
  };
  return await fetch(`${apiConfig.baseUrl}/api/flask-api/pdf`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
    },
    body: JSON.stringify(requestBody),
  }).then((response) => {
    if (response.status === 500) {
      throw new Error(response.statusText);
    }
    return response.blob();
  });
}

/**
 * Gets a frame from the camera's live stream as a base64 string. This is a costly
 * operation for backend, so consider using getCachedCameraFrame for large chunks.
 * @param rtsp camera's rtsp
 * @returns an object containing the camera frame in base64
 */
export async function getCameraFrame(rtsp: string, size?: number) {
  let requestURL =
    apiConfig.baseUrl +
    "/api/flask-api/get-frame?rtsp=" +
    encodeURIComponent(rtsp);
  if (size) requestURL += "&size=" + size;

  const options = {
    url: requestURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return await axios
    .get<{ img: string; msg: string; rtsp: string }>(requestURL, options)
    .then((response) => response.data);
}

/**
 * Gets a cached frame from the camera's live stream.
 * @param rtsp camera's rtsp
 * @returns the path to the image
 */
export function getCachedCameraFrame(rtsp: string, size?: number) {
  let requestURL =
    apiConfig.baseUrl +
    "/api/flask-api/get-frame-cached?rtsp=" +
    encodeURIComponent(rtsp);
  if (size) requestURL += "&size=" + size;

  return requestURL;
}

export async function getCommboxInfo(
  ip: string,
  port: number | string,
  isNiobox?: boolean
) {
  let requestURL =
    apiConfig.baseUrl +
    "/api/flask-api/device/get_commbox_info?ip=" +
    ip +
    "&port=" +
    port;

  if (isNiobox)
    requestURL =
      apiConfig.baseUrl +
      "/api/flask-api/device/get_niobox_info?ip=" +
      ip +
      "&port=" +
      port;

  return await axios
    .get(requestURL)
    .then((response) => response.data)
    .catch((error) => console.error(error));
}

/*----------------------------------------------------------
 * Test I/O Devices
 * ------------------------------------------------------------- */
export async function testCommbox(
  ip: string,
  port: number | string,
  address: number | string,
  duration: number | string
) {
  const requestURL =
    apiConfig.baseUrl +
    "/api/flask-api/device/set_commbox_output?ip=" +
    ip +
    "&port=" +
    port +
    "&address=" +
    address +
    "&time=" +
    duration;

  return await axios
    .get(requestURL)
    .then((response) => response.data)
    .catch((error) => console.error(error));
}

export async function testHornSpeaker(
  ip: string,
  port: number | string,
  audio: number | string,
  replayCount: number | string,
  password: string
) {
  const requestURL =
    apiConfig.baseUrl +
    "/api/flask-api/device/play?ip=" +
    ip +
    "&port=" +
    port +
    "&audio_id=" +
    audio +
    "&nbr_times=" +
    replayCount +
    "&pwd=" +
    password;

  const options = {
    method: "GET",
    url: requestURL,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .request(options)
    .then((response) => response.data)
    .catch((error) => console.error(error));
}

/**
 * Forense search
 */
export async function searchFace(data: FormData) {
  const requestUrl = apiConfig.baseUrlNoPort + ":5600/api/forensic/search";

  return await axios
    .post<ForenseFace[]>(requestUrl, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}
