import React from "react";
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { MonitoringMessage } from "interfaces/monitoring";
import { AnalyticType } from "interfaces/analytics";
import { useNavigate } from "react-router-dom";
import { Check, Place, Schedule, Videocam } from "@mui/icons-material";
import dayjs from "dayjs";
import { apiConfig } from "api/rootConfig";
import { LogIcon } from "lib/LogIcon";
import { getResizedImagePath } from "lib/resizedImage";

function getLogTypeFromAnalyticType(analyticType: AnalyticType) {
  switch (analyticType) {
    case "Aglomeração":
      return "agglomeration";
    case "Ausência de Veículo":
      return "vehicle-evasion";
    case "Congestionamento":
      return "vehicle-agglomeration";
    case "Detecção de Animal":
      return "animal";
    case "Detecção de Veículo":
      return "vehicle-intrusion";
    case "Evasão":
      return "evasion";
    case "Inatividade":
      return "inactivity";
    case "Intrusão":
      return "intrusion";
    case "Pânico":
      return "panic";
    case "Radar":
      return "radar";
    case "Lpr":
      return "lpr";
    case "Permanência":
      return "permanence";
    default:
      return "all";
  }
}

export function NotificationInfo({
  occurrence,
  handleClose,
  handleRemoveNotification,
}: {
  handleClose: () => void;
  handleRemoveNotification: () => void;
  occurrence: MonitoringMessage;
}) {
  const navigate = useNavigate();
  function handleClick(analyticType: AnalyticType) {
    const logType = getLogTypeFromAnalyticType(analyticType);
    navigate(`/log/${logType}`);
    handleRemoveNotification();
    handleClose();
  }
  return (
    <>
      <div className="relative flex w-96 p-2">
        <Tooltip title="Marcar como lida">
          <IconButton
            size="small"
            color="success"
            onClick={(ev) => {
              ev.stopPropagation();
              handleRemoveNotification();
            }}
            sx={{ position: "absolute", top: 0, right: 5 }}
          >
            <Check fontSize="small" />
          </IconButton>
        </Tooltip>
        <div>
          <img
            src={`${apiConfig.baseUrl}${getResizedImagePath(occurrence.url, 100)}`}
            className="rounded-md max-h-16 object-cover"
            alt="ocorrencia"
            width={100}
            height={75}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Schedule color="secondary" fontSize="small" />
            <span className="ml-2 mt-1">
              {dayjs(occurrence.created_at, "DD/MM/YYYY HH:mm:ss").format(
                "HH:mm:ss"
              )}
            </span>
          </div>
        </div>
        <div className="flex flex-col ml-3 space-y-1">
          <header className="flex">
            <LogIcon logType={occurrence.occurrence} />{" "}
            <h3 className="ml-2">{occurrence.occurrence}</h3>
          </header>
          <div className="flex">
            <div className="size-6">
              <Videocam color="secondary" />
            </div>
            <span className="ml-2 mt-1">{occurrence.camera.camera_name}</span>
          </div>
          <div className="flex">
            <div className="size-6">
              <Place color="secondary" />
            </div>
            <span className="ml-2 mt-1">
              {occurrence.camera.camera_address.address}
            </span>
          </div>
        </div>
      </div>
      <div className="pb-2 px-2">
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => handleClick(occurrence.occurrence)}
        >
          Ver detalhes
        </Button>
      </div>
      <Divider />
    </>
  );
}
