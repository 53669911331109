import { ArrowBack, Brightness1 } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";

export default function ReturnButton({ onClick, ...props }: IconButtonProps) {
  return (
    <IconButton className="relative" onClick={onClick} {...props}>
      <ArrowBack
        className="z-50"
        sx={{ fill: "var(--root-color-on-secondary)" }}
        fontSize="small"
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <Brightness1 color="secondary" fontSize="large" />
      </div>
    </IconButton>
  );
}
