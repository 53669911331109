import React from "react";
import { ConnectedDevice, HeimdallUser } from "interfaces/generic";
import { ConnectedDeviceCard } from "./ConnectedDeviceCard";

export function ConnectedDevicesList({
  connectedDevices, currentDeviceId, onRemoveDevice,
}: {
  currentDeviceId?: string;
  connectedDevices: HeimdallUser["messaging_tokens"];
  onRemoveDevice: (device: ConnectedDevice) => void;
}) {
  return (
    <div className="space-y-1">
      {connectedDevices.length === 0 && (
        <p>Nenhum dispositivo conectado.</p>
      )}
      {connectedDevices.map((device) => (
        <ConnectedDeviceCard
          device={device}
          key={device.device_id}
          onRemoveDevice={onRemoveDevice}
          isCurrentDevice={device.device_id === currentDeviceId} />
      ))}
    </div>
  );
}
