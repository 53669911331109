import {
  Dispatcher,
  DispatcherFormData,
  DispatcherListData,
  DispatcherTransiton,
  DispatcherTrigger,
  DispatcherTriggerFormData,
} from "../interfaces/alertSystem";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { createDispatcher, putDispatcher } from "api/alertSystem";
import { toast } from "components/ui/use-toast";
import { getErrorMessage } from "./apiErrorHandler";
import { AnalyticType } from "interfaces/analytics";
import { MoniIntegration } from "interfaces/moni";
import { getMoniIntegrations } from "api/moni";
import { getLogTypeFromLogName } from "./logs";
import {
  isMoniReceiver,
  isUserReceiver,
  isTelegramReceiver,
  isWhatsAppReceiver,
  isWhatsAppGroupReceiver,
  isWebhookReceiver,
  isEmailReceiver,
  NewDispatcher,
} from "interfaces/newAlertSystem";
import { getUsersFromCompanyAndSharedCameras } from "api/user";
dayjs.extend(duration);

// TODO: refactor to const as const. Check https://youtu.be/jjMbPt_H3RQ?si=VGqeVhI1QilPJaHV
export enum DispatcherFormError {
  name,
  type,
  maxCycleDuration,
  analyticTriggerTime,
  transitionHasNull,
  permanenceIntervalRule,
  noTransition,
  emptyReceivers,
  noErrors,
  noMoniIntegrationId,
}

function transitionHasNull(transition: DispatcherTransiton) {
  if (
    (!transition.maxAlerts && transition.useMaxAlerts) ||
    (!transition.permanenceInterval && transition.usePermanenceInterval)
  ) {
    if (!transition.maxAlerts) {
      transition.maxAlerts = 0;
    }
    return true;
  }

  return false;
}

function respectsTransitionRule(
  analyticTriggerTime: dayjs.Dayjs,
  transition: DispatcherTransiton
) {
  if (!transition.usePermanenceInterval) return true;
  const transitionDuration = dayjs.duration({
    seconds: transition.permanenceInterval?.second(),
    minutes: transition.permanenceInterval?.minute(),
  });
  const triggerDuration = dayjs.duration({
    seconds: analyticTriggerTime.second(),
    minutes: analyticTriggerTime.minute(),
  });

  if (transitionDuration < dayjs.duration({ minutes: 1 })) {
    return false;
  } else if (transitionDuration < triggerDuration) {
    return false;
  }

  return true;
}

export function canCreateNewDispatcher(dispatcher: NewDispatcher): {
  error: DispatcherFormError;
  triggerIndex: number;
} {
  // console.log(dispatcher);
  if (!dispatcher.name)
    return { error: DispatcherFormError.name, triggerIndex: 0 };
  // console.log("Has name");
  if (!dispatcher.duration)
    return { error: DispatcherFormError.maxCycleDuration, triggerIndex: 0 };
  // console.log("Has cycle duration");
  for (
    let triggerIndex = 0;
    triggerIndex < dispatcher.triggers.length;
    triggerIndex++
  ) {
    const trigger = dispatcher.triggers[triggerIndex];
    // Sets intrusion's analyticTriggerTime to 1 second. Reason:
    // User can't change intrusion's analyticTriggerTime since the
    // introduction of Permanence analytic.
    if (dispatcher.analyticType === "Intrusão") {
      trigger.analyticTriggerTime = dayjs("00:01", "mm:ss");
    }

    if (!trigger.analyticTriggerTime)
      return { error: DispatcherFormError.analyticTriggerTime, triggerIndex };
    // Can't have null fields in transition, except for last trigger
    // console.log("Has analytic time trigger");
    if (triggerIndex !== dispatcher.triggers.length - 1) {
      if (transitionHasNull(trigger.transition)) {
        // console.log("Doesnt have transition");
        return { error: DispatcherFormError.transitionHasNull, triggerIndex };
      } else if (
        !trigger.transition.useMaxAlerts &&
        !trigger.transition.usePermanenceInterval
      ) {
        // console.log("No transition");
        return { error: DispatcherFormError.noTransition, triggerIndex };
      } else if (
        !respectsTransitionRule(trigger.analyticTriggerTime, trigger.transition)
      ) {
        return {
          error: DispatcherFormError.permanenceIntervalRule,
          triggerIndex,
        };
      }
    }
    // Removing empty receivers and checking null
    for (
      let receiverIndex = 0;
      receiverIndex < trigger.receivers.length;
      receiverIndex++
    ) {
      // TODO: check each receiver.info object, and assure they are not empty
      const receiver = trigger.receivers[receiverIndex];

      if (isMoniReceiver(receiver) && receiver.moniIntegrationId === -1) {
        return { error: DispatcherFormError.noMoniIntegrationId, triggerIndex };
      }
    }

    // console.log(trigger.receivers);
  }

  // console.log("No errors");
  return { error: DispatcherFormError.noErrors, triggerIndex: 0 };
}

export function canCreateDispatcher(dispatcher: Dispatcher): {
  error: DispatcherFormError;
  triggerIndex: number;
} {
  // console.log(dispatcher);
  if (dispatcher.analyticType === "") {
    return { error: DispatcherFormError.type, triggerIndex: 0 };
  }
  if (!dispatcher.name)
    return { error: DispatcherFormError.name, triggerIndex: 0 };
  // console.log("Has name");
  if (!dispatcher.duration)
    return { error: DispatcherFormError.maxCycleDuration, triggerIndex: 0 };
  // console.log("Has cycle duration");
  for (
    let triggerIndex = 0;
    triggerIndex < dispatcher.triggers.length;
    triggerIndex++
  ) {
    const trigger = dispatcher.triggers[triggerIndex];
    // Sets intrusion's analyticTriggerTime to 1 second. Reason:
    // User can't change intrusion's analyticTriggerTime since the
    // introduction of Permanence analytic.
    if (dispatcher.analyticType === "Intrusão") {
      trigger.analyticTriggerTime = dayjs("00:01", "mm:ss");
    }

    if (!trigger.analyticTriggerTime)
      return { error: DispatcherFormError.analyticTriggerTime, triggerIndex };
    // Can't have null fields in transition, except for last trigger
    // console.log("Has analytic time trigger");
    if (triggerIndex !== dispatcher.triggers.length - 1) {
      if (transitionHasNull(trigger.transition)) {
        // console.log("Doesnt have transition");
        return { error: DispatcherFormError.transitionHasNull, triggerIndex };
      } else if (
        !trigger.transition.useMaxAlerts &&
        !trigger.transition.usePermanenceInterval
      ) {
        // console.log("No transition");
        return { error: DispatcherFormError.noTransition, triggerIndex };
      } else if (
        !respectsTransitionRule(trigger.analyticTriggerTime, trigger.transition)
      ) {
        return {
          error: DispatcherFormError.permanenceIntervalRule,
          triggerIndex,
        };
      }
    }
    // Removing empty receivers and checking null
    for (
      let receiverIndex = 0;
      receiverIndex < trigger.receivers.length;
      receiverIndex++
    ) {
      // TODO: check each receiver.info object, and assure they are not empty
      const receiver = trigger.receivers[receiverIndex];
      if (receiver.type === "whatsapp") {
        receiver.info.number = receiver.info.number.replace(/[-+\s()]/g, "");
      }
      if (receiver.type === "moni" && receiver.info.moniIntegrationId === -1) {
        return { error: DispatcherFormError.noMoniIntegrationId, triggerIndex };
      }
      if (!receiver || receiver.type !== "gear") {
        if (
          // @ts-expect-error
          receiver.info.number === "" ||
          // @ts-expect-error
          receiver.info.groupId === "" ||
          // @ts-expect-error
          (receiver.info.id === 0 && receiver.type === "user")
        ) {
          trigger.receivers.splice(receiverIndex);
        }
      }
    }

    // console.log(trigger.receivers);
    // Cant have empty receivers
    if (
      trigger.receivers.length === 1 &&
      (!trigger.receivers[0] ||
        (trigger.receivers[0].type !== "gear" && !trigger.receivers[0].info))
    )
      return { error: DispatcherFormError.emptyReceivers, triggerIndex };

    // TODO: This condition might never happen anymore
    if (trigger.receivers.length === 0) {
      trigger.receivers.push({
        type: "user",
        info: {
          id: -1,
          name: "",
        },
      });
      return { error: DispatcherFormError.emptyReceivers, triggerIndex };
    }
  }

  // console.log("No errors");
  return { error: DispatcherFormError.noErrors, triggerIndex: 0 };
}

export async function createDispatcherMiddleware(
  dispatcher: Dispatcher,
  editId?: number
) {
  const formData = new FormData();
  const dispatcherFormData: Partial<DispatcherFormData> = {};
  const dispatcherDuration = dayjs.duration({
    seconds: dispatcher.duration?.second(),
    minutes: dispatcher.duration?.minute(),
    hours: dispatcher.duration?.hour(),
  });
  // console.log("My duration\n", dispatcherDuration.asSeconds());

  // Building general dispatcher data
  dispatcherFormData.name = dispatcher.name;
  dispatcherFormData.max_cycle_duration = dispatcherDuration.asSeconds();
  dispatcherFormData.analytic_type = dispatcher.analyticType as AnalyticType;
  // Building triggers
  dispatcherFormData.alerts = [];

  let moniIntegrations: MoniIntegration[];
  const moniReceivers = dispatcher.triggers.filter((trigger) =>
    trigger.receivers.filter((value) => value?.type === "moni")
  );
  if (moniReceivers.length > 0) {
    moniIntegrations = (await getMoniIntegrations()).data;
  }

  const usersFromCompanyAndSharedCameras =
    await getUsersFromCompanyAndSharedCameras();

  dispatcher.triggers.forEach((trigger, index) => {
    const dispatcherTriggerFormData: Partial<DispatcherTriggerFormData> = {};
    const analyticTimeTrigger = dayjs.duration({
      seconds: trigger.analyticTriggerTime?.second(),
      minutes: trigger.analyticTriggerTime?.minute(),
    });

    dispatcherTriggerFormData.name = `gatilho ${index + 1}`;
    dispatcherTriggerFormData.analytic_time_trigger =
      analyticTimeTrigger.asSeconds();
    // Building receivers
    dispatcherTriggerFormData.receiver = {
      telegram: [],
      whatsapp: [],
      email: [],
      user: [],
      shared_camera_user: [],
      moni: [],
      webservice: [],
    };

    trigger.receivers.forEach((receiver) => {
      if (receiver!.type === "telegram") {
        dispatcherTriggerFormData.receiver?.telegram?.push({
          id: receiver!.info!.id,
          isVideo: receiver!.info!.isVideo!,
        });
      } else if (receiver!.type === "whatsapp") {
        dispatcherTriggerFormData.receiver?.whatsapp?.push({
          number: receiver!.info!.number,
          isVideo: receiver!.info!.isVideo!,
        });
      } else if (receiver!.type === "whatsappGroup") {
        dispatcherTriggerFormData.receiver?.whatsapp?.push({
          number: receiver!.info!.groupId,
          isVideo: receiver!.info!.isVideo!,
        });
      } else if (receiver!.type === "email") {
        dispatcherTriggerFormData.receiver?.email?.push(receiver!.info!);
      } else if (receiver!.type === "user") {
        const userInCompany = usersFromCompanyAndSharedCameras.data.findIndex(
          (value) => value.id === receiver!.info!.id
        );
        if (receiver.info.id === -1 || receiver.info.id === "-1") {
          dispatcherTriggerFormData.receiver?.user?.push(receiver!.info!);
        } else if (userInCompany === -1) {
          dispatcherTriggerFormData.receiver?.shared_camera_user?.push(
            receiver!.info!
          );
        } else {
          dispatcherTriggerFormData.receiver?.user?.push(receiver!.info!);
        }
      } else if (receiver!.type === "gear") {
        dispatcherTriggerFormData.receiver!.gear = true;
      } else if (!!receiver && receiver!.type === "moni") {
        const moniInfo: Partial<MoniIntegration> | undefined =
          moniIntegrations.find(
            (value) => value.id === receiver.info!.moniIntegrationId
          );
        if (!!moniInfo) {
          moniInfo.analytic_codes = [
            moniInfo.analytic_codes!.find(
              (value) =>
                value.analytic_type ===
                getLogTypeFromLogName(dispatcherFormData.analytic_type!)
            )!,
          ];
          delete moniInfo.company_id;
          delete moniInfo.created_at;
          delete moniInfo.updated_at;
        }
        dispatcherTriggerFormData.receiver?.moni?.push({
          ...moniInfo,
          client_id: receiver.info!.clientId,
          moni_integration_id: receiver.info!.moniIntegrationId,
          partition_id: receiver.info!.partitionId,
          company_id: receiver.info!.companyId,
        });
      } else if (!!receiver && receiver.type === "webhook") {
        const webhookInfo = {
          ip: receiver.info.ip,
          port: receiver.info.port,
          protocol: receiver.info.protocol,
          endpoint: receiver.info.endpoint,
          headers: receiver.info.headers,
          body: receiver.info.body,
        };
        dispatcherTriggerFormData.receiver?.webservice?.push(webhookInfo);
      } else {
        throw new Error("Tipo de receiver nao definido");
      }
    });
    // Building transitions
    const transitionInterval = dayjs.duration({
      seconds: trigger.transition.permanenceInterval?.second(),
      minutes: trigger.transition.permanenceInterval?.minute(),
    });

    let transitionBetweenAlert = {
      max_incident_times:
        typeof trigger.transition.maxAlerts === "string"
          ? parseInt(trigger.transition.maxAlerts)
          : trigger.transition.maxAlerts,
      min_time_between_alert: transitionInterval.asSeconds(),
    };
    if (!trigger.transition.usePermanenceInterval) {
      transitionBetweenAlert.min_time_between_alert = -1;
    }
    if (!trigger.transition.useMaxAlerts) {
      transitionBetweenAlert.max_incident_times = -1;
    }
    if (index === dispatcher.triggers.length - 1) {
      transitionBetweenAlert = {
        max_incident_times: 0,
        min_time_between_alert: 0,
      };
    }

    dispatcherTriggerFormData.transition_between_alert = transitionBetweenAlert;

    dispatcherFormData.alerts?.push(
      dispatcherTriggerFormData as DispatcherTriggerFormData
    );
  });

  const alertsString = JSON.stringify(dispatcherFormData.alerts);

  //   const alertsBlob = new Blob([alertsString], {
  //     type: "application/json",
  //   });

  formData.append("name", dispatcherFormData.name);
  formData.append(
    "max_cycle_duration",
    dispatcherFormData.max_cycle_duration.toString()
  );
  formData.append("analytic_type", dispatcherFormData.analytic_type);
  formData.append("alerts", alertsString);

  // console.log("Dispatcher form Data:\n", dispatcherFormData);
  // console.log("Form data for submitting:\n", formData);

  if (editId) {
    return await putDispatcher(formData, editId)
      .then(() => true)
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao editar sistema de alerta",
          description: getErrorMessage(err),
        });
        return false;
      });
  } else {
    return await createDispatcher(formData)
      .then(() => true)
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao editar sistema de alerta",
          description: getErrorMessage(err),
        });
        return false;
      });
  }
}

export async function createNewDispatcherMiddleware(
  dispatcher: NewDispatcher,
  editId?: number
) {
  const formData = new FormData();
  const dispatcherFormData: Partial<DispatcherFormData> = {};
  const dispatcherDuration = dayjs.duration({
    seconds: dispatcher.duration?.second(),
    minutes: dispatcher.duration?.minute(),
    hours: dispatcher.duration?.hour(),
  });
  // console.log("My duration\n", dispatcherDuration.asSeconds());

  // Building general dispatcher data
  dispatcherFormData.name = dispatcher.name;
  dispatcherFormData.max_cycle_duration = dispatcherDuration.asSeconds();
  dispatcherFormData.analytic_type = dispatcher.analyticType as AnalyticType;
  // Building triggers
  dispatcherFormData.alerts = [];

  let moniIntegrations: MoniIntegration[];
  const moniReceivers = dispatcher.triggers.filter((trigger) =>
    trigger.receivers.filter((value) => isMoniReceiver(value))
  );
  if (moniReceivers.length > 0) {
    moniIntegrations = (await getMoniIntegrations()).data;
  }

  const usersFromCompanyAndSharedCameras =
    await getUsersFromCompanyAndSharedCameras();

  dispatcher.triggers.forEach((trigger, index) => {
    const dispatcherTriggerFormData: Partial<DispatcherTriggerFormData> = {};
    const analyticTimeTrigger = dayjs.duration({
      seconds: trigger.analyticTriggerTime?.second(),
      minutes: trigger.analyticTriggerTime?.minute(),
    });

    dispatcherTriggerFormData.name = `gatilho ${index + 1}`;
    dispatcherTriggerFormData.analytic_time_trigger =
      analyticTimeTrigger.asSeconds();
    // Building receivers
    dispatcherTriggerFormData.receiver = {
      telegram: [],
      whatsapp: [],
      email: [],
      user: [],
      shared_camera_user: [],
      moni: [],
      webservice: [],
    };

    trigger.receivers.forEach((receiver) => {
      if (isTelegramReceiver(receiver)) {
        dispatcherTriggerFormData.receiver?.telegram?.push(receiver);
      } else if (isWhatsAppReceiver(receiver)) {
        dispatcherTriggerFormData.receiver?.whatsapp?.push(receiver);
      } else if (isWhatsAppGroupReceiver(receiver)) {
        dispatcherTriggerFormData.receiver?.whatsapp?.push({
          isVideo: receiver.isVideo,
          number: receiver.groupId,
        });
      } else if (isEmailReceiver(receiver)) {
        dispatcherTriggerFormData.receiver?.email?.push(receiver);
      } else if (isUserReceiver(receiver)) {
        const userInCompany = usersFromCompanyAndSharedCameras.data.findIndex(
          (value) => value.id === receiver.id
        );
        if (receiver.id === -1 || receiver.id === "-1") {
          dispatcherTriggerFormData.receiver?.user?.push(receiver);
        } else if (userInCompany === -1) {
          dispatcherTriggerFormData.receiver?.shared_camera_user?.push(
            receiver
          );
        } else {
          dispatcherTriggerFormData.receiver?.user?.push(receiver);
        }
      } else if (receiver === null) {
        dispatcherTriggerFormData.receiver!.gear = true;
      } else if (isMoniReceiver(receiver)) {
        const moniInfo: Partial<MoniIntegration> | undefined =
          moniIntegrations.find(
            (value) => value.id === receiver.moniIntegrationId
          );
        if (!!moniInfo) {
          moniInfo.analytic_codes = [
            moniInfo.analytic_codes!.find(
              (value) =>
                value.analytic_type ===
                getLogTypeFromLogName(dispatcherFormData.analytic_type!)
            )!,
          ];
          delete moniInfo.company_id;
          delete moniInfo.created_at;
          delete moniInfo.updated_at;
        }
        dispatcherTriggerFormData.receiver?.moni?.push({
          ...moniInfo,
          client_id: receiver.clientId,
          moni_integration_id: receiver.moniIntegrationId,
          partition_id: receiver.partitionId,
          company_id: receiver.companyId,
        });
      } else if (isWebhookReceiver(receiver)) {
        const webhookInfo = {
          ip: receiver.ip,
          port: receiver.port,
          protocol: receiver.protocol,
          endpoint: receiver.endpoint,
          headers: receiver.headers,
          body: receiver.body,
        };
        dispatcherTriggerFormData.receiver?.webservice?.push(webhookInfo);
      } else {
        throw new Error("Tipo de receiver nao definido");
      }
    });
    // Building transitions
    const transitionInterval = dayjs.duration({
      seconds: trigger.transition.permanenceInterval?.second(),
      minutes: trigger.transition.permanenceInterval?.minute(),
    });

    let transitionBetweenAlert = {
      max_incident_times:
        typeof trigger.transition.maxAlerts === "string"
          ? parseInt(trigger.transition.maxAlerts)
          : trigger.transition.maxAlerts,
      min_time_between_alert: transitionInterval.asSeconds(),
    };
    if (!trigger.transition.usePermanenceInterval) {
      transitionBetweenAlert.min_time_between_alert = -1;
    }
    if (!trigger.transition.useMaxAlerts) {
      transitionBetweenAlert.max_incident_times = -1;
    }
    if (index === dispatcher.triggers.length - 1) {
      transitionBetweenAlert = {
        max_incident_times: 0,
        min_time_between_alert: 0,
      };
    }

    dispatcherTriggerFormData.transition_between_alert = transitionBetweenAlert;

    dispatcherFormData.alerts?.push(
      dispatcherTriggerFormData as DispatcherTriggerFormData
    );
  });

  const alertsString = JSON.stringify(dispatcherFormData.alerts);

  //   const alertsBlob = new Blob([alertsString], {
  //     type: "application/json",
  //   });

  formData.append("name", dispatcherFormData.name);
  formData.append(
    "max_cycle_duration",
    dispatcherFormData.max_cycle_duration.toString()
  );
  formData.append("analytic_type", dispatcherFormData.analytic_type);
  formData.append("alerts", alertsString);

  // console.log("Dispatcher form Data:\n", dispatcherFormData);
  // console.log("Form data for submitting:\n", formData);

  if (editId) {
    return await putDispatcher(formData, editId)
      .then(() => true)
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao editar sistema de alerta",
          description: getErrorMessage(err),
        });
        return false;
      });
  } else {
    return await createDispatcher(formData)
      .then(() => true)
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao editar sistema de alerta",
          description: getErrorMessage(err),
        });
        return false;
      });
  }
}

export function getEmptyTrigger() {
  const emptyTrigger: DispatcherTrigger = {
    analyticTriggerTime: null,
    transition: {
      maxAlerts: "",
      permanenceInterval: null,
      useMaxAlerts: false,
      usePermanenceInterval: false,
    },
    receivers: [
      {
        type: "user",
        info: { id: -1, name: "Todos" },
      },
    ],
  };

  return emptyTrigger;
}

export function getEmptyDispatcher() {
  const emptyDispatcher: Dispatcher = {
    name: "",
    analyticType: "",
    duration: null,
    triggers: [getEmptyTrigger()],
  };

  return emptyDispatcher;
}

// Initial state for error messages
export function getInitialErrorMessages() {
  const initialErrorMessages = Array.from({ length: 60 }, () => ({
    [DispatcherFormError.name]: "",
    [DispatcherFormError.type]: "",
    [DispatcherFormError.maxCycleDuration]: "",
    [DispatcherFormError.analyticTriggerTime]: "",
    [DispatcherFormError.transitionHasNull]: "",
    [DispatcherFormError.permanenceIntervalRule]: "",
    [DispatcherFormError.noTransition]: "",
    [DispatcherFormError.emptyReceivers]: "",
    [DispatcherFormError.noErrors]: "",
    [DispatcherFormError.noMoniIntegrationId]: "",
  }));
  return initialErrorMessages;
}

// Converts backend data type to frontend data type
export function convertDispatcherListDataToDispatcher(
  data: DispatcherListData
): Dispatcher {
  const dispatcher: Dispatcher = {
    name: data.name,
    analyticType: data.analytic_type,
    duration: data.max_cycle_duration
      ? dayjs("00:00:00", "HH:mm:ss").add(data.max_cycle_duration, "second")
      : null,
    triggers: data.alerts.map((triggerData) => {
      const trigger: DispatcherTrigger = {
        analyticTriggerTime: triggerData.analytic_time_trigger
          ? dayjs("00:00:00", "HH:mm:ss").add(
              triggerData.analytic_time_trigger,
              "second"
            )
          : null,
        transition: {
          permanenceInterval:
            !triggerData.transition_between_alert.min_time_between_alert ||
            triggerData.transition_between_alert.min_time_between_alert === -1
              ? null
              : dayjs("00:00:00", "HH:mm:ss").add(
                  triggerData.transition_between_alert.min_time_between_alert,
                  "second"
                ),
          maxAlerts:
            triggerData.transition_between_alert.max_incident_times === -1 ||
            !triggerData.transition_between_alert.max_incident_times
              ? ""
              : triggerData.transition_between_alert.max_incident_times,
          useMaxAlerts:
            !triggerData.transition_between_alert.max_incident_times ||
            triggerData.transition_between_alert.max_incident_times === -1
              ? false
              : true,
          usePermanenceInterval:
            !triggerData.transition_between_alert.min_time_between_alert ||
            triggerData.transition_between_alert.min_time_between_alert === -1
              ? false
              : true,
        },
        receivers: [],
      };

      if (triggerData.receiver.telegram) {
        trigger.receivers.push(
          ...triggerData.receiver.telegram.map((telegramInfo) => ({
            type: "telegram" as "telegram",
            info: {
              id: telegramInfo.id,
              isVideo: telegramInfo.isVideo,
            },
          }))
        );
      }

      if (triggerData.receiver.whatsapp) {
        trigger.receivers.push(
          ...triggerData.receiver.whatsapp.map((whatsappInfo) => {
            if (whatsappInfo.number.length > 15) {
              return {
                type: "whatsappGroup" as "whatsappGroup",
                info: {
                  groupId: whatsappInfo.number,
                  isVideo: whatsappInfo.isVideo,
                },
              };
            }
            return {
              type: "whatsapp" as "whatsapp",
              info: whatsappInfo,
            };
          })
        );
      }

      if (triggerData.receiver.email) {
        trigger.receivers.push(
          ...triggerData.receiver.email.map((emailInfo) => ({
            type: "email" as "email",
            info: emailInfo,
          }))
        );
      }

      if (triggerData.receiver.user) {
        trigger.receivers.push(
          ...triggerData.receiver.user.map((userInfo) => ({
            type: "user" as "user",
            info: userInfo,
          }))
        );
      }

      if (triggerData.receiver.shared_camera_user) {
        trigger.receivers.push(
          ...triggerData.receiver.shared_camera_user.map((userInfo) => ({
            type: "user" as "user",
            info: userInfo,
          }))
        );
      }

      if (triggerData.receiver.gear) {
        trigger.receivers.push({ type: "gear", info: null });
      }

      if (triggerData.receiver.moni) {
        trigger.receivers.push(
          ...triggerData.receiver.moni.map((moniInfo) => ({
            type: "moni" as "moni",
            info: {
              clientId: moniInfo.client_id,
              companyId: moniInfo.company_id,
              moniIntegrationId: moniInfo.moni_integration_id,
              partitionId: moniInfo.partition_id,
            },
          }))
        );
      }

      if (triggerData.receiver.webservice) {
        trigger.receivers.push(
          ...triggerData.receiver.webservice.map((webhookInfo) => ({
            type: "webhook" as "webhook",
            info: webhookInfo,
          }))
        );
      }

      return trigger;
    }),
  };

  return dispatcher;
}
