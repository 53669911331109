import React, { useEffect, useState } from "react";
// Helpers
import { ConfigureAnalyticProps } from "interfaces/configureAnalytic";
// Components
import { Button, TextField } from "@mui/material";
// Styles
import styles from "./ConfigureAnalytic.module.css";

import RadarCanvas, { PolygonI } from "./RadarCanvas/RadarCanvas";
import { v4 as uuidv4 } from "uuid";
import useCanvasDimensions from "./useCanvasDimensions";
import { updateAnalytic } from "api/analytics";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import { LoadingButton } from "@mui/lab";
import { Vector2d } from "lib/canvas";
import { Notifications } from "@mui/icons-material";
import ExpandableContainer from "components/ExpandableContainer";
import DispatcherSelector from "./Controls/DispatcherSelector";
import CreateSimpleDispatcherDialog from "./CreateSimpleDispatcherDialog";
import { isPlatform } from "@ionic/react";
import AssociateDevices from "./IO_Devices/AssociateDevices";
import { putAlertAssociation } from "api/alertSystem";
// import { putAlertAssociation } from "api/alertSystem";

const initialRectWidth = 200;
const initialRectHeight = 200;

function normalized(value: number, dimension: number) {
  return value / dimension;
}

function getInitialPolygon(canvasDimensions: {
  width: number;
  height: number;
}) {
  const initialRectPosX = canvasDimensions.width / 2 - initialRectWidth / 2;
  const initialRectPosY = canvasDimensions.height / 2 - initialRectHeight / 2;

  const vertex1 = {
    x: normalized(initialRectPosX, canvasDimensions.width),
    y: normalized(initialRectPosY, canvasDimensions.height),
  };
  const vertex2 = {
    x: normalized(initialRectPosX + initialRectWidth, canvasDimensions.width),
    y: normalized(initialRectPosY, canvasDimensions.height),
  };
  const vertex3 = {
    x: normalized(initialRectPosX + initialRectWidth, canvasDimensions.width),
    y: normalized(initialRectPosY + initialRectHeight, canvasDimensions.height),
  };
  const vertex4 = {
    x: normalized(initialRectPosX, canvasDimensions.width),
    y: normalized(initialRectPosY + initialRectHeight, canvasDimensions.height),
  };

  const initialPolygon: PolygonI = {
    color: "rgba(13,239,65,0.26)",
    name: `Radar region`,
    id: uuidv4(),
    points: [vertex1, vertex2, vertex3, vertex4],
  };

  return initialPolygon;
}

function getInitialRegion(
  prevRegion: string | undefined,
  canvasDimensions: {
    width: number;
    height: number;
  }
) {
  if (!prevRegion) {
    return getInitialPolygon(canvasDimensions);
  }
  const parsedRegion = JSON.parse(prevRegion) as Vector2d[];

  return {
    color: "rgba(13,239,65,0.26)",
    name: `Radar region`,
    id: uuidv4(),
    points: parsedRegion,
  };
}

export default function ConfigureRadarAnalytic({
  cameraFrame,
  updateCamera,
  cameraAnalytic,
}: ConfigureAnalyticProps) {
  const { toast } = useToast();
  const canvasDimensions = useCanvasDimensions();
  const [hasChanged, setHasChanged] = useState(false);
  const [radarRegion, setRadarRegion] = useState<PolygonI>(
    getInitialRegion(cameraAnalytic.radar!.target_regions, canvasDimensions)
  );
  const [distance, setDistance] = useState("");
  const [speedLimit, setSpeedLimit] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openNewDispatcher, setOpenNewDispatcher] = useState(false);
  const currentDispatcher = cameraAnalytic.alerts_analytics[0];
  const [newDispatcherId, setNewDispatcherId] = useState(
    currentDispatcher.alert_system_id
  );

  useEffect(() => {
    if (cameraAnalytic.radar!.distance) {
      setDistance(cameraAnalytic.radar!.distance.toString());
    }
    if (cameraAnalytic.radar!.max_velocity === -1) {
      setSpeedLimit("");
    } else {
      setSpeedLimit(cameraAnalytic.radar!.max_velocity.toString());
    }
  }, [cameraAnalytic]);

  useEffect(() => {
    if (
      cameraAnalytic.radar!.distance?.toString() !== distance ||
      cameraAnalytic.radar!.max_velocity.toString() !== speedLimit
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [cameraAnalytic.radar, distance, speedLimit]);

  function handleChangeDispatcher(dispatcherId: number) {
    setHasChanged(true);
    setNewDispatcherId(dispatcherId);
  }

  function handleChangeRegion(newRegion: PolygonI) {
    setHasChanged(true);
    const newRadarRegion = { ...newRegion };

    newRadarRegion.points = newRadarRegion.points.map((value) => ({
      x: normalized(value.x, canvasDimensions.width),
      y: normalized(value.y, canvasDimensions.height),
    }));

    setRadarRegion(newRadarRegion);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    const requestData = {
      max_velocity: speedLimit,
      distance,
      target_regions: JSON.stringify(radarRegion.points),
      activated: cameraAnalytic.activated,
    };

    if (!hasChanged) {
      requestData.activated = !cameraAnalytic.activated;
    }

    await putAlertAssociation(
      cameraAnalytic.alerts_analytics[0].alert_system_analytic_id!,
      {
        alert_system_id: newDispatcherId,
      }
    ).catch((err) => {
      toast({
        variant: "destructive",
        description: getErrorMessage(err),
        title: "Erro ao atualizar sistema de alerta",
      });
    });

    await updateAnalytic(requestData, "Radar", cameraAnalytic.id)
      .then(() => {
        setHasChanged(false);
        updateCamera();
        toast({
          description: "Analítico salvo com sucesso",
        });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          description: getErrorMessage(err),
          title: "Erro ao salvar analítico",
        });
      });
    setIsSubmitting(false);
  }

  return (
    <div>
      <div className={styles.row}>
        <div>
          <h2 className="font-medium mb-2">
            Demarcação de região para o radar
          </h2>
          <RadarCanvas
            className="mb-3 sm:mr-3"
            region={radarRegion}
            cameraFrame={cameraFrame}
            dimensions={canvasDimensions}
            onChangeRegion={handleChangeRegion}
          />
        </div>
        <div className="flex-1 grid gap-3 grid-cols-1 sm:grid-cols-2 lg:h-24">
          <h2 className="col-span-1 sm:col-span-2 max-h-fit font-medium">
            Parâmetros do radar
          </h2>
          <LoadingButton
            color={
              hasChanged
                ? "secondary"
                : cameraAnalytic.activated
                  ? "error"
                  : "primary"
            }
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
            className="col-span-1 sm:col-span-2 max-h-fit"
          >
            {hasChanged
              ? "Salvar mudanças"
              : cameraAnalytic.activated
                ? "Desativar"
                : "Ativar"}
          </LoadingButton>
          <TextField
            size="small"
            value={distance}
            label="Distância"
            InputProps={{ endAdornment: "m" }}
            onChange={(ev) => setDistance(ev.target.value)}
          />
          <TextField
            size="small"
            value={speedLimit}
            label="Velocidade limite"
            InputProps={{ endAdornment: "km/h" }}
            onChange={(ev) => setSpeedLimit(ev.target.value)}
          />
          <div className="col-span-1 sm:col-span-2">
            <ExpandableContainer title="Notificações" icon={<Notifications />}>
              <DispatcherSelector
                initialValue={currentDispatcher}
                analyticType={cameraAnalytic.analytic_type}
                onSelectDispatcher={handleChangeDispatcher}
              />
              <Button
                variant="outlined"
                onClick={() => setOpenNewDispatcher(true)}
              >
                Novo sistema de alerta
              </Button>
            </ExpandableContainer>
          </div>
          <CreateSimpleDispatcherDialog
            openNewDispatcher={openNewDispatcher}
            analyticType={cameraAnalytic.analytic_type}
            onClose={() => setOpenNewDispatcher(false)}
          />
        </div>
      </div>
      {!(isPlatform("mobile") && !isPlatform("mobileweb")) && (
        <AssociateDevices analyticID={cameraAnalytic.id} />
      )}
    </div>
  );
}
