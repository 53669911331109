import axios from "axios";
import { toast } from "components/ui/use-toast";
import { externalSetUser } from "context/GlobalContextProvider";
import { cleanUp } from "lib/logoutUtils";
import { isPlatform } from "@ionic/react";

class ApiConfig {
  baseUrl: string;
  basePort: string;
  baseUrlNoPort: string;

  constructor() {
    this.baseUrl = this._initBaseUrl();
    const urlAux = new URL(this.baseUrl);
    this.basePort = urlAux.port;
    this.baseUrlNoPort = `${urlAux.protocol}//${urlAux.hostname}`;
  }

  _initBaseUrl() {
    let baseUrl;
    const isDev = process.env.NODE_ENV === "development";

    if (isDev) {
      // development
      baseUrl = process.env.REACT_APP_DEV_API_URL;
    } else if (!isDev && isPlatform("mobile") && !isPlatform("mobileweb")) {
      // mobile in production
      const mobileBaseUrl = localStorage.getItem("mobileBaseUrl");
      if (mobileBaseUrl) {
        baseUrl = mobileBaseUrl;
      } else {
        // This might be redundant
        baseUrl = "https://heimdallcloud.com.br";
      }
    } else if (!isDev) {
      // browser in production
      baseUrl = window.location.origin;
    }

    if (!baseUrl)
      throw new Error(
        "Erro na definição do endereço da API. Cheque as variáveis de ambiente"
      );

    return baseUrl;
  }

  _updateBasePortAndBaseUrlNoPort() {
    const urlAux = new URL(this.baseUrl);
    this.basePort = urlAux.port;
    this.baseUrlNoPort = `${urlAux.protocol}//${urlAux.hostname}`;
  }

  /**
   * Updates the three attributes of this class: `baseUrl`, `basePort` and `baseUrlNoPort` based on
   * the new base url provided
   * @param newBaseUrl the new base url for api requests
   */
  updateBaseUrl(newBaseUrl: string) {
    this.baseUrl = newBaseUrl;
    this._updateBasePortAndBaseUrlNoPort();
    localStorage.setItem("mobileBaseUrl", newBaseUrl);
    axiosRoot.defaults.baseURL = newBaseUrl;
  }
}

export const apiConfig = new ApiConfig();

const axiosRoot = axios.create({
  baseURL: apiConfig.baseUrl,
  timeout: 8500,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export function updateBaseUrl(newBaseUrl: string) {
  localStorage.setItem("mobileBaseUrl", newBaseUrl);
  axiosRoot.defaults.baseURL = newBaseUrl;
}

axiosRoot.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response) {
      const tokenExpired = err.response.data.message === "Token has expired";
      const tokenBlacklisted =
        err.response.data.message === "The token has been blacklisted";

      if (tokenExpired) {
        toast({
          variant: "destructive",
          description: "Sua sessão expirou",
        });
        cleanUp();
        externalSetUser(null);
      } else if (tokenBlacklisted) {
        toast({
          variant: "destructive",
          description: "Sua autenticação de sessão foi removida",
        });
        cleanUp();
        externalSetUser(null);
      }
    }
    return Promise.reject(err);
  }
);

export default axiosRoot;
